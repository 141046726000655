import React, {useEffect, useRef, useState, useContext, useCallback} from 'react';
import './NewCharacterList.css';
import {CharacterContainer} from './NewCharacterContainer';
import {getUserAccessToken, getUserID, userIDExists} from '../../App';
import {TagSystem} from "./TagSystem";
import {TagProvider} from '../Context/TagContext';
import MainPageLoader from "../LoadingEvents/MainPageLoader";
import {CharacterContext} from "../Context/CharacterContext";
import {ReactComponent as LeftArrowIcon} from "./icons/LeftArrow.svg";
import {ReactComponent as RightArrowIcon} from "./icons/RightArrow.svg";
import JoinCommunityBanner from "../Banners/JoinCommunityBanner";
// import ReferUsersBanner from "../Banners/ReferUsersBanner";
import UpgradePlanBanner from "../Banners/UpgradePlanBanner";
import TopTimer from "../Notifications/TopBanners/TopTimer";
// import {useProfileInfo} from "../Context/ProfileInfoContext";
import {useTimer} from "../Context/TimerContext";
import HeroBannerMain from "../Banners/HeroBannerMain";
import MobileHeroBanner from "../Banners/MobileHeroBanner";
import Cookies from "js-cookie";
import SignUpToSeeMore from "../Notifications/SignUpToSeeMore";
import EmailVerification from "../Forms/EmailVerification";
import {useProfileData} from "../Context/ProfileDataContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useButtonTracking, usePageTracking} from "../Analytics/UserAnalyticsContext";
import AgeVerificationModal from "../Modals/AgeVerificationModal";

export const NewCharacterList = ({
                                     ownCharacters,
                                     search,
                                     setSearchText,
                                     searchCharacters,
                                     setSearchCharacters,
                                     searchText
                                 }) => {
    const rowRefs = useRef([]);
    const [isLoading, setLoading] = useState(true);
    const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isAdditionalLoading, setAdditionalLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [reverseOverride, setReverseOverride] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const includeNSFW = true;

    const languageMapping = {
        en: 'English',
        es: 'Spanish',
        fr: 'French',
        ru: 'Russian',
        pl: 'Polish',
        pt: 'Portuguese',
        zh: 'Chinese',
        ja: 'Japanese',
        ko: 'Korean',
        de: 'German',
        it: 'Italian'
    };

    const [language, setLanguage] = useState(i18n.language);

    useEffect(() => {
        // Listen for language changes
        const handleLanguageChange = (lng) => {
            setLanguage(lng);
        };

        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup on unmount
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    // console.log(language);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    const {realChars, setRealChars, setLocalSearchText} = useContext(CharacterContext);

    const {t} = useTranslation();

    const getCategoryLabel = (categoryName) => {
        switch (categoryName) {
            case "Popular":
                return `${t('Popular')}`;
            case "Trending":
                return `${t('Trending')}`;
            case "Newest":
                return `${t('Newest')}`;
            case "Recommended For You":
                return `${t('RecommendedForYou')}`;
            case "Your Characters":
                return `${t('YourCharacters')}`;

            case "Alpha Male":
                return `${t('Alpha Male')}`;
            case "Arab":
                return `${t('Arab')}`;
            case "Babysitter":
                return `${t('Babysitter')}`;
            case "Bondage":
                return `${t('Bondage')}`;
            case "Brazilian":
                return `${t('Brazilian')}`;
            case "British":
                return `${t('British')}`;
            case "Brunette":
                return `${t('Brunette')}`;
            case "Bully":
                return `${t('Bully')}`;
            case "Catboy":
                return `${t('Catboy')}`;
            case "Catgirl":
                return `${t('Catgirl')}`;
            case "Cheerleader":
                return `${t('Cheerleader')}`;
            case "Coffee Shop":
                return `${t('CoffeeShop')}`;
            case "College":
                return `${t('College')}`;
            case "Cosplay":
                return `${t('Cosplay')}`;
            case "Cuckold":
                return `${t('Cuckold')}`;
            case "Czech":
                return `${t('Czech')}`;
            case "Ebony":
                return `${t('Ebony')}`;
            case "FNAF":
                return `${t('FNAF')}`;
            case "Feet":
                return `${t('Feet')}`;
            case "Fit":
                return `${t('Fit')}`;
            case "Friend":
                return `${t('Friend')}`;
            case "Funny":
                return `${t('Funny')}`;
            case "GILF":
                return `${t('GILF')}`;
            case "Genshin Impact":
                return `${t('Genshin Impact')}`;
            case "Homeless":
                return `${t('Homeless')}`;
            case "Hypnosis":
                return `${t('Hypnosis')}`;
            case "Indian":
                return `${t('Indian')}`;
            case "Interracial":
                return `${t('Interracial')}`;
            case "Italian":
                return `${t('Italian')}`;
            case "Japanese":
                return `${t('Japanese')}`;
            case "Korean":
                return `${t('Korean')}`;
            case "Mafia":
                return `${t('Mafia')}`;
            case "Massage":
                return `${t('Massage')}`;
            case "Mind Control":
                return `${t('MindControl')}`;
            case "Multiple People":
                return `${t('Multiple People')}`;
            case "My Little Pony":
                return `${t('My Little Pony')}`;
            case "Night Club":
                return `${t('Night Club')}`;
            case "Non-Binary":
                return `${t('Non-Binary')}`;
            case "Non-Consensual":
                return `${t('NonConsensual')}`;
            case "Non-English":
                return `${t('NonEnglish')}`;
            case "Non-Human":
                return `${t('NonHuman')}`;
            case "Office":
                return `${t('Office')}`;
            case "Old & Young":
                return `${t('OldAndYoung')}`;
            case "Older":
                return `${t('Older')}`;
            case "Party":
                return `${t('Party')}`;
            case "Pokemon":
                return `${t('Pokemon')}`;
            case "Police":
                return `${t('Police')}`;
            case "Red Head":
                return `${t('Red Head')}`;
            case "Role Play":
                return `${t('Role Play')}`;
            case "Russian":
                return `${t('Russian')}`;
            case "Secret":
                return `${t('Secret')}`;
            case "Sister":
                return `${t('Sister')}`;
            case "Size Difference":
                return `${t('SizeDifference')}`;
            case "Son":
                return `${t('Son')}`;
            case "Strap On":
                return `${t('Strap On')}`;
            case "Tattoos":
                return `${t('Tattoos')}`;
            case "Teacher":
                return `${t('Teacher')}`;
            case "Teasing":
                return `${t('Teasing')}`;
            case "Texting":
                return `${t('Texting')}`;
            case "Threesome":
                return `${t('Threesome')}`;
            case "Toxic":
                return `${t('Toxic')}`;
            case "Trans":
                return `${t('Trans')}`;
            case "Valorant":
                return `${t('Valorant')}`;
            case "Virgin":
                return `${t('Virgin')}`;
            default:
                return categoryName;
        }
    };

    useEffect(() => {
        const fetchCharacters = async () => {
            try {
                setLoadingTagOrSearch(true);

                const response = await fetch('https://api.elyza.app/v1/search-identity', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({searchText, includeNSFW, cardsThatFit, offset: 0})
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();


                setTimeout(() => {
                    setLoadingTagOrSearch(false); // Stop loading after the delay
                    setSearchCharacters(data.results || []);
                }, 500);

            } catch (error) {
                console.error('Error fetching characters:', error);
                // Use setTimeout to also handle errors with a delay
                setTimeout(() => {
                    setLoadingTagOrSearch(false); // Stop loading on error
                    setSearchCharacters([]);
                }, 500);
            }
        };

        const timeoutId = setTimeout(() => {
            if (searchText) {
                fetchCharacters();
            }
        }, 500); // Initial delay before starting the fetch

        return () => {
            clearTimeout(timeoutId);
        };

        //eslint-disable-next-line
    }, [includeNSFW, searchText]);


    useEffect(() => {
        if (search.length > 0) {
            window.scrollTo({
                top: 0,
                behavior: "auto" // TODO: Decide if smooth scroll is more appropriate
            });
        }
    }, [search])
// eslint-disable-next-line
    const handleSelectedTagsChange = async (tags, force = false) => {
        if (tags === selectedTags && !force) return;
        setSelectedTags(tags);
        // setLoadingTagOrSearch(true);

        if (tags.length > 0) {
            setLocalSearchText(""); // Reset the search text only if a tag is selected
        }

        loadSpecificTagCharacters(tags).then(r => {

            setLoadingTagOrSearch(false);

        });

    };

    const getDynamicCardWidth = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth > 1280) {
            return 200;
        } else if (screenWidth > 680 && screenWidth <= 1280) {
            return 180;
        } else {
            return 140;
        }
    };

    const cardWidthInitial = getDynamicCardWidth();
    const screenWidth = window.innerWidth;
    const cardsThatFit = Math.ceil(screenWidth / cardWidthInitial) + 1;

    const initialCharacterListLoad = async () => {
        if (realChars.length === 0) {
            if (await userIDExists()) {
                const id = await getUserID();
                const response = await fetch('https://api.elyza.app/v1/identity-list', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                    }, body: JSON.stringify({
                        userId: id,
                        queryMode: "initial-load",
                        grouping: "",
                        tags: selectedTags,
                        includeNSFW: true,
                        startIndex: 0,
                        totalCount: cardsThatFit,
                        paginationStage: null,
                        specialCategory: "",
                        noRepeatSpecial: [],
                        targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                        languageOverride: languageMapping[language]
                    })
                });

                fetch('https://api.elyza.app/v1/profile-data', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', 'Authorization': await getUserAccessToken(),
                    }, body: JSON.stringify({userId: await getUserID()}),
                }).then(async (response) => {
                    const data = await response.json();
                    if (data.error) {
                        console.error('Error fetching profile data:', data.error);
                    } else {
                        setUsername(data.username);
                    }
                });
                return await response.json();
            } else {
                const response = await fetch('https://api.elyza.app/v1/identity-list', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                    }, body: JSON.stringify({
                        userId: null,
                        queryMode: "initial-load",
                        grouping: "",
                        tags: [],
                        includeNSFW: true,
                        startIndex: 0,
                        totalCount: cardsThatFit,
                        paginationStage: null,
                        specialCategory: "",
                        noRepeatSpecial: [],
                        targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                        languageOverride: languageMapping[language]
                    })
                });
                return await response.json();
            }
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        const tagParam = queryParams.get('tag');
        if (tagParam) {
            const decodedTag = decodeURIComponent(tagParam);
            handleSelectedTagsChange([decodedTag]).then(() => {
            });
            setReverseOverride([decodedTag]);
        }
        // eslint-disable-next-line
    }, [window.location]);

    const [isFirstLoad, setIsFirstLoad] = useState(true); // Add this state to your component
    const fetchCharacterList = async () => {
        try {
            if (isFirstLoad) {
                setLoading(true);
                setIsFirstLoad(false);
            }

            let newCharacters = await initialCharacterListLoad();

            if (newCharacters !== undefined && newCharacters.results.length > 0) {
                if (Object.keys(newCharacters.results[0])[0] === "Recommended For You") {
                    newCharacters.results.shift();
                }
                setRealChars(newCharacters.results);
            } else {
                const newCharacters = await initialCharacterListLoad();
                if (newCharacters !== undefined && newCharacters.results.length > 0) {
                    if (Object.keys(newCharacters.results[0])[0] === "Recommended For You") {
                        newCharacters.results.shift();
                    }
                    setRealChars(newCharacters.results);
                }
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching character list:', error);
            setLoading(false); // Ensure to handle loading state in case of error
        }
    };

// const [loginError, setLoginError] = useState(false);

// const validateIsLoggedIn = async () => {
//     const loggedIn = await userIDExists();
//     setLoginError(!loggedIn);
//
//     return loggedIn;
// };
//
// useEffect(() => {
//     if (loginError) {
//
//         document.body.style.overflow = 'hidden';
//     } else {
//         // Re-enable scrolling
//         document.body.style.overflow = 'auto';
//     }
//
//     // Clean up function to ensure scrolling is re-enabled when the component unmounts
//     return () => {
//         document.body.style.overflow = 'auto';
//     };
// }, [loginError]);

    const [isLoadingSpecialCategories, setLoadingSpecialCategories] = useState(false);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const handleScroll = () => {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const windowHeight = window.innerHeight;
                const docHeight = document.documentElement.offsetHeight;

                // Calculate the distance from the bottom
                const calculatedDistanceFromBottom = docHeight - windowHeight - scrollTop;

                // Update state with the distance from the bottom
                if (calculatedDistanceFromBottom < 1280 && !isLoadingSpecialCategories) {
                    setLoadingSpecialCategories(true);
                    loadSpecialPaginationCategories();
                }
            };

            handleScroll();
        }, 250); // 1000 ms delay

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const docHeight = document.documentElement.offsetHeight;

            // Calculate the distance from the bottom
            const calculatedDistanceFromBottom = docHeight - windowHeight - scrollTop;

            if (calculatedDistanceFromBottom < 1280 && !isLoadingSpecialCategories) {
                setLoadingSpecialCategories(true);
                loadSpecialPaginationCategories();
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line
    }, [isLoadingSpecialCategories]);

    const retrieveBottomTenCategories = () => {
        const bottomCategories = [];
        for (let i = realChars.length - 1; i > realChars.length - 11; i--) {
            if (realChars[i]) {
                const categoryName = Object.keys(realChars[i])[0];
                if (!["Popular", "Recommended For You", "Trending", "Search", "Newest", "Your Characters"].includes(categoryName)) {
                    bottomCategories.push(categoryName);
                }
            }
        }
        return bottomCategories;
    }

    const loadSpecialPaginationCategories = async (retry = false) => {
        const bottomCategories = retrieveBottomTenCategories();
        const response = await fetch('https://api.elyza.app/v1/identity-list', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
            }, body: JSON.stringify({
                userId: null,
                queryMode: "initial-load",
                grouping: "",
                tags: [],
                includeNSFW: true,
                startIndex: 0,
                totalCount: cardsThatFit,
                paginationStage: 999,
                specialCategory: "",
                noRepeatSpecial: bottomCategories,
                targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                languageOverride: languageMapping[language]
            })
        });
        const data = await response.json();
        if (data.results.length > 0) {
            for (let i = 0; i < data.results.length; i++) {
                setRealChars(currentChars => {
                    const updatedChars = [...currentChars];
                    updatedChars.push(data.results[i]);
                    return updatedChars;
                });
            }
        } else if (!retry) {
            loadSpecialPaginationCategories(true);
        }
        setLoadingSpecialCategories(false);
    }

    const trackButton = useButtonTracking();

    const handleCharacterClick = (identityName) => {
        trackButton('view_character', {
            navigate: `/c/${identityName}`,
            identity_name: identityName
        });
    };

    useEffect(() => {
        // eslint-disable-next-line
        fetchCharacterList();
        // eslint-disable-next-line
    }, [search, ownCharacters, setRealChars]);

// eslint-disable-next-line
    const checkScroll = (ref, index, categoryName) => {
        const node = ref.current;
        if (node) {
            const atStart = node.scrollLeft === 0;
            const atEnd = node.scrollLeft + node.offsetWidth >= node.scrollWidth - 1;

            if (atStart) {
                node.classList.add('no-blur-left');
            } else {
                node.classList.remove('no-blur-left');
            }

            if (atEnd && !atStart) {
                node.classList.add('no-blur-right');

                loadMoreCharacters(index, categoryName);
            } else {
                node.classList.remove('no-blur-right');
            }
        }
    };

    const [isScrolling, setIsScrolling] = useState(false);

    const smoothScroll = (element, target, duration) => {
        setIsScrolling(true);
        let start = element.scrollLeft,
            change = target - start,
            startTime = null;

        // EaseInOutQuad function
        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        const animateScroll = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const timeElapsed = timestamp - startTime;
            element.scrollLeft = easeInOutQuad(timeElapsed, start, change, duration);

            if (timeElapsed < duration) {
                requestAnimationFrame(animateScroll);
            } else {
                element.scrollLeft = target; // Ensure it ends at the exact target
                setIsScrolling(false); // Animation completed
            }
        };

        requestAnimationFrame(animateScroll);
    };

    const scrollAmount = 300; // Scroll amount in pixels
    const scrollDuration = 500; // Scroll duration in milliseconds

    const scrollLeft = (index) => {
        const ref = rowRefs.current[index];
        if (ref && ref.current) {
            smoothScroll(ref.current, ref.current.scrollLeft - scrollAmount, scrollDuration);
        }
    };

    const scrollRight = (index) => {
        const ref = rowRefs.current[index];
        if (ref && ref.current) {
            smoothScroll(ref.current, ref.current.scrollLeft + scrollAmount, scrollDuration);
        }
    };

    useEffect(() => {
        realChars.forEach((category, index) => {
            const categoryName = Object.keys(category)[0] ?? "Your Characters";
            if (rowRefs.current[index]) {
                checkScroll(rowRefs.current[index], index, categoryName);
            }
        });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        const newTimeout = setTimeout(() => {
            if (!containsSearchValue() && search.length > 0) {
                setLoadingTagOrSearch(true);
            } else {
                setLoadingTagOrSearch(false);
            }

            setRealChars(currentChars => {
                const updatedChars = [...currentChars];

                updatedChars.forEach((category, index) => {
                    const name = Object.keys(category)[0];
                    if (name.includes("Search")) {
                        updatedChars.splice(index, 1);
                    }
                });

                if (search.length > 0) {
                    setSelectedTags([]); // Adjust this to send data to the tag selector
                    if (searchCharacters.length > 0) {
                        updatedChars.unshift({[`Search Results for "${search}"`]: searchCharacters});
                    }
                }
                setLoadingTagOrSearch(false);

                return updatedChars;
            });
        },);
        return () => {
            clearTimeout(newTimeout);
        };
// eslint-disable-next-line
    }, [searchCharacters]); // Dependencies array

    useEffect(() => {
        if (search.length === 0) {
            setRealChars(currentChars => {
                const updatedChars = [...currentChars];

                updatedChars.forEach((category, index) => {
                    const name = Object.keys(category)[0];
                    if (name.includes("Search")) {
                        updatedChars.splice(index, 1);
                    }
                });

                return updatedChars;
            });
        }
        // eslint-disable-next-line
    }, [search])

    const containsSearchValue = () => {
        let isContain = false;
        realChars.forEach((category, index) => {
            const categoryName = Object.keys(category)[0];
            if (categoryName.includes("Search")) {
                isContain = true;
                return isContain;
            }
        });
        return isContain;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            rowRefs.current.forEach((ref, index) => {
                if (ref.current) {
                    // eslint-disable-next-line
                    const categoryName = realChars[index]?.categoryName; // Replace with how you access categoryName
                    checkScroll(ref, index, categoryName);
                }
            });
        }, 1); // Run after 1ms

        return () => {
            clearTimeout(timer);
        };
    }, [realChars, checkScroll]);


    const loadMoreCharacters = useCallback(async (index, categoryName) => {

        if (isAdditionalLoading) return;
        setAdditionalLoading(true);

        const userId = await userIDExists() ? await getUserID() : "";
        // Ensure we're correctly accessing the category's characters
        const categoryChars = realChars[index] ? realChars[index][categoryName] : [];

        if (categoryChars === undefined) {
            setAdditionalLoading(false);
            return;
        }

        // Calculate the startIndex correctly; consider revising based on actual data structure
        const startIndex = categoryChars.reduce((acc, char) => (!char.isPlaceholder ? acc + 1 : acc), 0);

        // Insert placeholders correctly within the category
        const placeholders = new Array(15).fill(null).map((_, idx) => ({
            isPlaceholder: true,
            id: `placeholder-${startIndex + idx}`
        }));
        setRealChars(currentChars => {
            const updatedChars = [...currentChars];
            if (updatedChars[index] && updatedChars[index][categoryName]) {
                updatedChars[index][categoryName] = [...updatedChars[index][categoryName], ...placeholders];
            }
            return updatedChars;
        });

        let isSpecial = isSpecialCategory(index, categoryName);

        let response;
        if (categoryName.includes("Your Characters")) { // TODO: Recommended For You
            response = await fetch('https://api.elyza.app/v1/identity-list', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify({
                    userId: userId,
                    queryMode: "singular-load",
                    grouping: "",
                    tags: [],
                    includeNSFW: true,
                    startIndex: startIndex,
                    totalCount: cardsThatFit,
                    paginationStage: null,
                    specialCategory: "",
                    targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                    languageOverride: languageMapping[language]
                })
            });
        } else if (categoryName.includes("Search")) {
            response = await fetch('https://api.elyza.app/v1/search-identity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    searchText: search,
                    includeNSFW: true,
                    count: cardsThatFit,
                    offset: categoryChars.length
                })
            });
        } else {
            response = await fetch('https://api.elyza.app/v1/identity-list', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify({
                    userId: userId,
                    queryMode: "singular-load",
                    grouping: ["Popular", "Trending", "Newest", "Recommended For You", "Your Characters"].includes(categoryName) && !isSpecial ? categoryName : "",
                    tags: ["Popular", "Trending", "Newest", "Recommended For You", "Your Characters"].includes(categoryName) || isSpecial ? [] : (categoryName.includes(",") ? categoryName.split(", ") : [categoryName]),
                    includeNSFW: true,
                    startIndex: startIndex,
                    totalCount: cardsThatFit,
                    paginationStage: null,
                    specialCategory: isSpecial ? categoryName : "",
                    targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                    languageOverride: languageMapping[language]
                })
            });
        }

        const resp = await response.json();

        if (categoryName.includes("Search")) {
            if (resp && resp.results) {
                const newChars = resp.results;
                setRealChars(currentChars => {
                    const updatedChars = [...currentChars];
                    if (updatedChars[index] && updatedChars[index][categoryName]) {
                        // Remove placeholders and add actual characters
                        const existingCharsWithoutPlaceholders = updatedChars[index][categoryName].filter(char => !char.isPlaceholder);
                        updatedChars[index][categoryName] = [...existingCharsWithoutPlaceholders, ...newChars];
                    }
                    return updatedChars;
                });
                setHasMore(newChars.length === 10);
            } else {
                setRealChars(currentChars => {
                    const updatedChars = [...currentChars];
                    if (updatedChars[index] && updatedChars[index][categoryName]) {
                        updatedChars[index][categoryName] = updatedChars[index][categoryName].filter(char => !char.isPlaceholder);
                    }
                    return updatedChars;
                });
                setHasMore(false);
            }
        } else {
            if (resp && resp.results && resp.results[0] && Array.isArray(resp.results[0][categoryName])) {
                const newChars = resp.results[0][categoryName];
                setRealChars(currentChars => {
                    const updatedChars = [...currentChars];
                    if (updatedChars[index] && updatedChars[index][categoryName]) {
                        // Remove placeholders and add actual characters
                        const existingCharsWithoutPlaceholders = updatedChars[index][categoryName].filter(char => !char.isPlaceholder);
                        updatedChars[index][categoryName] = [...existingCharsWithoutPlaceholders, ...newChars];
                    }
                    return updatedChars;
                });
                setHasMore(newChars.length === 10);
            } else {
                setRealChars(currentChars => {
                    const updatedChars = [...currentChars];
                    if (updatedChars[index] && updatedChars[index][categoryName]) {
                        updatedChars[index][categoryName] = updatedChars[index][categoryName].filter(char => !char.isPlaceholder);
                    }
                    return updatedChars;
                });
                setHasMore(false);
            }
        }

        setAdditionalLoading(false);
        // eslint-disable-next-line
    }, [isAdditionalLoading, hasMore, realChars, setRealChars]);

    const isSpecialCategory = (index, name) => { // TODO: INCORPORATE EMBEDDING VERSION OF TAGS
        let newestRow = -1;
        realChars.forEach((category, index) => {
            const categoryName = Object.keys(category)[0];
            if (categoryName === "Newest") {
                newestRow = index;
            }
        });
        if (newestRow !== -1 && index > newestRow) return true;
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
    const getFirstRowCategoryName = () => {
        if (realChars.length > 0) {
            return Object.keys(realChars[0])[0];
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const tagsString = selectedTags.join(", ");
            const firstRowCategoryName = getFirstRowCategoryName();
            if (search.length === 0 && firstRowCategoryName !== tagsString) {
                handleSelectedTagsChange(selectedTags, true).then(r => {
                });
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, [realChars, getFirstRowCategoryName, handleSelectedTagsChange, selectedTags, search]);

    const loadSpecificTagCharacters = useCallback(async (selectedTags, nestingLevel = 0) => {
        const userId = await userIDExists() ? await getUserID() : "";

        // TODO: Figure out the point of this || hasSelectedTagStuff
        if (selectedTags.length === 0) {
            setRealChars(currentChars => {
                let updatedChars = [...currentChars];
                if (updatedChars.length > 0) {
                    // TypeError: can't convert undefined to object
                    try {
                        const firstRow = Object.keys(realChars[0])[0];

                        if (firstRow !== "Popular" && firstRow !== "Trending" && firstRow !== "Recommended For You" && !firstRow.includes("Search") && !firstRow.includes("Your Characters")) {
                            updatedChars.shift();
                        }

                        let keysToRemove = [];
                        for (let i = 0; i < updatedChars.length; i++) {
                            const name = Object.keys(updatedChars[i])[0];
                            if (!["Popular", "Recommended For You", "Trending", "Search", "Newest", "Your Characters"].includes(name)) {

                                keysToRemove.push(name);
                            } else {
                                break;
                            }
                        }
                        keysToRemove.forEach((key) => {
                            updatedChars = updatedChars.filter((obj) => {
                                const name = Object.keys(obj)[0];
                                return name !== key;
                            });
                        });
                    } catch (e) {
                        if (nestingLevel < 2) {
                            loadSpecificTagCharacters(selectedTags, nestingLevel + 1).then(r => {

                                setLoadingTagOrSearch(false);

                            });
                        }
                        console.log(e);
                    }
                }

                return updatedChars;
            });
        } else {
            const response = await fetch('https://api.elyza.app/v1/identity-list', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify({
                    userId: userId,
                    queryMode: "singular-load",
                    grouping: "",
                    tags: selectedTags,
                    includeNSFW: true,
                    startIndex: 0,
                    totalCount: cardsThatFit,
                    paginationStage: null,
                    specialCategory: "",
                    targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                    languageOverride: languageMapping[language]
                })
            });

            setSearchText("");

            const resp = await response.json();
            if (resp && resp.results && resp.results[0] && Array.isArray(resp.results[0][Object.keys(resp.results[0])[0]])) {
                const newChars = resp.results[0][Object.keys(resp.results[0])[0]];
                setRealChars(currentChars => {
                    let updatedChars = [...currentChars];
                    if (updatedChars.length === 0) {
                        //TODO: Look out for this
                        return updatedChars;
                    }
                    const firstRow = Object.keys(updatedChars[0])[0];

                    if (newChars.length === 0) {
                        if (firstRow !== "Popular" && !firstRow.includes("Trending") && firstRow !== "Your Characters" && !firstRow.includes("Search")) { // TODO: Recommended For You
                            updatedChars.shift();
                        }

                        return updatedChars;
                    } else {
                        if (firstRow !== "Popular" && !firstRow.includes("Trending") && firstRow !== "Your Characters" && !firstRow.includes("Search")) { // TODO: Recommended For You
                            updatedChars.shift();
                        }

                        let keysToRemove = [];
                        for (let i = 0; i < updatedChars.length; i++) {
                            const name = Object.keys(updatedChars[i])[0];
                            if (!["Popular", "Trending", "Search", "Newest", "Recommended For You", "Your Characters"].includes(name)) {
                                keysToRemove.push(name);
                            } else {
                                break;
                            }
                        }
                        keysToRemove.forEach((key) => {
                            updatedChars = updatedChars.filter((obj) => {
                                const name = Object.keys(obj)[0];
                                return name !== key;
                            });
                        });

                        for (let i = 1; i < resp.results.length; i++) {
                            const name = Object.keys(resp.results[i])[0];
                            updatedChars.unshift({[name]: resp.results[i][name]});
                        }
                        updatedChars.unshift({[Object.keys(resp.results[0])[0]]: newChars});
                    }

                    return updatedChars;
                });

            }
        }
// eslint-disable-next-line
    }, [isAdditionalLoading, hasMore, realChars, setRealChars]);


    const PseudoCard = () => (
        <div className="pseudo-card">
            <div className="pseudo-card-content">
            </div>
        </div>
    );

    const [isLoadingTagOrSearch, setLoadingTagOrSearch] = useState(false);

// const handleCloseModal = () => {
//     setLoginError(false);
// };
//
// const handleModalClick = (event) => {
//     event.stopPropagation(); // Prevent click from propagating to the overlay
// };

    const [isBannerVisibleFirstDiscord, setIsBannerVisibleFirstDiscord] = useState(true);
// const [isBannerVisibleRefer, setIsBannerVisibleRefer] = useState(true);
    const [isBannerVisibleUpgrade, setIsBannerVisibleUpgrade] = useState(true);
    const [isBannerHeroVisible, setIsBannerHeroVisible] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
            setIsBannerHeroVisible(!loggedIn); // Set isBannerHeroVisible to true if user is not logged in
        };

        const checkBannerHidden = () => {
            const bannerHidden = Cookies.get('bannerHidden') === 'true';
            setIsBannerHeroVisible(!bannerHidden); // Set isBannerHeroVisible to false if bannerHidden cookie is true
        };

        checkUserLoggedIn().then(checkBannerHidden);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const isVisible = window.innerWidth >= 680;
            setIsBannerVisibleFirstDiscord(isVisible);
            // setIsBannerVisibleRefer(isVisible);
            setIsBannerVisibleUpgrade(isVisible);

        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

// Functions to hide each banner
    const hideBannerDiscord = () => setIsBannerVisibleFirstDiscord(false);
// const hideBannerRefer = () => setIsBannerVisibleRefer(false);
    const hideBannerUpgrade = () => setIsBannerVisibleUpgrade(false);
    const hideTopHeroBanner = () => setIsBannerHeroVisible(false);

    const {timerExpired} = useTimer();

    const [isWide, setIsWide] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [popupOpacity, setPopupOpacity] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollDistance = window.scrollY;
            const startThreshold = 1480; // Start appearing at 800px
            const fullOpacityThreshold = 2400; // Full opacity at 1600px

            // Calculate the opacity proportionally between the thresholds
            const opacity = scrollDistance >= startThreshold ?
                Math.min((scrollDistance - startThreshold) / (fullOpacityThreshold - startThreshold), 1) :
                0;

            setPopupOpacity(opacity);

            // Prevent further scrolling when the popup is fully visible
            if (opacity === 1) {
                window.scrollTo(0, fullOpacityThreshold);
            }
        };

        // Add the event listener only if the user is not logged in
        if (!isLoggedIn) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoggedIn]);

    const {profileData} = useProfileData();

    useEffect(() => {
        if (isLoggedIn && !profileData.is_verified) {
            setIsEmailVerificationModalOpen(true);
        }
    }, [isLoggedIn, profileData.is_verified]);

    const [isBlurred, setIsBlurred] = useState(true);

    usePageTracking('main_page');

    const [ageVerificationModalOpen, setAgeVerificationModalOpen] = useState(false);

    const unblurAllImages = () => {
        setIsBlurred(false);
        // Set a cookie that indicates user is age-verified
        Cookies.set(NSFW_VERIFICATION_COOKIE, 'true', {expires: 365});
    };

    const NSFW_VERIFICATION_COOKIE = 'nsfwAgeVerified';

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        return savedLanguage || i18n.language;
    });

    useEffect(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        const i18nLang = i18n.language;

        if (savedLanguage && savedLanguage !== currentLanguage) {
            setCurrentLanguage(savedLanguage);
        } else if (!savedLanguage && i18nLang !== currentLanguage) {
            setCurrentLanguage(i18nLang);
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    return (
        <div className="character-list-menu">

            {!isLoggedIn &&
                <SignUpToSeeMore opacity={popupOpacity} onClose={() => {
                }}/>
            }

            {isEmailVerificationModalOpen && (
                <EmailVerification isOpen={isEmailVerificationModalOpen}
                                   onClose={() => setIsEmailVerificationModalOpen(false)}/>
            )}

            {!timerExpired ? (
                <div className="top-timer-container-main">
                    <TopTimer/>
                </div>
            ) : null}

            {isLoading ? (
                <div className="loading-spin">
                    <MainPageLoader ariaLabel="tail-spin-loading"/>
                </div>
            ) : null}

            {/*{loginError ? (*/}
            {/*    <div className="ErrorScreenOverlay">*/}
            {/*        <AuthModal onClose={handleCloseModal} onClick={handleModalClick}/>*/}
            {/*    </div>*/}
            {/*) : null}*/}

            {!isBannerHeroVisible &&
                <TagProvider>
                    <div style={{
                        marginTop: isWide <= 680 ? (timerExpired ? '-10px' : '45px') : (timerExpired ? '105px' : '165px'),

                        marginBottom: '-15px'
                    }}>
                        <TagSystem search={search} onSelectedTagsChange={handleSelectedTagsChange}
                                   reverseOverride={reverseOverride}/>
                    </div>
                </TagProvider>
            }

            {isWide <= 680 &&
                (isLoading === false && isBannerHeroVisible === true ? (
                    <MobileHeroBanner
                        isBannerVisibleFirstDiscord={isBannerHeroVisible}
                        hideBanner={hideTopHeroBanner}/>
                ) : null)
            }

            {isWide >= 680 && (
                <div className="another-event-container">
                    <div className="event-mainmenu-container">
                        <div>
                            {isBannerHeroVisible === true ? (
                                <>

                                    <div style={{marginTop: timerExpired ? '105px' : '175px'}}>
                                        <HeroBannerMain
                                            isBannerVisibleFirstDiscord={isBannerHeroVisible}
                                            hideBanner={hideTopHeroBanner}
                                        />
                                    </div>
                                    <TagProvider>
                                        <div style={{marginTop: '25px'}}>
                                            <TagSystem search={search} onSelectedTagsChange={handleSelectedTagsChange}/>
                                        </div>
                                    </TagProvider>
                                </>
                            ) : isLoggedIn && (
                                <div style={{marginTop: '30px'}}>
                                    <JoinCommunityBanner
                                        isBannerVisibleFirstDiscord={isBannerVisibleFirstDiscord}
                                        hideBanner={hideBannerDiscord}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="GeneralRowsChars">
                {
                    isLoadingTagOrSearch && (
                        <div>
                            <div className="loading-indicator"></div>
                            <div className="placeholder-row">
                                {Array.from({length: cardsThatFit}, (_, index) => (
                                    <PseudoCard key={index}/>
                                ))}
                            </div>
                        </div>
                    )
                }
                {realChars.reduce((acc, category, index) => {
                    if (!rowRefs.current[index]) {
                        rowRefs.current[index] = React.createRef();
                    }

                    const categoryName = Object.keys(category)[0] ?? "Your Characters"; // TODO: Recommended For You
                    const characters = category[categoryName];

                    // Render the category row if it has characters
                    if (Array.isArray(characters) && characters.length > 0) {
                        acc.rows.push(
                            <div className="general-row-container" key={index}>
                                <h2>{t(getCategoryLabel(categoryName))}</h2>
                                <div className="scroll-buttons-container">
                                    <button className="scroll-button-character left-scroll"
                                            onClick={() => scrollLeft(index, categoryName)}
                                            disabled={isScrolling}>
                                        <LeftArrowIcon/>
                                    </button>
                                    <button className="scroll-button-character right-scroll"
                                            onClick={() => scrollRight(index, categoryName)}
                                            disabled={isScrolling}>
                                        <RightArrowIcon/>
                                    </button>
                                </div>
                                <div className="character-row">
                                    <div className="character-container-grid-new" ref={rowRefs.current[index]}
                                         onScroll={() => checkScroll(rowRefs.current[index], index, categoryName)}>
                                        {
                                            characters.map((character, charIndex) => (
                                                character.isPlaceholder ?
                                                    <PseudoCard key={`placeholder-${charIndex}`}/> :
                                                    <div className="character-div-new" key={character.id || charIndex}>
                                                        <CharacterContainer
                                                            character={character}
                                                            handleCharacterClick={handleCharacterClick}
                                                            username={username}
                                                            isBlurred={isBlurred}
                                                            setIsBlurred={setIsBlurred}
                                                            ageVerificationModalOpen={ageVerificationModalOpen}
                                                            setAgeVerificationModalOpen={setAgeVerificationModalOpen}
                                                        />
                                                    </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        );

                        // Increment the counter for each category processed
                        acc.counter += 1;

                        // After every 12 rows, insert an event container
                        if (acc.counter % 12 === 0) {

                            acc.rows.push(
                                <>
                                    {isLoggedIn && (

                                        <div className="another-event-container" key={`event-${acc.counter}`}>
                                            <div className="event-mainmenu-container">
                                                {acc.bannerCounter === 3 && isBannerVisibleFirstDiscord &&
                                                    <JoinCommunityBanner
                                                        isBannerVisibleFirstDiscord={isBannerVisibleFirstDiscord}
                                                        hideBanner={hideBannerDiscord}
                                                    />
                                                }
                                                {/*{acc.bannerCounter === 2 && isBannerVisibleRefer &&*/}
                                                {/*    <ReferUsersBanner*/}
                                                {/*        isBannerVisible={isBannerVisibleRefer}*/}
                                                {/*        hideBanner={hideBannerRefer}*/}
                                                {/*    />*/}
                                                {/*}*/}
                                                {acc.bannerCounter === 1 && isBannerVisibleUpgrade &&
                                                    <UpgradePlanBanner
                                                        isBannerVisible={isBannerVisibleUpgrade}
                                                        hideBanner={hideBannerUpgrade}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                            // Update the banner counter, cycling back to 1 after reaching 3
                            acc.bannerCounter = acc.bannerCounter % 3 + 1;
                        }
                    }
                    if (index === realChars.length - 1) {
                        // Add three placeholder rows
                        for (let i = 0; i < 3; i++) {
                            acc.rows.push(
                                <div>
                                    <div className="loading-indicator"></div>

                                    <div className="placeholder-row">
                                        {Array.from({length: cardsThatFit}, (_, index) => (
                                            <PseudoCard key={index}/>
                                        ))}
                                    </div>
                                </div>
                            );
                        }
                    }
                    return acc;
                }, {rows: [], counter: 0, bannerCounter: 1}).rows // Extract the rows for rendering
                }
            </div>
            <AgeVerificationModal
                isOpen={ageVerificationModalOpen}
                onClose={() => setAgeVerificationModalOpen(false)}
                onConfirmAge={unblurAllImages}
            />
        </div>
    );

}