import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../Notifications/ModalAndNotifications.css';
import { ReactComponent as CloseIcon } from "../MainMenuPage/icons/cross.svg";
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import "./DeleteAccountDropdown.css"
import { getUserAccessToken } from "../../App";

const DeleteAccountModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [reason, setReason] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [confirmPhrase, setConfirmPhrase] = useState('');
    const requiredPhrase = t('deletePhrase');
    const [error, setError] = useState(null);

    const deleteReasons = [
        { value: '', label: t('selectReason') },
        { value: 'not-liked', label: t('reasonNotLiked'), hasOptionalText: true },
        { value: 'personal-reason', label: t('reasonPersonal') },
        { value: 'found-alternative', label: t('reasonAlternative'), hasOptionalText: true },
        { value: 'privacy-concerns', label: t('reasonPrivacy') },
        { value: 'other', label: t('reasonOther'), hasOptionalText: true }
    ];

    const isDeleteEnabled = reason && confirmPhrase === requiredPhrase;

    const handleReasonChange = (option) => {
        setReason(option.value);
        setAdditionalInfo('');
        setError(null);
    };

    const getCurrentReason = () => {
        return deleteReasons.find(option => option.value === reason);
    };

    const handleDeleteAccount = async () => {
        try {
            const response = await fetch('https://api.elyza.app/v1/delete-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    deletionReason: reason,
                    optionalInput: additionalInfo || ''
                })
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || t('errorMessage'));
            }

            localStorage.clear();
            sessionStorage.clear();
            onClose();
            window.location.reload();

        } catch (err) {
            setError(err.message || t('errorMessage'));
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="modal-backdrop" style={{ alignItems: 'center', zIndex: '9999' }}>
            <div className="modal-content-notification" style={{overflow: "unset"}}>
                <div className='notification-label'>
                    {t('deleteAccount')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        {t('sadToSeeYouGo')}
                        <br />
                        <span className="colored-purple-text"> {t('specifyReason')}</span>
                    </div>

                    <Dropdown
                        options={deleteReasons}
                        onChange={handleReasonChange}
                        value={deleteReasons.find(option => option.value === reason)}
                        placeholder={t('selectReason')}
                        className="delete-account-dropdown"
                        controlClassName="delete-account-dropdown-control"
                        menuClassName="delete-account-dropdown-menu"
                        arrowClassName="delete-account-dropdown-arrow"
                    />

                    {reason && getCurrentReason()?.hasOptionalText && (
                        <div className="textbox-name-general">
                            <div className="sublabel-notification">
                                {t('tellUsMore')}
                            </div>
                            <input
                                type="text"
                                value={additionalInfo}
                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                placeholder={t('sharePlaceholder')}
                                className="additional-info-input"
                            />
                        </div>
                    )}

                    {reason && (
                        <div className="textbox-name-general">
                            <div className="sublabel-notification">
                                {t('confirmDeletion')}
                                <span className="colored-purple-text"> {requiredPhrase}</span>
                            </div>
                            <input
                                type="text"
                                value={confirmPhrase}
                                onChange={(e) => {
                                    setConfirmPhrase(e.target.value);
                                    setError(null);
                                }}
                                placeholder={t('typePlaceholder')}
                            />
                            {error && <div className="error-message">{error}</div>}
                        </div>
                    )}
                </div>

                <button
                    onClick={handleDeleteAccount}
                    className={`notification-button-main ${!isDeleteEnabled ? 'disabled' : ''}`}
                    disabled={!isDeleteEnabled}
                >
                    {t('continueButton')}
                </button>
            </div>
        </div>,
        document.body
    );
};

export default DeleteAccountModal;