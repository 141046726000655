import React, {useCallback, useEffect, useState} from 'react';
import './CharacterFunnel.css';
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {ReactComponent as GoBackIcon} from '../../../pages/AdvancedCharacterCreation/icons/backIcon.svg';
import {ReactComponent as GoForwardIcon} from '../../../pages/AdvancedCharacterCreation/icons/forwardIcon.svg';
import {useStepsConfig} from "./StepsConfig";
import CharNamePopup from "../LocalizedPopups/CharNamePopup";
import {SimplifiedSignUp} from "../LocalizedPopups/SimplifiedSignUp";
import LoadingScreen from "../../LoadingEvents/EpicLoadingScreen";
import {getUserAccessToken, getUserID, userIDExists} from "../../../App";
import {useFunnelCharacterContext} from "./FunnelContext";
import SuccessModal from "../../../pages/SimpleCharacterCreation/screens/SuccessModal";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";

const ProgressBar = ({currentStep, totalSteps}) => {
    return (
        <div className="progress-bar-container-char-funnel">
            <div className="progress-bar-background"></div>
            <div className="progress-bar-line" style={{width: `${(currentStep) / (totalSteps - 1) * 100}%`}}></div>
            {Array.from({length: totalSteps}).map((_, index) => (
                <div key={index} className={`circle-progress-bar ${index <= currentStep ? 'active' : ''}`}></div>
            ))}
            <div className="progress-user-icon" style={{left: `${(currentStep) / (totalSteps - 1) * 100}%`}}></div>
        </div>
    );
};

const CharacterFunnel = ({version}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [choices, setChoices] = useState({});
    const {t} = useTranslation();
    const steps = useStepsConfig(version);
    const StepComponent = steps[currentStep]?.component;
    const isLastStep = currentStep === steps.length - 1;
    const { funnelCharData, clearOptions } = useFunnelCharacterContext();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [previousLoginState, setPreviousLoginState] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);

            if (loggedIn && !previousLoginState && funnelCharData.charName) {
                setPreviousLoginState(loggedIn);
                await handleCreateCharacter();
            }
        };

        checkUserLoggedIn();
        //eslint-disable-next-line
    }, [isLoggedIn, previousLoginState]);

    const handleChoice = (choice) => {
        setChoices(prev => ({
            ...prev,
            [currentStep]: choice
        }));
    };

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const [askForNamePopup, setAskForNamePopup] = useState(false);
    const [simplifiedSignup, setSimplifiedSignup] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [charName, setCharName] = useState('');

    const handleSimpleFinalize = () => {
        setAskForNamePopup(true);
    };

    const handleNameSubmit = () => {
        setAskForNamePopup(false);
        setIsLoading(true);  // Start loading

        // Simulate processing time
        setTimeout(() => {
            setIsLoading(false);  // Stop loading
            setSimplifiedSignup(true);  // Show signup
        }, 1000);  // 3 seconds delay
    };

    const handleSignupComplete = async (loginSuccessful) => {
        setIsLoading(true);
        try {
            if (loginSuccessful) {
                setIsLoggedIn(true);  // Update the login state
                await handleCreateCharacter();
            } else {
                console.warn("Login was not successful");
            }
        } catch (error) {
            console.error("Error during character creation:", error);
        } finally {
            setIsLoading(false);
            setSimplifiedSignup(false);
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 680);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [identityName, setIdentityName] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const navigate = useNavigate();

    const capitalize = (str) => {
        if (!str) return str;

        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';

        for (let char of str) {
            const index = lowercase.indexOf(char);
            result += index !== -1 ? uppercase[index] : char;
        }

        return result;
    };

    const [language] = useState(() => {
        const initialLang = localStorage.getItem('userLanguage') || i18n.language;
        return capitalize(initialLang);
    });

    const handleCreateCharacter = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await fetch('https://api.elyza.app/v1/hyper-simplified-creator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken(),
                },
                body: JSON.stringify({
                    charName: funnelCharData.charName,
                    style: funnelCharData.style,
                    gender: funnelCharData.gender,
                    ethnicity: funnelCharData.ethnicity,
                    hairColor: funnelCharData.hairColor,
                    hairStyle: funnelCharData.hairStyle,
                    breastSize: funnelCharData.breastSize,
                    dickSize: funnelCharData.dickSize,
                    buttSize: funnelCharData.buttSize,
                    personality: funnelCharData.personality,
                    scenario: funnelCharData.scenario,
                    voiceSelection: funnelCharData.voiceSelection,
                    languageSelection: language,
                    specialCase: 'FunnelCharacter'
                }),
            });

            const data = await response.json();

            if (data.identityName) {
                setSuccessModalOpen(true);
                setIdentityName(data.identityName);
            }
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }, [funnelCharData, language]);

    const handleStartChatting = async () => {

        try {
            const id = await getUserID();
            if (!identityName) {
                console.error('No identity name available');
                // Handle this case (e.g., show an error message to the user)
                return;
            }

            await fetch('https://api.elyza.app/v1/create-character', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: await getUserAccessToken(),
                },
                body: JSON.stringify({
                    userId: id,
                    identityName: identityName,
                    creatorId: "",
                    language: language
                }),
            });

            navigate(`/chat/${identityName}`, { state: { autoSelect: true } });
        } catch (error) {
            console.error('Error starting chat:', error);
        } finally {
            clearOptions();
        }
    };

    if (!StepComponent) return null;

    return (
        <>

            <SuccessModal
                isOpen={successModalOpen}
                onClose={() => setSuccessModalOpen(false)}
                characterName={funnelCharData.charName}
                startChatting={handleStartChatting}
            />

            <CharNamePopup
                isOpen={askForNamePopup}
                onClose={() => setAskForNamePopup(false)}
                charName={charName}
                setCharName={setCharName}
                handleFinished={handleNameSubmit}
            />

            <SimplifiedSignUp
                isOpen={simplifiedSignup}
                onClose={() => setSimplifiedSignup(false)}
                initialUsername={charName}
                onComplete={handleSignupComplete}
            />

            <LoadingScreen
                isLoading={isLoading}
            />

            <div className="character-creation-header"
                 style={{marginTop: isMobile ? "135px" : '85px'}}
            >
                <h1 className="main-title">{t('CharacterCreatorTitle')}</h1>
                <p className="main-description">{t('CharacterCreatorDescription')}</p>
            </div>

            <div
                style={{width: '100%', display: 'flex', justifyContent: 'center'}}
            >
                <ProgressBar
                    currentStep={currentStep}
                    totalSteps={steps.length}
                />
            </div>

            <div
                style={{width: '100%', display: 'flex', justifyContent: 'center'}}
            >
                <div className="funnel-container">


                    <div className="funnel-content">
                        {/*<div className="step-header">*/}
                        {/*    <h2 className="step-title">{steps[currentStep].title}</h2>*/}
                        {/*    <h3 className="step-subtitle">{steps[currentStep].subtitle}</h3>*/}
                        {/*</div>*/}

                        <StepComponent
                            selected={choices[currentStep]}
                            onSelect={handleChoice}
                        />

                        <motion.div
                            className="navigation-buttons"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: 0.5}}
                        >
                            <button
                                onClick={handlePrevious}
                                disabled={currentStep === 0}
                                className="nav-button prev-button"
                            >
                                <GoBackIcon/>
                            </button>
                            {isLastStep ? (
                                <button
                                    onClick={handleSimpleFinalize}
                                    className="nav-button next-button"
                                >
                                    <span>{t('Finish')}</span>
                                    <GoForwardIcon/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleNext}
                                    className="nav-button next-button"
                                >
                                    <span>{t('Next')}</span>
                                    <GoForwardIcon/>
                                </button>
                            )}
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CharacterFunnel;