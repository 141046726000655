import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import Dropdown from "react-dropdown";
import './CharCreatorSteps.css';
import { useCharacterCreation } from "../CharacterCreationContext";

const SimpleStepTen = () => {
    const { t } = useTranslation();
    const { characterData, updateCharacterData } = useCharacterCreation();
    const [age, setAge] = useState(characterData.age || '');
    const [name, setName] = useState(characterData.charName || '');
    const [scenario, setScenario] = useState(characterData.scenario || '');

    const [isTypingAge, setIsTypingAge] = useState(false);

    const baseAdvancedSettings = [
        { value: 'Futa', label: t('AdvancedFuta') },
        { value: 'Femboy', label: t('AdvancedFemboy') },
        { value: 'Furry', label: t('AdvancedFurry') },
    ];

    const [selectedAdvancedSetting, setSelectedAdvancedSetting] = useState('');

    const advancedSettings = useMemo(() => {
        if (selectedAdvancedSetting) {
            return [
                ...baseAdvancedSettings,
                { value: '', label: t('AdvancedReset') }
            ];
        }
        return baseAdvancedSettings;
    // eslint-disable-next-line
    }, [selectedAdvancedSetting]);

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const validateAge = useCallback(() => {
        if (age !== '') {
            const numAge = parseInt(age, 10);
            if (numAge < 18) {
                setAge('18');
                updateCharacterData({age: '18'});
            } else {
                updateCharacterData({age: numAge.toString()});
            }
        }
    }, [age, updateCharacterData]);

    //eslint-disable-next-line
    const debouncedValidateAge = useCallback(debounce(validateAge, 1000), [validateAge]);

    useEffect(() => {
        if (!isTypingAge) {
            debouncedValidateAge();
        }
    }, [age, isTypingAge, debouncedValidateAge]);

    const handleNameChange = (e) => {
        setName(e.target.value);
        updateCharacterData({charName: e.target.value});
    };

    const handleAgeChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d+$/.test(value)) {
            setAge(value);
            setIsTypingAge(true);
            debounce(() => setIsTypingAge(false), 2000)();
        }
    };

    const handleScenarioChange = (e) => {
        setScenario(e.target.value);
        updateCharacterData({scenario: e.target.value});
    };

    const handleChangeAdvancedSetting = (option) => {
        setSelectedAdvancedSetting(option.value);
        updateCharacterData({specialCase: option.value})
    };

    const quickInsertOptions = [
        { label: t("Classmates"), text: t("ClassmatesText") },
        { label: t("ClubMembers"), text: t("ClubMembersText") },
        { label: t("StudyBuddies"), text: t("StudyBuddiesText") },
        { label: t("OldFriends"), text: t("OldFriendsText") },
        { label: t("Neighbors"), text: t("NeighborsText") },
        { label: t("Strangers"), text: t("StrangersText") },
        { label: t("BestFriends"), text: t("BestFriendsText") },
        { label: t("InternetFriends"), text: t("InternetFriendsText") },
        { label: t("Roommates"), text: t("RoommatesText") },

        //siri pitor pasxalka lololol
        { label: t("CoWorkers"), text: t("CoWorkersText") },

        //hugo pasxalka
        { label: t("Enemies"), text: t("EnemiesText") },
    ];

    if (characterData.gender === 'Female') {
        quickInsertOptions.push(
            { label: t("StepSister"), text: t("StepSisterText") },
            { label: t("StepMom"), text: t("StepMomText") }
        );
    } else if (characterData.gender === 'Male') {
        quickInsertOptions.push(
            { label: t("StepBrother"), text: t("StepBrotherText") },
            { label: t("StepDad"), text: t("StepDadText") }
        );
    }

    const handleQuickInsert = (text) => {
        setScenario(prevScenario => {
            const newScenario = prevScenario ? `${prevScenario} ${text}` : text;
            updateCharacterData({scenario: newScenario});
            return newScenario;
        });
    };

    return (
        <div className="final-step-container">
            <div className="input-container-top">
                <div className="textbox-name-general" style={{width: '50%'}}>
                    <label className="label-sign-up-h">{t('CharacterName')}</label>
                    <input
                        type="text"
                        placeholder={t('CharacterNamePlaceholder')}
                        value={name}
                        onChange={handleNameChange}
                        maxLength="25"
                    />
                </div>
                <div className="textbox-name-general" style={{width: '50%'}}>
                    <label className="label-sign-up-h">{t('Age')}</label>
                    <input
                        type="text"
                        placeholder={t('AgePlaceholder')}
                        value={age}
                        onChange={handleAgeChange}
                        maxLength="3"
                    />
                </div>
            </div>

            <div className="input-container-middle">
                <div className="textbox-name-general-short">
                        <label htmlFor="personalityDescription" className="label-sign-up-h">{t('Scenario')}</label>
                    <textarea
                        id="personalityDescription"
                        value={scenario}
                        onChange={handleScenarioChange}
                        rows="4"
                        maxLength="3000"
                        cols="50"
                        placeholder={t('ScenarioPlaceholder')}
                    />
                </div>
            </div>

            <div className="quick-insert-container">
                {quickInsertOptions.map((option, index) => (
                    <button
                        key={index}
                        className="quick-insert-button"
                        onClick={() => handleQuickInsert(option.text)}
                    >
                        {t(option.label)}
                    </button>
                ))}
            </div>

            <div className="dropdown-container-bottom">
                <div className="custom-dropdown">
                    <Dropdown
                        options={advancedSettings}
                        value={selectedAdvancedSetting}
                        onChange={handleChangeAdvancedSetting}
                        placeholder={t("AdvancedSettings")}
                        className="model-dropdown-creator"
                        controlClassName="model-dropdown-control-creator"
                        menuClassName="model-dropdown-menu-creator"
                        arrowClassName="model-dropdown-arrow-creator"
                    />
                </div>
            </div>
        </div>
    );
};

export default SimpleStepTen;