import React from 'react';
import ReactDOM from 'react-dom';
import '../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next'; // Import the useTranslation hook

const CustomScenarioModal = ({ isOpen, onClose, children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize the translation function

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
                 style={{alignItems: 'center'}}
            >
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        💕 {t('want_to_try')} {/* Translated string */}
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {t('feature_available_only_to')}
                            <span className="colored-purple-text"> {t('premium_users')} </span>
                            {t('and_above')}
                            <br></br>
                            <br></br>
                            {t('they_get_plenty_of_other_features')}
                        </div>
                    </div>
                    <button onClick={() => {
                        navigate('/pricing');
                        onClose(true);
                    }} className="notification-button-main">{t('try_now')}</button>
                </div>
            </div>
        ),
        document.body
    );
};

export default CustomScenarioModal;
