import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../pages/SimpleCharacterCreation/icons/cross.svg";
import { ReactComponent as DiceIcon } from "../../../pages/SimpleCharacterCreation/icons/dice.svg";
import { useTranslation } from "react-i18next";
import '../SalesCharCreator/CharacterFunnel.css';
import { useFunnelCharacterContext } from "../SalesCharCreator/FunnelContext";

const femaleNames = [
    "Luna", "Aria", "Nova", "Sage", "Rain",
    "Violet", "River", "Sky", "Ember", "Willow",
    "Jade", "Eden", "Dawn", "Ivy", "Raven",
    "Aurora", "Storm", "Echo", "Phoenix", "Winter",
    "Crystal", "Brook", "Star", "Rose", "Haven"
];

const CharNamePopup = ({ isOpen, onClose, handleFinished }) => {
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();

    const handleNewNameChange = (event) => {
        updateCharacterData({ charName: event.target.value });
    };

    const getRandomName = () => {
        const randomIndex = Math.floor(Math.random() * femaleNames.length);
        const randomName = femaleNames[randomIndex];
        updateCharacterData({ charName: randomName });
    };

    const handleClosePopup = () => {
        onClose();
    };

    const internalOnSave = () => {
        if (!funnelCharData.charName || funnelCharData.charName.length < 3) {
            setError("Name must be at least 3 characters long");
            return;
        }
        handleFinished();
    };

    if (!isOpen) return null;

    return (
        <div className="popup-background" style={{zIndex: '9999'}} onClick={handleClosePopup}>
            <div className="name-change-popup" onClick={(e) => e.stopPropagation()}>
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">Name Your Character</div>
                    <div className="close-modal-icon" onClick={handleClosePopup}><CloseIcon/></div>
                </div>
                <div className="profile-setting-sublabel">
                    Choose a name for your partner or generate a random one
                </div>

                <div style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '15px'
                }}>
                    <input
                        type="text"
                        placeholder="Enter character name"
                        className="new-name-input"
                        value={funnelCharData.charName || ''}
                        onChange={handleNewNameChange}
                        style={{ flex: 1 }}
                    />
                    <button onClick={getRandomName} className="random-name-button">
                        <DiceIcon />
                    </button>
                </div>

                {error && <div className="profile-error-message">{error}</div>}

                <div className='a-straight-line-3' style={{ marginTop: '20px' }}></div>
                <button
                    className="save-new-name-button"
                    onClick={internalOnSave}
                    disabled={!funnelCharData.charName || funnelCharData.charName.length < 3}
                >
                    {t('Continue')}
                </button>
            </div>
        </div>
    );
};

export default CharNamePopup;