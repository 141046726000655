import React, {useEffect, useRef, useState} from "react";
import UserOutputChat from "../../components/ChatComponents/UserOutputChat";
import AIOutputChat from "../../components/ChatComponents/AIOutputChat";
import "./ChatPage.css";
import SendMessageTextbox from "../../components/ChatComponents/SendMessageTextbox";
import SelectMessageControlMenu from "../../components/ChatComponents/SelectMessageControlMenu";
import {getUserAccessToken, getUserID, supabase, userIDExists} from "../../App";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as Settings} from '../../icons/char-settings.svg';
import Tooltip from '@mui/material/Tooltip';
import {AuthModal} from "../../components/Forms/AuthModal";
import EmailVerification from "../../components/Forms/EmailVerification";
import {ReactComponent as MenuLeft} from "../../icons/MenuLeft.svg";
import {ReactComponent as MenuRight} from "../../icons/MenuRight.svg";
import ResponseSettings from "../../components/ChatComponents/ResponseSettings";
import ChatPageLoader from "../../components/LoadingEvents/ChatPageLoader";
import {ReactComponent as BottomArrowIcon} from "../../icons/arrow-bottom.svg";
import {useTimer} from "../../components/Context/TimerContext";
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {useChat} from "../../components/Context/ChatContext";
import {ReactComponent as FolderImages} from "../../icons/folderImages.svg";
import {LargeGalleryBanner} from "../../components/ChatComponents/LargeGalleryBanner"
import RecentChatList from "../../components/ChatComponents/RecentChatList/RecentChatList";
import {ReactComponent as OpenMainMenu} from "../../icons/message/open-the-menu.svg";
import {updateSettingsOnServer} from "../../components/ChatComponents/ChatPageHelpers";
import MobileCustomizationModal from "../../components/ChatComponents/CustomizationScreen/MobileCustomizationModal";
import {
    getUpdatedMemoriesAndPersonalities
} from "../../components/ChatComponents/CustomizationScreen/CustomizationFunctions";
import {useTranslation} from "react-i18next";
import ShareChatModal from "../../components/ChatComponents/ShareChatModal/ShareChatModal";
import {useMediaGeneration} from "./ChatPageFunctions";
import UpgradeNotification from "../../components/Notifications/Upsells/UpgradeNotification";
import TopTimerChatPage from "../../components/Notifications/TopBanners/TimerChatPage";
import {useAnalytics, usePageTracking} from "../../components/Analytics/UserAnalyticsContext";
import Cookies from 'js-cookie';
import {ReactComponent as MobileMenuIcon} from "../../icons/MobileMenuIcon.svg"
import MobileChatSlideMenu from "../../components/ChatComponents/MobileChatSlideMenu";
import SupportModal from "../../components/Modals/SupportModal";
import {useUserSettings} from "../../components/UserSettings/UserSettingContext";
import WelcomePopup from "../../components/Notifications/WelcomePopup";
import {ReactComponent as RewardMessage} from '../../components/RewardScreen/RewardIcons/rewardMessage.svg';
import UpdatedMessageLimitUpsell from "../../components/Notifications/UpdatedUpsells/UpdatedMessageLimit";
import ChatSettingDropdown from "./ChatSettingDropdown";
import {useChatSettings} from "./ChatSettingsContext";
import backgroundSVG from '../../icons/background-shapes.svg';
// import {useFeatureIsOn} from "@growthbook/growthbook-react";
export function ChatPage() {

    const {t} = useTranslation();
    const {visitorId, sessionId} = useAnalytics();

    usePageTracking('chat_page');

    const tds = {
        start() {
            this.decoder = new TextDecoder(this.encoding, this.options)
        }, transform(chunk, controller) {
            controller.enqueue(this.decoder.decode(chunk))
        }
    }
    let _jstds_wm = new WeakMap(); /* info holder */
    class TextDecoderStream extends TransformStream {
        constructor(encoding = 'utf-8', {...options} = {}) {
            let t = {...tds, encoding, options}

            super(t)
            _jstds_wm.set(this, t)
        }

        get encoding() {
            return _jstds_wm.get(this).decoder.encoding
        }
    }

    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMessageRunning, setIsMessageRunning] = useState(false);
    const [isRendering, setIsRendering] = useState(false);
    const location = useLocation();
    const [profileUrl, setProfileUrl] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [firstMessageAudio, setFirstMessageAudio] = useState(null);
    const [subscriptionErrorMessage, setSubscriptionErrorMessage] = useState(false);

    const {
        subscriptionError,
        setSubscriptionError,
    } = useMediaGeneration();

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
            if (!loggedIn) {
                navigate('/');
            }
        };
        checkUserLoggedIn();
        //eslint-disable-next-line
    }, []);

    const {
        isGalleryBannerVisible,
        setIsGalleryBannerVisible,
        scenario,
        description,
        profileURLBanner,
        isMenuOpen,
        toggleMenu,
        activeComponent,
        isActiveModal,
        selectedMessages,
        setSelectedMessages,
        selectMessageMode
    } = useChat();

    const {profileData} = useProfileData();

    const [clientUpdateData, setClientUpdateData] = useState(null);

    const [loginError, setLoginError] = useState(false);

    const [currentSelectedCharacterName, setCurrentSelectedCharacterName] = useState("");

    const [characterId, setCharacterId] = useState(null);

    const [currentCharacterType, setCurrentCharacterType] = useState(null);

    const [galleryPlaceholders, setGalleryPlaceholders] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState('');

    useEffect(() => {
        async function updateChat() {
            setIsLoading(true);

            setIsMessageRunning(true);
            setMessages([]);
            const identityName = window.location.pathname.substring(6);
            const exists = await userIDExists();

            if (!exists) {
                setLoginError(true);
                setMessages([]);

                return;
            }
            if (identityName !== "") {
                const id = await getUserID();
                setIsRendering(true);

                if (window.location.pathname.substring(6, 9) === "GC/") {
                    // TODO: Handle group chat rendering
                } else {

                    const {data: character, error} = await supabase
                        .from('characters')
                        .select('id')
                        .eq('user_id', id)
                        .eq('identity_name', identityName)
                        .single();

                    if (error) {
                        // console.log(error);
                        setIsRendering(false);
                        setIsMessageRunning(false);
                        return;
                    }

                    const {data: identity, error: identityError} = await supabase
                        .from('identities')
                        .select('profile, display_name, type')
                        .eq('identity_name', identityName)
                        .single();

                    setCurrentCharacterType(identity.type || 'Realistic');

                    identity.profile = undefined;

                    if (identityError) {
                        // console.log(identityError);
                        setIsRendering(false);
                        setIsMessageRunning(false);
                        return;
                    }

                    setProfileUrl(identity.profile);
                    setCurrentSelectedCharacterName(identity.display_name);

                    const render = await renderAPIMessages(character.id, 0, 50, id);
                    setCharacterId(character.id);
                    if (window.location.pathname.substring(6) !== identityName) {
                        return;
                    }

                    const renderedMessages = render.map((renderItem, i) => {
                        let isLatest = i === render.length - 1;
                        return renderItem.isUser ?
                            <UserOutputChat text={renderItem.content} imageUrl={renderItem.imageUrl}/> :
                            <AIOutputChat
                                currentCharacterType={identity.type || 'Realistic'}
                                totalMessages={messages.length}
                                text={renderItem.content}
                                messageId={renderItem.id}
                                audioUrl={renderItem.audioUrl}
                                imageUrl={renderItem.imageUrl !== null && renderItem.imageUrl !== undefined ? renderItem.imageUrl : ""}
                                regenMessage={addBotMessage}
                                isLatest={isLatest}
                                profileUrl={identity.profile}
                                senderUsername={profileData.username}
                                characterUsername={identity.display_name}
                                deleteMessage={deleteLastMessage}
                                isFirst={i === 0}
                                continueMessage={continueAPIMessage}
                                likeStatus={renderItem.likeStatus}
                                selectedMessages={selectedMessages}
                                setSelectedMessages={setSelectedMessages}
                                firstMessageAudio={firstMessageAudio}
                            />;
                    });
                    // Select three random imageUrl that is not undefined from render array
                    // Filter the render array to get only items with imageUrl not undefined
                    const itemsWithImage = render.filter(item => item.imageUrl !== undefined && !item.isUser && item.likeStatus !== 2 && item.likeStatus !== 3);
                    for (let i = itemsWithImage.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [itemsWithImage[i], itemsWithImage[j]] = [itemsWithImage[j], itemsWithImage[i]];
                    }
                    const placeholders = itemsWithImage.slice(0, 3).map(item => item.imageUrl);
                    if (placeholders.length > 0) {
                        setGalleryPlaceholders(placeholders);
                    } else {
                        fetch('https://api.elyza.app/v1/character-gallery', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': await getUserAccessToken()
                            },
                            body: JSON.stringify({
                                // userId: await getUserID(),
                                identityName: identityName
                            })
                        }).then(async response => {
                            if (response.ok) {
                                const data = await response.json();
                                const filtered = data.images.filter(image => image.image_url !== null && image.like_status !== 2 && image.like_status !== 3);
                                setGalleryPlaceholders(filtered.map(image => image.image_url));
                            }
                        });
                    }
                    setMessages(renderedMessages);
                    setIsMessageRunning(false);
                }
                setIsRendering(false);
            }
            setIsLoading(false);
        }

        updateChat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const renderMoreMessages = async () => {
        const identityName = window.location.pathname.substring(6);

        if (identityName !== "") {
            try {
                const id = await getUserID();
                const {data: character, error} = await supabase
                    .from('characters')
                    .select('id')
                    .eq('user_id', id)
                    .eq('identity_name', identityName)
                    .single();

                if (error) {
                    console.error(error);
                    return; // Early return on error
                }

                const newMessages = await renderAPIMessages(character.id, messages.length, 50, id);
                const renderedNewMessages = [];

                newMessages.reverse();

                for (let message of newMessages) {
                    const messageComponent = message.isUser ?
                        <UserOutputChat text={message.content} imageUrl={message.imageUrl}/> :
                        <AIOutputChat
                            currentCharacterType={currentCharacterType}
                            totalMessages={messages.length}
                            text={message.content}
                            messageId={message.id}
                            audioUrl={message.audioUrl}
                            imageUrl={message.imageUrl}
                            regenMessage={addBotMessage}
                            profileUrl={profileUrl}
                            deleteMessage={deleteLastMessage}
                            senderUsername={profileData.username}
                            characterUsername={currentSelectedCharacterName}
                            isFirst={renderedNewMessages.length === 0}
                            continueMessage={continueAPIMessage}
                            likeStatus={message.likeStatus}
                            setSelectedMessages={setSelectedMessages}
                            firstMessageAudio={firstMessageAudio}
                        />;

                    renderedNewMessages.unshift(messageComponent);
                }

                const updatedMessages = [...renderedNewMessages, ...messages];

                setMessages(updatedMessages);
            } catch (error) {
                console.error("Failed to load messages:", error);
            } finally {
                setIsMessageRunning(false);
            }
        }
    };

    const chatContainerRef = useRef(null);

    const addUserMessage = async (message, base64Image = "") => {
        if (message.startsWith('/me ')) {
            message = `*${message.substring(4)}*`;
        }

        setMessages(messages.concat(<UserOutputChat text={message}/>));

        setTimeout(scrollToBottom, 50)

        setTimeout(await addBotMessage(message, profileUrl, false, 0, base64Image), 1000);

        updateMessageCount();
    }

    const addBotMessage = async (userMessage, profileUrl, regenerate, oldId, base64Image = "") => {
        await new Promise((resolve) => setTimeout(resolve, 500)); // Introduce a delay of 2 seconds
        // setBackgroundImageUrl(imageUrl);

        const identityName = window.location.pathname.substring(6);

        getUserID().then(async (id) => {
            if (window.location.pathname.substring(6, 9) === "GC/") {
                const groupChatId = window.location.pathname.substring(9);

                await fetch('https://api.elyza.app/v1/add-user-message', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                    }, body: JSON.stringify({
                        groupChatId, senderId: id, content: userMessage
                    }),
                });
            } else {
                const {data: character, error} = await supabase
                    .from('characters')
                    .select('id')
                    .eq('user_id', id)
                    .eq('identity_name', identityName)
                    .single();
                if (error) {
                    // console.log(error);
                }

                const characterId = character.id;

                if (regenerate) {
                    let success = false;
                    if (window.location.pathname.substring(6) !== identityName) {
                        return;
                    }
                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];
                        const lastMessage = updatedMessages[updatedMessages.length - 1];
                        if (lastMessage.type === AIOutputChat) {
                            if (updatedMessages[updatedMessages.length - 1].props.messageId === oldId) {
                                updatedMessages[updatedMessages.length - 1] = {
                                    ...lastMessage, props: {
                                        ...lastMessage.props, text: "", messageId: undefined
                                    },
                                };
                                success = true;
                            }
                        }
                        return updatedMessages;
                    });
                    if (!success) {
                        return;
                    } else {
                        setIsMessageRunning(true);
                    }
                } else {
                    if (window.location.pathname.substring(6) !== identityName) {
                        return;
                    }

                    const newMessages = messages.concat(
                        <UserOutputChat
                            text={userMessage}
                            imageData={base64Image ? `${base64Image}` : ""}
                        />,
                        <AIOutputChat
                            currentCharacterType={currentCharacterType}
                            text={""}
                            imageUrl={""}
                            audioUrl={messages.audioUrl}
                            regenMessage={addBotMessage}
                            profileUrl={profileUrl}
                            deleteMessage={deleteLastMessage}
                            isFirst={false}
                            senderUsername={profileData.username}
                            characterUsername={currentSelectedCharacterName}
                            continueMessage={continueAPIMessage}
                            userMessage={userMessage}
                            selectedLanguage={selectedLanguage}
                            setSelectedMessages={setSelectedMessages}
                            firstMessageAudio={firstMessageAudio}

                        />
                    );
                    setMessages(newMessages);
                }

                setTimeout(scrollToBottom, 100)
                await sendAPIMessage(id, characterId, userMessage, regenerate, oldId, identityName, base64Image)
            }

        });
    }

    const continueAPIMessage = async (characterId, userMessage, botMessage, messageId, identityName, userId) => {
        try {
            const response = await fetch('https://api.elyza.app/v1/continue-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    userId,
                    characterId,
                    userMessage,
                    botMessage,
                    visitorId,
                    sessionId
                })
            });

            const responseData = await response.json();

            // Handle subscription error
            if (responseData.errorCode === 50) {
                setSubscriptionErrorMessage(true);
                return;
            }

            // Get the message from the correct property
            const messageText = " " + (responseData.continuedMessage || "");

            // Create artificial streaming effect
            const words = messageText.split(/(\s+|[.,!?'"])/); // Split by spaces and punctuation
            // let currentText = "";

            for (const word of words) {
                // Check if we navigated away
                if (window.location.pathname.substring(6) !== identityName) {
                    return;
                }

                // currentText += word;
                addValueToContinuedLastMessage(word, false, '', '');
                scrollToBottom();

                // Adjust timing based on punctuation
                const delay = /[.,!?]/.test(word) ? 200 : 30; // Longer pause at punctuation
                await new Promise(resolve => setTimeout(resolve, delay));
            }

            // Mark message as complete and save it
            addValueToContinuedLastMessage("", true, characterId, userId);
            setIsMessageRunning(false);

        } catch (error) {
            console.error('Message processing error:', error);
            setIsMessageRunning(false);
        }
    };

    const addValueToContinuedLastMessage = (value, complete, characterId, userId) => {
        let done = false;
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastMessage = updatedMessages[updatedMessages.length - 1];

            for (let i = 0; i < updatedMessages.length; i++) {
                if (updatedMessages[i].type !== AIOutputChat) {
                    continue;
                }
                if (updatedMessages[i].props.messageId === undefined) {
                    continue;
                }
                if (updatedMessages[i].props.text === undefined) {
                    continue;
                }
                updatedMessages[i] = {
                    ...updatedMessages[i], props: {
                        ...updatedMessages[i].props,
                        isLatest: false,
                    },
                };
                break;
            }

            if (lastMessage.type === AIOutputChat) {
                updatedMessages[updatedMessages.length - 1] = {
                    ...lastMessage, props: {
                        ...lastMessage.props, text: lastMessage.props.text + value,
                    },
                };
            }

            if (complete && !done && userId.length > 0) {
                done = true;
                const finalText = lastMessage.props.text;
                const messageId = saveContinuedAPIBotMessage(lastMessage.props.messageId, characterId, finalText, userId); // TODO: AWAIT?
                messageId.then((messageId) => {
                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];
                        //const lastMessage = updatedMessages[updatedMessages.length - 1];
                        for (let i = updatedMessages.length - 1; i >= 0; i--) {
                            if (updatedMessages[i].type !== AIOutputChat) {
                                continue;
                            }
                            if (updatedMessages[i].props.messageId !== undefined) {
                                continue;
                            }
                            if (updatedMessages[i].props.text !== finalText) {
                                continue;
                            }
                            updatedMessages[i] = {
                                ...updatedMessages[i], props: {
                                    ...updatedMessages[i].props, messageId: messageId,
                                    isLatest: true,
                                },
                            };
                            break;
                        }
                        return updatedMessages;
                    });
                });
            }

            return updatedMessages;
        });
    };

    const saveContinuedAPIBotMessage = async (messageId, characterId, message, userId) => {
        const response = await fetch('https://api.elyza.app/v1/save-continued-message', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId, characterId, messageId, message
            })
        })
        const json = await response.json();
        return json.messageId;
    }

    const [responseMode, setResponseMode] = useState('');
    const [llmModel, setLlmModel] = useState('Elyza Classic');

    const sendAPIMessage = async (userId, characterId, message, regenerate, oldId, identityName, base64Image) => {
        let response;
        const requestBody = {
            userId,
            characterId,
            message,
            regenerate,
            oldId: regenerate ? oldId : 0,
            userBase64Image: base64Image,
            visitorId,
            sessionId,
        };

        let endpoint = 'https://api.elyza.app/v1/new-send-message2';

        if (llmModel === 'Elyza Polyglot') {
            endpoint = 'https://api.elyza.app/v1/ml-send-message';
        }

        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/stream',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify(requestBody)
        });

        if (response.headers.get('Content-Type') === 'application/json') {
            const responseData = await response.json();
            if (responseData.errorCode === 50) {
                setIsMessageRunning(false);
                setSubscriptionErrorMessage(true);
                return;
            }
        }

        let isActuallyDone = false;

        const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();

        let buffer = '';
        while (true) {
            const {value, done} = await reader.read();
            if (window.location.pathname.substring(6) !== identityName) {
                return;
            }
            if (done && !isActuallyDone) {
                addValueToLastMessage("", true, characterId, userId, identityName);
                setIsMessageRunning(false);
                isActuallyDone = true;
                break;
            }

            buffer += value;
            const lines = buffer.split('\n');
            buffer = lines.pop(); // Keep the last incomplete line in the buffer

            for (const line of lines) {
                const trimmedLine = line.trim();
                if (trimmedLine === "[DONE]" || trimmedLine === "" || trimmedLine === "undefined") {
                    continue;
                }

                if (trimmedLine.startsWith('data: ')) {
                    const jsonString = trimmedLine.slice(6);
                    try {
                        const json = JSON.parse(jsonString);
                        if (json.choices && json.choices[0]) {
                            const content = json.choices[0].delta?.content || json.choices[0].text;
                            if (content) {
                                addValueToLastMessage(content, false, '', '');
                                setTimeout(scrollToBottom, 50);
                            }
                        }
                    } catch (e) {
                        console.error('Error parsing JSON:', e);
                    }
                }
            }
        }

        // Process any remaining data in the buffer
        if (buffer.trim()) {
            try {
                const json = JSON.parse(buffer.trim().slice(6));
                if (json.choices && json.choices[0]) {
                    const content = json.choices[0].delta?.content || json.choices[0].text;
                    if (content) {
                        addValueToLastMessage(content, false, '', '');
                        setTimeout(scrollToBottom, 50);
                    }
                }
            } catch (e) {
                console.error('Error parsing JSON from buffer:', e);
            }
        }
        if (!isActuallyDone) {
            addValueToLastMessage("", true, characterId, userId, identityName);
        }
        setIsMessageRunning(false);
    };

    const saveAPIBotMessage = async (characterId, message, userId, identityName = "") => {
        setClientUpdateData({
            identityName: identityName,
            newTimestamp: new Date().toISOString(),
            finalMessage: message
        });
        const response = await fetch('https://api.elyza.app/v1/save-message', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: userId, characterId: characterId, message: message
            })
        })
        const json = await response.json();
        return json.messageId;
    }

    const renderAPIMessages = async (characterId, start, count, userId) => {
        const response = await fetch('https://api.elyza.app/v1/render-messages', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: userId, characterId: characterId, start: start, count: count,
            })
        })

        const json = await response.json();

        return json.slice().reverse();
    }

    //eslint-disable-next-line
    const addValueToLastMessage = (value, complete, characterId, userId, identityName = "") => {
        let done = false;
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastMessage = updatedMessages[updatedMessages.length - 1];

            for (let i = 0; i < updatedMessages.length; i++) {
                if (updatedMessages[i].type !== AIOutputChat) {
                    continue;
                }
                if (updatedMessages[i].props.messageId === undefined) {
                    continue;
                }
                if (updatedMessages[i].props.text === undefined) {
                    continue;
                }
                updatedMessages[i] = {
                    ...updatedMessages[i], props: {
                        ...updatedMessages[i].props,
                        isLatest: false,
                    },
                };
                break;
            }

            if (lastMessage.type === AIOutputChat) {
                updatedMessages[updatedMessages.length - 1] = {
                    ...lastMessage, props: {
                        ...lastMessage.props, text: lastMessage.props.text + value,
                    },
                };
            }

            if (complete && !done && userId.length > 0) {
                done = true;

                const messageId = saveAPIBotMessage(characterId, lastMessage.props.text, userId, identityName); // TODO: AWAIT?
                messageId.then((messageId) => {
                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];
                        for (let i = updatedMessages.length - 1; i >= 0; i--) {
                            if (updatedMessages[i].type !== AIOutputChat) {
                                continue;
                            }
                            if (updatedMessages[i].props.messageId !== undefined) {
                                continue;
                            }
                            if (updatedMessages[i].props.text !== lastMessage.props.text) {
                                continue;
                            }
                            updatedMessages[i] = {
                                ...updatedMessages[i], props: {
                                    ...updatedMessages[i].props, messageId: messageId,
                                    isLatest: true,
                                },
                            };
                            break;
                        }
                        return updatedMessages;
                    });
                });
            }
            return updatedMessages;
        });
    };

    const deleteLastMessage = async (messageId) => {
        const identityName = window.location.pathname.substring(6);
        if (identityName === "") return false;

        try {
            const id = await getUserID();
            const {data: character, error} = await supabase
                .from('characters')
                .select('id')
                .eq('user_id', id)
                .eq('identity_name', identityName)
                .single();

            if (error) throw error;

            const token = await getUserAccessToken();

            return new Promise((resolve) => {
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    const lastIndex = updatedMessages.length - 1;
                    const lastMessage = updatedMessages[lastIndex];
                    const userMessage = updatedMessages[lastIndex - 1];

                    if (lastMessage?.type === AIOutputChat && lastMessage.props.messageId === messageId) {
                        if (userMessage?.type === UserOutputChat) {
                            updatedMessages.splice(lastIndex - 1, 2);

                            fetch('https://api.elyza.app/v1/delete-message', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'text/stream',
                                    'Authorization': token
                                },
                                body: JSON.stringify({
                                    userId: id,
                                    characterId: character.id,
                                    oldId: messageId,
                                    visitorId, sessionId
                                })
                            });

                            if (updatedMessages.length > 0) {
                                const newLastIndex = updatedMessages.length - 1;
                                updatedMessages[newLastIndex] = {
                                    ...updatedMessages[newLastIndex],
                                    props: {
                                        ...updatedMessages[newLastIndex].props,
                                        isLatest: true,
                                    }
                                };
                            }

                            resolve(true);
                            return updatedMessages;
                        }
                    }

                    resolve(false);
                    return prevMessages;
                });
            });
        } catch (error) {
            console.error("Error in deleteLastMessage:", error);
            return false;
        }
    };

    const [localChats, setLocalChats] = useState([]);

    const [currentCharName, setCurrentCharName] = useState("");

    const handleCloseModal = () => {
        setLoginError(false);
    };

    const [isOwnerBoolean, setIsOwnerBoolean] = useState(false);
    const [showChatMainMenu, setShowChatMainMenu] = React.useState(false);
    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight, behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        setTimeout(scrollToBottom, 1000);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            scrollToBottom();
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();

    const [chatSettingDropdownOpen, setChatSettingDropdownOpen] = useState(false);

    const handleChatSettingDropdown = () => {
        setChatSettingDropdownOpen(!chatSettingDropdownOpen)
    }

    const [isAtBottom, setIsAtBottom] = useState(true);

    const handleScroll = (renderMoreMessages) => {
        const {scrollHeight, scrollTop, clientHeight} = chatContainerRef.current;

        const threshold = 5;
        const isUserAtBottom = scrollHeight - scrollTop - clientHeight < threshold;

        setIsAtBottom(isUserAtBottom);

        if (scrollTop === 0) {
            renderMoreMessages();
        }
    };

    const [maxTokens, setMaxTokens] = useState(512);
    const [temperature, setTemperature] = useState(0.88);
    const [isSimple, setIsSimple] = useState(true)
    const [isPublic, setIsPublic] = useState(true)
    const [dynamicScenarioEnabled, setDynamicScenarioEnabled] = useState(false);

    const loadingRef = useRef(null);

    useEffect(() => {
        if (isRendering && loadingRef.current) {
            loadingRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
    }, [isRendering]);

    const {timerExpired} = useTimer();

    const [deleteCurrentChat, setDeleteCurrentChat] = useState(false);

    const handleGalleryToggle = () => {
        setIsGalleryBannerVisible(!isGalleryBannerVisible);
    };

    const [isGalleryMounted, setIsGalleryMounted] = useState(isGalleryBannerVisible);

    useEffect(() => {
        if (isGalleryBannerVisible === true) {
            setIsGalleryMounted(true);

        }
    }, [isGalleryBannerVisible]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1080) {
                setIsGalleryBannerVisible(false);
            } else {
                setIsGalleryBannerVisible(true);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        //eslint-disable-next-line
    }, []);

    let updatedScenario = scenario.replace(/{{user}}/g, profileData.username);
    let updatedDescription = description.replace(/{{user}}/g, profileData.username);

    const handleClick = () => {
        const currentPath = window.location.pathname;
        const basePath = currentPath.split('/')[1];
        const id = currentPath.split('/')[2];

        const newPath = `/${basePath.replace('chat', 'gallery')}/${id}`;
        navigate(newPath);
    };

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerHeight > 1080);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerHeight > 1080);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array to run the effect only on mount and unmount


    const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] = useState(false);

    useEffect(() => {
        if (isLoggedIn && !profileData.is_verified) {
            setIsEmailVerificationModalOpen(true);
        }
    }, [isLoggedIn, profileData.is_verified]);

    const getContainerStyle = () => {
        if (isGalleryBannerVisible && isMenuOpen) {
            return {minWidth: 'calc(100vw - 720px)'};
        } else if (isGalleryBannerVisible && !isMenuOpen) {
            return {minWidth: 'calc(100vw - 360px)'};
        } else if (!isGalleryBannerVisible && isMenuOpen) {
            return {minWidth: 'calc(100vw - 360px)'};
        } else {
            return {minWidth: '100vw'};
        }
    };

    const getStyles = () => {
        if (!isWide) {
            return {
                width: '100vw',
                marginLeft: '0',
            };
        } else {
            return {
                width: isMenuOpen ? 'calc(100vw - 360px)' : '100vw',
                marginLeft: isMenuOpen ? '360px' : '0',
            };
        }
    };

    const [shouldRenderHeader, setShouldRenderHeader] = useState(true);

    const shouldRender = () => {
        const isNarrowScreen = window.innerWidth <= 680;
        const isExactChatPath = location.pathname.startsWith('/chat/') && location.pathname.length > '/chat/'.length;
        return !(isNarrowScreen && isExactChatPath && activeComponent !== 'RecentChatList');
    };

    useEffect(() => {
        const renderState = shouldRender();
        setShouldRenderHeader(renderState);
        //eslint-disable-next-line
    }, [location.pathname, window.innerWidth, activeComponent]);

    const isFreeUser = profileData.subscription_data.tier === "FREE";
    const hasReachedMessageLimit = isFreeUser && profileData.message_count >= 30;
    const [modelSwitchExternalCache, setModelSwitchExternalCache] = useState('Elyza Classic');
    const identityName = window.location.pathname.substring(6);
    const {wasRecentChatUpdated, setWasRecentChatUpdated} = useChat();

    useEffect(() => {
        if (wasRecentChatUpdated) {
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('model_')) {
                    localStorage.removeItem(key);
                }
            });
            setWasRecentChatUpdated(false);
        }

        const storedModel = localStorage.getItem(`model_${identityName}`);
        if (storedModel) {
            setModelSwitchExternalCache(storedModel);
            setLlmModel(storedModel);
        } else {
            setModelSwitchExternalCache(llmModel);
        }
        //eslint-disable-next-line
    }, [wasRecentChatUpdated, identityName, setLlmModel, setModelSwitchExternalCache, setWasRecentChatUpdated]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (llmModel !== 'Elyza Free' && hasReachedMessageLimit) {
                setSubscriptionErrorMessage(true);
            } else {
                setSubscriptionErrorMessage(false);
            }
        }, 3000); // 3000 milliseconds = 3 seconds

        return () => clearTimeout(timer);
    }, [llmModel, hasReachedMessageLimit]);

    const handleSwitchToFree = async () => {

        const newModel = 'Elyza Free';
        setLlmModel(newModel);
        setModelSwitchExternalCache(newModel);
        setSubscriptionErrorMessage(false);

        localStorage.setItem(`model_${identityName}`, newModel);

        try {
            await updateSettingsOnServer(newModel);
        } catch (error) {
            console.error("Failed to update model on server:", error);
        }
    };

    const [memoriesAndPersonalities, setMemoriesAndPersonalities] = useState(null);

    useEffect(() => {
        if (!isWide) {
            getUpdatedMemoriesAndPersonalities(null, identityName)
                .then(result => setMemoriesAndPersonalities(result))
                .catch(error => console.error("Failed to fetch memories and personalities:", error));
        }
        //eslint-disable-next-line
    }, [window.location.pathname]);

    const [isChatMobileMenuOpen, setIsChatMobileMenuOpen] = useState(false);

    const toggleMobileChatMenu = () => {
        setIsChatMobileMenuOpen(!isChatMobileMenuOpen);
    }

    const {
        isSupportModalOpen,
        toggleSupportModal,
    } = useUserSettings();

    const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(false);

    useEffect(() => {
        if (profileData.subscription_data.tier !== "FREE") {
            // If Premium, set the cookie without showing popup
            Cookies.set('hasSeenWelcome', 'true', {expires: 365});
            return;
        }
        // Check if user has seen the welcome popup before
        const hasSeenWelcome = Cookies.get('hasSeenWelcome');

        if (!hasSeenWelcome) {
            // If they haven't seen it, show popup and set cookie
            setIsWelcomePopupOpen(true);
            // Set cookie that expires in 365 days
            Cookies.set('hasSeenWelcome', 'true', {expires: 365});
        }
        //eslint-disable-next-line
    }, []);

    const handleCloseWelcomePopup = () => {
        setIsWelcomePopupOpen(false);
        Cookies.set('hasSeenWelcome', 'true', {expires: 365});
    };

    const MAX_TOTAL_MESSAGES = 9999;

    const [messageCount, setMessageCount] = useState(() => {
        const serverCount = parseInt(profileData.message_count || '0', 10);
        const localCount = parseInt(localStorage.getItem('localMessageCount') || '0', 10);
        return Math.max(serverCount, localCount);
    });

    // const messageLimitFeature = useFeatureIsOn("message_limit_test");

    const BASE_MESSAGE_LIMIT = 30;

    const [remainingMessages, setRemainingMessages] = useState(() => {
        const bonusMessages = parseInt(profileData.bonus_messages || '0', 10);
        const totalAvailable = Math.min(BASE_MESSAGE_LIMIT + bonusMessages, MAX_TOTAL_MESSAGES);
        return Math.max(0, totalAvailable - messageCount);
    });

    useEffect(() => {
        localStorage.setItem('localMessageCount', messageCount.toString());
    }, [messageCount]);

// Sync with server count when profileData changes
    useEffect(() => {
        const serverCount = parseInt(profileData.message_count || '0', 10);
        setMessageCount(prevCount => Math.max(prevCount, serverCount));
    }, [profileData.message_count]);

    const updateMessageCount = () => {
        setMessageCount(prev => {
            const newCount = prev + 1;

            // Recalculate remaining messages
            const bonusMessages = parseInt(profileData.bonus_messages || '0', 10);
            const totalAvailable = Math.min(BASE_MESSAGE_LIMIT + bonusMessages, MAX_TOTAL_MESSAGES);
            const remaining = Math.max(0, totalAvailable - newCount);

            setRemainingMessages(remaining);
            return newCount;
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (llmModel !== 'Elyza Free' && remainingMessages === 0) {
                setSubscriptionErrorMessage(true);
            } else {
                setSubscriptionErrorMessage(false);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, [llmModel, remainingMessages]);

    const {
        isCharacterBackground,
        isMobileCustomizationOpen,
        isResponseModalOpen,
        isShareModalOpen,
        toggleResponseModal,
        toggleShareModal,
        toggleMobileCustomization
    } = useChatSettings();

    const containerStyles = {
        marginLeft: isMenuOpen === true ? '360px' : '0px',
        backgroundImage: isCharacterBackground
            ? `url(${profileURLBanner || backgroundSVG})`
            : `url(${backgroundSVG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center 10%',
        backgroundOrigin: 'padding-box',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'normal',
        width: isGalleryBannerVisible ? isMenuOpen ? 'calc(100vw - 720px)' : 'calc(100vw - 360px)' : isMenuOpen ? "calc(100vw - 360px)" : "100vw"
    };

    const mobileContainerStyle = {
        backgroundImage: isCharacterBackground
            ? `url(${profileURLBanner || backgroundSVG})`
            : `url(${backgroundSVG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center 10%',
        backgroundOrigin: 'padding-box',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'normal',
    };

    return (
        <>

            <ChatSettingDropdown
                isOpen={chatSettingDropdownOpen}
                onClose={handleChatSettingDropdown}
                setSubscriptionError={setSubscriptionError}
            />

            {isFreeUser &&
                <WelcomePopup
                    isOpen={isWelcomePopupOpen}
                    onClose={handleCloseWelcomePopup}
                />
            }

            <MobileChatSlideMenu
                isOpen={isChatMobileMenuOpen}
                onClose={() => setIsChatMobileMenuOpen(false)}
                isLoggedIn={isLoggedIn}
                setShowChatMainMenu={setShowChatMainMenu}
                handleShareOpen={toggleShareModal}
                handleCustomizationModal={toggleMobileCustomization}
                handleGallerySwitch={handleClick}
                // isAuthModalOpen={isAuthModalOpen}
                // setIsAuthModalOpen={setIsAuthModalOpen}
            />

            {!isWide &&
                <MobileCustomizationModal
                    isOpen={isMobileCustomizationOpen}
                    onCancel={toggleMobileCustomization}
                    memoriesAndPersonalities={memoriesAndPersonalities}
                    setMemoriesAndPersonalities={setMemoriesAndPersonalities}
                />
            }

            {!isWide &&
                <SupportModal
                    isOpen={isSupportModalOpen}
                    onClose={toggleSupportModal}
                />
            }

            {/*//REACHED MESSAGES LIMIT FOR FREE USERS ONLY*/}
            {isFreeUser &&
                <>
                    {subscriptionErrorMessage && <UpdatedMessageLimitUpsell
                        isOpen={subscriptionErrorMessage}
                        onClose={() => setSubscriptionErrorMessage(false)}
                        onSwitchFree={handleSwitchToFree}
                        model={llmModel}
                        modelSwitchExternalCache={modelSwitchExternalCache}
                        setLoginError={setLoginError}
                    />}
                </>
            }

            {/*//HIT MESSAGE LIMIT FROM THE BACKEND SIDE*/}
            {/*{profileData.subscription_data.tier === "FREE" &&*/}
            {/*    <>*/}
            {/*        {subscriptionErrorMessage ?*/}
            {/*            <MessageLimitUpsell isOpen={subscriptionErrorMessage} onClose={(confirm) => {*/}
            {/*                setSubscriptionErrorMessage(false);*/}
            {/*                if (confirm) {*/}
            {/*                    navigate('/pricing');*/}
            {/*                }*/}
            {/*            }}/> : null}*/}
            {/*    </>*/}
            {/*}*/}

            <div className="ChatPage">
                {shouldRenderHeader && (
                    <HeaderMainMenu/>
                )}
                {isEmailVerificationModalOpen && (
                    <EmailVerification isOpen={isEmailVerificationModalOpen}
                                       onClose={() => setIsEmailVerificationModalOpen(false)}/>
                )}
                {isLoading ? (
                    <div className="loading-spin-chat"
                         style={getStyles()}
                    >
                        <ChatPageLoader/>
                    </div>) : null}


                {!timerExpired ? (
                    <div className="top-timer-container-main-chat"
                         style={{display: timerExpired !== false ? "none" : 'block'}}
                    >
                        <TopTimerChatPage/>
                    </div>
                ) : null}

                {!showChatMainMenu && (
                    <div
                        className="recent-chat-list-h-container-visible"
                    >
                        <RecentChatList
                            setFirstMessageAudio={setFirstMessageAudio}
                            isLoading={isRendering || isMessageRunning}
                            isRendering={isLoading}
                            setLocalChats={setLocalChats}
                            localChats={localChats}
                            setProfileUsername={() => {
                            }}
                            profileUsername={profileData.username}
                            setCurrentCharName={setCurrentCharName}
                            chatContainerRef={chatContainerRef}
                            setShowChatMainMenu={setShowChatMainMenu}
                            isMobile={!isWide}
                            llmModel={llmModel}
                            setLlmModel={setLlmModel}
                            maxTokens={maxTokens}
                            setMaxTokens={setMaxTokens}
                            temperature={temperature}
                            setTemperature={setTemperature}
                            setResponseMode={setResponseMode}

                            dynamicScenarioEnabled={dynamicScenarioEnabled}
                            setDynamicScenarioEnabled={setDynamicScenarioEnabled}

                            clientUpdateData={clientUpdateData}
                            setIsOwnerBoolean={setIsOwnerBoolean}
                            deleteCurrentChat={deleteCurrentChat}
                            setDeleteCurrentChat={setDeleteCurrentChat}
                            setSelectedLanguage={setSelectedLanguage}
                            setIsSimple={setIsSimple}
                            setIsPublic={setIsPublic}
                        />
                    </div>
                )}

                {showChatMainMenu && (
                    <>
                        {!isWide && (
                            <div ref={chatContainerRef} className='chat-container-visible'
                                 style={mobileContainerStyle}
                                 onScroll={() => handleScroll(renderMoreMessages)}
                            >
                                <div className="chat-container-top-container">
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                        <button className="chat-back-button"
                                                onClick={() => {

                                                    toggleMobileChatMenu()

                                                    // navigate('/chat')
                                                    // setShowChatMainMenu(false)
                                                }}>
                                            <MobileMenuIcon/>
                                        </button>
                                        <div className="char-name-container">
                                            {currentCharName}
                                        </div>
                                    </div>
                                    <div className="icons-container">

                                        {/*<div className="settings-icon" onClick={handleShareOpen}>*/}
                                        {/*    <Tooltip title={t('share')} placement="bottom-start" arrow>*/}
                                        {/*        <ShareIcon/>*/}
                                        {/*    </Tooltip>*/}
                                        {/*</div>*/}

                                        {/*{!isWide &&*/}
                                        {/*    <Tooltip title={t('Customizations')} placement="left" arrow>*/}
                                        {/*        <div className="settings-icon-gallery"*/}
                                        {/*             onClick={handleCustomizationModal}>*/}
                                        {/*            <CustomizationIcon/>*/}
                                        {/*        </div>*/}
                                        {/*    </Tooltip>*/}
                                        {/*}*/}

                                        {isFreeUser && messageCount >= 30 &&
                                            <div className="bonus-messages-counter"
                                                 style={{width: '80px', color: '#ccc', cursor: 'pointer'}}
                                                 onClick={() => navigate('/pricing')}
                                            >
                                                {remainingMessages}
                                                <RewardMessage/>
                                            </div>
                                        }

                                        <div className="settings-icon" onClick={handleChatSettingDropdown}>
                                            <Tooltip title={t('characterSettings')} placement="left" arrow>
                                                <Settings/>
                                            </Tooltip>
                                        </div>


                                        {/*{window.innerWidth < 1080 &&*/}
                                        {/*    <Tooltip title={t('CharacterGallery')} placement="left" arrow>*/}
                                        {/*        <div className="settings-icon-gallery"*/}
                                        {/*             onClick={handleClick}>*/}
                                        {/*            <FolderImages/>*/}
                                        {/*        </div>*/}
                                        {/*    </Tooltip>*/}
                                        {/*}*/}

                                        {window.innerWidth > 1080 &&
                                            <div className="settings-icon"
                                                 onClick={handleGalleryToggle}>
                                                {isGalleryBannerVisible === true ? <MenuRight/> : <MenuLeft/>}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="message-margin-top"
                                     style={{paddingTop: timerExpired !== false ? '85px' : '135px'}}
                                >
                                    {messages !== undefined ? messages.map((message, index) => React.cloneElement(message, {key: index})) : null}
                                </div>
                                {!isAtBottom && (
                                    <div className='scroll-bottom-button' onClick={scrollToBottom}
                                    >
                                        <BottomArrowIcon/>
                                    </div>
                                )}

                                {subscriptionError ?
                                    <UpgradeNotification isOpen={subscriptionError} onClose={() => {
                                        setSubscriptionError(false);
                                    }}/> : null}


                                {location.pathname !== '/chat' &&
                                    <div className="messages-scroll-container">
                                        <div className="chatbox-spacer"
                                        >
                                            <SendMessageTextbox
                                                addUserMessage={addUserMessage}
                                                addBotMessage={addBotMessage}
                                                isMessageRunning={isMessageRunning}
                                                setIsMessageRunning={setIsMessageRunning}
                                                onSelect={() => {
                                                }}
                                                messages={messages}
                                            />
                                        </div>
                                    </div>
                                }
                                {selectMessageMode === true &&
                                    <SelectMessageControlMenu/>
                                }
                            </div>
                        )}
                    </>
                )}
                {isWide && (
                    <div className="recent-chat-list-h-container"
                         style={{
                             zIndex: isActiveModal ? '9998' : (isMenuOpen ? '998' : '1')
                         }}
                    >
                        <RecentChatList
                            setFirstMessageAudio={setFirstMessageAudio}
                            isLoading={isRendering || isMessageRunning}
                            setLocalChats={setLocalChats}
                            localChats={localChats}
                            setProfileUsername={() => {
                            }}
                            profileUsername={profileData.username}
                            setCurrentCharName={setCurrentCharName}
                            chatContainerRef={chatContainerRef}
                            setShowChatMainMenu={() => {
                            }}
                            isMobile={!isWide}
                            setLlmModel={setLlmModel}
                            setMaxTokens={setMaxTokens}
                            setTemperature={setTemperature}
                            setResponseMode={setResponseMode}

                            setDynamicScenarioEnabled={setDynamicScenarioEnabled}

                            // setNsfwEnabled={setNsfwEnabled}
                            clientUpdateData={clientUpdateData}
                            setIsOwnerBoolean={setIsOwnerBoolean}
                            deleteCurrentChat={deleteCurrentChat}
                            setDeleteCurrentChat={setDeleteCurrentChat}
                            setSelectedLanguage={setSelectedLanguage}
                            setIsSimple={setIsSimple}
                            setIsPublic={setIsPublic}
                        />
                    </div>)}
                {isWide && (
                    <div
                        ref={chatContainerRef}
                        onScroll={() => handleScroll(renderMoreMessages)}
                        className={`chat-container ${timerExpired !== false ? 'banner-chat-container' : 'chat-container'}`}
                        style={containerStyles}
                    >
                        <div className="galleries-hide-them">
                            <LargeGalleryBanner
                                isGalleryMounted={isGalleryMounted}
                                isLargeScreen={isLargeScreen}
                                isGalleryBannerVisible={isGalleryBannerVisible}
                                profileURLBanner={profileURLBanner}
                                currentCharName={currentCharName}
                                galleryPlaceholders={galleryPlaceholders}
                                handleClick={handleClick}
                                updatedScenario={updatedScenario}
                                updatedDescription={updatedDescription}
                            />
                        </div>
                        {!isAtBottom && (
                            <div className='scroll-bottom-button' onClick={scrollToBottom}
                                 style={{
                                     marginLeft: isGalleryBannerVisible ? '0' : (isMenuOpen === true ? '180px' : '0')
                                 }}
                            >
                                <BottomArrowIcon/>
                            </div>
                        )}
                        <div className="chat-container-top-container"
                             style={getContainerStyle()}
                        >
                            <div className="char-name-container">
                                {!isMenuOpen && isWide && (
                                    <div className="settings-icon-open">
                                        <Tooltip title="Open Menu" placement="right" arrow>
                                            <OpenMainMenu onClick={toggleMenu}/>
                                        </Tooltip>
                                    </div>
                                )}
                                {currentCharName}
                            </div>
                            <div className="icons-container">
                                {/*{profileData.subscription_data.tier !== 'FREE' && profileData.split_tests.includes("new-image-generation-1") && (*/}
                                {/*    <button className="new-feature-block" onClick={handleOpenImageModal}>*/}
                                {/*        Click Me!*/}
                                {/*        <HeartIcon/>*/}
                                {/*    </button>*/}
                                {/*)}*/}

                                {/*//TODO: please look and dont forget about me*/}

                                {isFreeUser && messageCount >= 30 &&
                                    <div className="bonus-messages-counter"
                                         style={{width: '80px', color: '#ccc', cursor: 'pointer'}}
                                         onClick={() => navigate('/pricing')}
                                    >
                                        {remainingMessages}
                                        <RewardMessage/>
                                    </div>
                                }

                                {/*<div className="settings-icon" onClick={toggleShareModal}>*/}
                                {/*    <Tooltip title={t('share')} placement="bottom-start" arrow>*/}
                                {/*        <ShareIcon/>*/}
                                {/*    </Tooltip>*/}
                                {/*</div>*/}

                                <div className="settings-icon" onClick={handleChatSettingDropdown}>
                                    <Tooltip title={t('characterSettings')} placement="bottom-start" arrow>
                                        <Settings/>
                                    </Tooltip>
                                </div>

                                {window.innerWidth < 1080 &&
                                    <Tooltip title={t('CharacterGallery')} placement="bottom-start" arrow>
                                        <div className="settings-icon-gallery"
                                             onClick={handleClick}>
                                            <FolderImages/>
                                        </div>
                                    </Tooltip>
                                }
                                {window.innerWidth > 1080 &&
                                    <div className="settings-icon"
                                         onClick={handleGalleryToggle}>
                                        {isGalleryBannerVisible === true ? <MenuRight/> : <MenuLeft/>}
                                    </div>
                                }
                            </div>
                        </div>

                        {/*//TODO WTF*/}
                        {!isRendering ? (
                            <div className="message-margin-top"
                                 style={{
                                     // width: isGalleryBannerVisible ? 'calc(100% - 360px)' : '100%',
                                     paddingBottom: timerExpired !== false ? '0px' : '85px',
                                     paddingTop: timerExpired !== false ? '85px' : '85px'
                                 }}
                            >
                                {messages !== undefined ? messages.map((message, index) => React.cloneElement(message, {key: index})) : null}
                            </div>
                        ) : null}

                                {subscriptionError ?
                                    <UpgradeNotification isOpen={subscriptionError} onClose={() => {
                                        setSubscriptionError(false);
                                    }}/> : null}

                        {location.pathname !== '/chat' && selectMessageMode !== true &&
                            <div className="messages-scroll-container">
                                <div className="chatbox-spacer"
                                     style={{
                                         paddingRight: isGalleryBannerVisible ? '380px' : '20px',
                                         marginLeft: isMenuOpen === true ? '360px' : '0px',
                                         width: isMenuOpen === true ? 'calc(100vw - 360px)' : '100vw'
                                     }}
                                >
                                    <SendMessageTextbox
                                        isRendering={isLoading}
                                        addUserMessage={addUserMessage}
                                        addBotMessage={addBotMessage}
                                        isMessageRunning={isMessageRunning}
                                        setIsMessageRunning={setIsMessageRunning}
                                        onSelect={() => {
                                        }}
                                        messages={messages}
                                    />
                                </div>
                            </div>
                        }
                        {selectMessageMode === true &&
                            <SelectMessageControlMenu/>
                        }
                    </div>
                )}
                {loginError ? (<div className="ErrorScreenOverlay">
                    <AuthModal onClose={handleCloseModal}/>
                </div>) : null}
                <ResponseSettings
                    isVisible={isResponseModalOpen}
                    onCancel={toggleResponseModal}
                    llmModel={llmModel}
                    setLlmModel={setLlmModel}
                    maxTokens={maxTokens}
                    setMaxTokens={setMaxTokens}
                    temperature={temperature}
                    setTemperature={setTemperature}
                    responseMode={responseMode}
                    setResponseMode={setResponseMode}
                    dynamicScenarioEnabled={dynamicScenarioEnabled}
                    setDynamicScenarioEnabled={setDynamicScenarioEnabled}
                    isOwnerBoolean={isOwnerBoolean}
                    characterId={characterId}
                    setDeleteCurrentChat={setDeleteCurrentChat}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    isSimple={isSimple}
                    isPublic={isPublic}
                    setLoginError={setLoginError}
                />
            </div>
            <ShareChatModal
                isOpen={isShareModalOpen}
                onClose={toggleShareModal}
            />
        </>
    );
}