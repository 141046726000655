import React, {useState, useRef, useEffect} from "react";
import {ReactComponent as EditTextIcon} from "../../icons/editTextIcon.svg";
import {useCharacterCreation} from "../../CharacterCreationContext";
import {useAdvancedCharacterCreation} from "../../../AdvancedCharacterCreation/AdvancedCharacterContext";

const CustomVoiceHeader = ({initialVoiceName = "Custom Voice", onSave}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [voiceName, setVoiceName] = useState(initialVoiceName);
    const textRef = useRef(null);
    const inputRef = useRef(null);
    const { updateCharacterData } = useCharacterCreation();
    const {updateAdvancedCharacterData} = useAdvancedCharacterCreation();

    useEffect(() => {
        if (isEditing && inputRef.current) {
            const width = textRef.current.offsetWidth;
            inputRef.current.style.width = `${width}px`;
        }
    }, [isEditing]);

    const handleClick = () => {
        if (!isEditing) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (onSave) {
            onSave(voiceName);
        }
    };

    const handleChange = (e) => {
        setVoiceName(e.target.value);

        if (window.location.href === 'https://elyza.app/advanced-creator') {
            updateAdvancedCharacterData({ clonedVoiceName: e.target.value });
        } else if (window.location.href === 'https://elyza.app/create-character') {
            updateCharacterData({ clonedVoiceName: e.target.value });
        }

        // Adjust input width based on content
        const value = e.target.value || initialVoiceName;
        textRef.current.textContent = value;
        e.target.style.width = `${textRef.current.offsetWidth}px`;
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    };

    return (
        <div className="custom-voice-header">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    cursor: 'pointer',
                    position: 'relative'
                }}
                onClick={handleClick}
            >
                {/* Hidden text element for measuring width */}
                <span
                    ref={textRef}
                    style={{
                        visibility: 'hidden',
                        position: 'absolute',
                        whiteSpace: 'pre',
                        fontSize: '1em',
                        fontWeight: '700'
                    }}
                >
                    {voiceName}
                </span>

                {isEditing ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={voiceName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        style={{
                            background: 'transparent',
                            border: 'none',
                            color: '#c4c4c4',
                            fontSize: '1em',
                            fontWeight: '700',
                            outline: 'none',
                            padding: '0',
                            minWidth: '20px'
                        }}
                    />
                ) : (
                    <span style={{
                        color: '#c4c4c4',
                        fontSize: '1em',
                        fontWeight: '700',
                    }}>
                        {voiceName}
                    </span>
                )}

                <EditTextIcon
                    style={{
                        minWidth: '18px',
                        maxWidth: '18px',
                        minHeight: '18px',
                        maxHeight: '18px',
                        flexShrink: 0
                    }}
                />
            </div>
        </div>
    );
};

export default CustomVoiceHeader;