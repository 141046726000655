import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../../../icons/cross.svg";
import {useTranslation} from "react-i18next";

const PublishDenied = ({isOpen, onClose, characterName, message}) => {
    const {t} = useTranslation();

    let messageSub = '';

    if (message.includes('IMAGE')) {
        messageSub = t('IMAGE')
    } else if (message.includes('QUALITY')) {
        messageSub = t('QUALITY')
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-label'>
                        {t('PublishDenied0')}
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {t('PublishDenied1')}
                            {characterName}
                            {t('PublishDenied2')}
                            <br></br>
                            <br></br>
                            {t('PublishDenied3')}
                            {messageSub}
                            <br></br>
                            <br></br>
                            {t('PublishDenied4')}
                        </div>
                    </div>

                    <button
                        className="notification-button-main"
                        onClick={onClose}
                    >
                        {t('okay')}
                    </button>
                </div>
            </div>
        ),
        document.body
    );
};

export default PublishDenied;