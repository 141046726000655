import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import { useTranslation } from 'react-i18next';
import {useFeatureIsOn} from "@growthbook/growthbook-react";

const WelcomePopup = ({isOpen, onClose}) => {
    const { t } = useTranslation();

    const messageLimit = useFeatureIsOn("message_limit_test");

    if (!isOpen) return null;

    const messageCount = messageLimit ? '5' : '30';


    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    {t('welcome')}

                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <span className='sublabel-notification'>
                        {t('thankYouForChoosing')}
                    </span>

                    <div className='sublabel-notification'>
                        {t('youHave')}

                        <span className="colored-purple-text">
                                     {t('thirtyFreePremium').replace('30', messageCount)}
                        </span>
                        {t('messages')}
                        <br></br>
                        <br></br>
                        {t('afterThat')} {' '}
                        <span className="colored-purple-text">{t('freeModelForever')}</span>
                    </div>
                </div>

                <button onClick={onClose} className="notification-button-main">
                    {t('continue')}
                </button>
            </div>
        </div>
    );
};

export default WelcomePopup;