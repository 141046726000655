import React, { createContext, useContext, useState} from 'react';

const ChatDropdownContext = createContext();

export const useChatSettings = () => useContext(ChatDropdownContext);

export const ChatDropdownProvider = ({ children }) => {

    const BACKGROUND_STORAGE_KEY = 'chat-character-background';
    const GENERATE_AUDIO_STORAGE_KEY = 'auto-audio-gen-background';

    const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
    const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [isCharacterBackground, setIsCharacterBackground] = useState(() => {
        const savedValue = localStorage.getItem(BACKGROUND_STORAGE_KEY);
        return savedValue ? JSON.parse(savedValue) : false;
    });

    const [isAutoGenerateAudio, setIsAutoGenerateAudio] = useState(() => {
        const savedValue = localStorage.getItem(GENERATE_AUDIO_STORAGE_KEY);
        return savedValue ? JSON.parse(savedValue) : false;
    });

    const toggleAutoGenerateAudio = () => {
        setIsAutoGenerateAudio(prev => {
            const newValue = !prev;
            localStorage.setItem(GENERATE_AUDIO_STORAGE_KEY, JSON.stringify(newValue));
            return newValue;
        });
    }

    const toggleCharacterBackground = () => {
        setIsCharacterBackground(prev => {
            const newValue = !prev;
            localStorage.setItem(BACKGROUND_STORAGE_KEY, JSON.stringify(newValue));
            return newValue;
        });
    };

    const [isMobileCustomizationOpen, setIsMobileCustomizationOpen] = useState(false)

    const toggleResponseModal = () => {
        setIsResponseModalOpen(!isResponseModalOpen);
    };

    const toggleCustomizeModal = () => {
        setIsCustomizeModalOpen(!isCustomizeModalOpen);
    };

    const toggleShareModal = () => {
        setIsShareModalOpen(!isShareModalOpen);
    };

    const toggleMobileCustomization = () => {
        setIsMobileCustomizationOpen(!isMobileCustomizationOpen);
    }

    return (
        <ChatDropdownContext.Provider value={{
            isResponseModalOpen,
            isCustomizeModalOpen,
            isShareModalOpen,
            isCharacterBackground,
            isMobileCustomizationOpen,
            isAutoGenerateAudio,
            toggleResponseModal,
            toggleCustomizeModal,
            toggleShareModal,
            toggleCharacterBackground,
            toggleMobileCustomization,
            toggleAutoGenerateAudio
        }}>
            {children}
        </ChatDropdownContext.Provider>
    );
};