import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as SquareIcon} from "../../icons/squareIcon.svg";
import {ReactComponent as DeleteIcon} from "../../icons/deleteIcon.svg";
import {ReactComponent as MicIcon} from "../../icons/MicIcon.svg"
import CustomAudioPlayer from "./CustomAudioPlayer";
import {useCharacterCreation} from "../../CharacterCreationContext";
import {useAdvancedCharacterCreation} from "../../../AdvancedCharacterCreation/AdvancedCharacterContext";

const VoiceRecordingSection = ({currentVoiceOptionActive, setCurrentVoiceOptionActive}) => {
    const MAX_DURATION = 10; // Maximum duration in seconds
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [finalDuration, setFinalDuration] = useState(0);
    const { updateCharacterData } = useCharacterCreation();
    const {updateAdvancedCharacterData} = useAdvancedCharacterCreation();

    const startTimeRef = useRef(null);
    const durationCheckInterval = useRef(null);

    useEffect(() => {
        return () => {
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
            if (durationCheckInterval.current) {
                clearInterval(durationCheckInterval.current);
            }
        };
    }, [audioStream]);

    const startRecording = async () => {
        setRecordedAudio(null);

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            setAudioStream(stream);

            const recorder = new MediaRecorder(stream);
            const chunks = [];

            startTimeRef.current = Date.now();

            // Start duration check interval
            durationCheckInterval.current = setInterval(() => {
                const currentDuration = (Date.now() - startTimeRef.current) / 1000;

                if (currentDuration >= MAX_DURATION) {
                    stopRecording();
                }
            }, 100);

            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                }
            };

            recorder.onstop = async () => {
                clearInterval(durationCheckInterval.current);
                const duration = (Date.now() - startTimeRef.current) / 1000;
                const finalDuration = Math.min(duration, MAX_DURATION);
                setFinalDuration(finalDuration);

                const blob = new Blob(chunks, {type: 'audio/wav'});
                // Create URL for preview playback
                const url = URL.createObjectURL(blob);
                setRecordedAudio(url);

                // Convert blob to base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Audio = reader.result.split(',')[1]; // Remove the data URL prefix
                    // updateCharacterData({ clonedVoiceURL: base64Audio });

                    if (window.location.href === 'https://elyza.app/advanced-creator') {
                        updateAdvancedCharacterData({ clonedVoiceURL: base64Audio });
                    } else if (window.location.href === 'https://elyza.app/create-character') {
                        updateCharacterData({ clonedVoiceURL: base64Audio });
                    }

                };
                reader.readAsDataURL(blob);
            };

            recorder.start(250);
            setMediaRecorder(recorder);
            setIsRecording(true);
        } catch (err) {
            console.error("Error accessing microphone:", err);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
            setIsRecording(false);

            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
            if (durationCheckInterval.current) {
                clearInterval(durationCheckInterval.current);
            }
            setCurrentVoiceOptionActive('record');
        }
    };

    useEffect(() => {
        return () => {
            if (recordedAudio) {
                URL.revokeObjectURL(recordedAudio);
            }
        };
    }, [recordedAudio]);

    if (currentVoiceOptionActive === 'library' || currentVoiceOptionActive === 'upload') {
        return null;
    }

    return (
        <div className="voice-recording-container">
            <button
                onClick={isRecording ? stopRecording : startRecording}
                className={`recording-button ${isRecording ? 'recording' : ''}`}
            >
                {isRecording ? (
                    <>
                        <SquareIcon/>
                        <span>Stop Recording</span>
                    </>
                ) : (
                    <>
                        <MicIcon/>
                        <span>Start Recording</span>
                    </>
                )}
            </button>

            {recordedAudio && (
                <div className="audio-preview-container"
                    style={{ marginBottom: '0'}}
                >

                    <CustomAudioPlayer
                        src={recordedAudio}
                        duration={finalDuration}
                    />

                    <div
                        onClick={() => {
                            setRecordedAudio(null);
                            setCurrentVoiceOptionActive('');
                        }}
                        className="delete-voice-clone-audio-button"
                    >
                        <DeleteIcon
                            style={{minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px'}}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VoiceRecordingSection;