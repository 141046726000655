import React from 'react';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const UpgradeSaleModal = ({ isOpen, onClose, children, percent }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        onClose();
        navigate('/pricing');
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    💎 {t('premiumButLess')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        {t('premiumSale')}
                        <span className="colored-purple-text"> {percent}% {t('off')} </span>
                        {t('usualPrice')}
                    </div>

                    <div className='sublabel-notification'>
                        {t('unlimitedMessagesLong')}
                    </div>

                    <div className='sublabel-notification'>
                        {t('dontLetTimeRunOut')}
                    </div>
                </div>

                <button onClick={() => {
                    handleUpgradeClick()
                }} className="notification-button-main">
                    {t('upgrade')}
                </button>

            </div>
        </div>
    );
};

export default UpgradeSaleModal;
