import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../../FunnelContext";
import { ReactComponent as VoiceIcon } from "../../../../../pages/SimpleCharacterCreation/icons/voiceIcon.svg";

const personalities = [
    {
        name: 'Sweet',
        description: 'Friendly and outgoing.',
        emoji: '🍬'
    },
    {
        name: 'Mean',
        description: 'Assertive and confident.',
        emoji: '😠'
    },
    {
        name: 'Innocent',
        description: 'Pure and kind-hearted.',
        emoji: '😇'
    },
    {
        name: 'Submissive',
        description: 'Obedient and compliant.',
        emoji: '🙇‍'
    },
    {
        name: 'Dominant',
        description: 'Bold and assertive.',
        emoji: '👑'
    },
    {
        name: 'Shy',
        description: 'Reserved and timid.',
        emoji: '🫣'
    }
];

const baseUrl = "https://supabase.elyza.app/storage/v1/object/public/audio-files/character-creator";

const voicesTransEnglish = [
    { id: "e3827ec5-697a-4b7c-9704-1a23041bbc51", label: "Voice 1", name: "Sweet Lady", url: `${baseUrl}/english/sweet_lady.wav` },
    { id: "21b81c14-f85b-436d-aff5-43f2e788ecf8", label: "Voice 6", name: "Laidback Woman", url: `${baseUrl}/english/laidback_woman.wav` },
    { id: "15a9cd88-84b0-4a8b-95f2-5d583b54c72e", label: "Voice 7", name: "Reading Lady", url: `${baseUrl}/english/reading_lady.wav` },
    { id: "5345cf08-6f37-424d-a5d9-8ae1101b9377", label: "Voice 8", name: "Maria", url: `${baseUrl}/english/maria.wav` },
    { id: "00a77add-48d5-4ef6-8157-71e5437b282d", label: "Voice 10", name: "Calm Lady", url: `${baseUrl}/english/calm_lady.wav` },
    { id: "156fb8d2-335b-4950-9cb3-a2d33befec77", label: "Voice 11", name: "Helpful Woman", url: `${baseUrl}/english/helpful_woman.wav` },
    { id: "1001d611-b1a8-46bd-a5ca-551b23505334", label: "Voice 14", name: "Anime Girl", url: `${baseUrl}/english/anime_girl.wav` },
    { id: "248be419-c632-4f23-adf1-5324ed7dbf1d", label: "Voice 15", name: "Professional Woman", url: `${baseUrl}/english/professional_woman.wav` }
];

const TransStep4 = () => {
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedPersonality, setSelectedPersonality] = useState(funnelCharData.personality || '');
    const [selectedVoice, setVoiceSelection] = useState(funnelCharData.voiceSelection || '');
    const { t } = useTranslation();
    const audioRef = useRef(new Audio());

    const handlePersonalitySelect = (personality) => {
        setSelectedPersonality(personality);
        updateCharacterData({ personality: personality });
    };

    const handleVoiceSelection = (voice) => {
        setVoiceSelection(voice.id);
        updateCharacterData({ voiceSelection: voice.id });
        playAudio(voice.url);
    };

    const playAudio = (url) => {
        audioRef.current.pause();
        audioRef.current.src = url;
        audioRef.current.play().catch(error => console.error("Audio playback failed:", error));
    };

    return (
        <div className="personality-selection-container" style={{marginTop: '0'}}>
            <div className="funnel-creation-label" style={{marginBottom: '20px'}}>
                {t('tSelectPersonality')}
            </div>

            <div className="personality-grid">
                {personalities.map((personality) => (
                    <div
                        key={personality.name}
                        className={`personality-box ${selectedPersonality === personality.name ? 'selected' : ''}`}
                        onClick={() => handlePersonalitySelect(personality.name)}
                    >
                        <h3 className="personality-name-creator">
                            {t(personality.name)} {personality.emoji}
                        </h3>
                        <p className="personality-description-creator">
                            {t(personality.description)}
                        </p>
                    </div>
                ))}
            </div>

            <div className="funnel-creation-label" style={{margin: '20px 0'}}>
                {t('tSelectVoice')}
            </div>

            <div className="voice-grid" style={{display: 'grid', gap: '1rem'}}>
                {voicesTransEnglish.map((voice, index) => (
                    <div key={`${voice.id}-${index}`} className="general-option-wrapper">
                        <div
                            className={`general-option-voice-items ${selectedVoice === voice.id ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleVoiceSelection(voice)}
                        >
                            <div className="voice-example-container">
                                {t(`${voice.label}`)}
                                <VoiceIcon/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransStep4;