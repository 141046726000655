import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';

const FunnelCharacterContext = createContext();

export const useFunnelCharacterContext = () => useContext(FunnelCharacterContext);

export const FunnelCharacterProvider = ({ children }) => {
    const defaultData = {
        charName: '',
        style: 'Anime',
        gender: '',
        ethnicity: 'White',
        hairColor: 'Blonde',
        hairStyle: 'Curly',
        breastSize: 'Medium',
        dickSize: 'Medium',
        buttSize: '',
        personality: 'Sweet',
        scenario: 'tBestFriends',
        voiceSelection: 'e3827ec5-697a-4b7c-9704-1a23041bbc51',
        languageSelection: 'EN',
        specialCase: 'FunnelCharacter'
    };

    const [funnelCharData, setFunnelCharData] = useState(() => {
        try {
            const savedData = localStorage.getItem('funnelCharacterData');
            if (savedData) {
                const parsedData = JSON.parse(savedData);
                if (parsedData && typeof parsedData === 'object') {
                    return {
                        ...defaultData,
                        ...parsedData
                    };
                }
            }
        } catch (error) {
            console.error('Error loading funnel data from localStorage:', error);
        }
        return defaultData;
    });

    const [isAnime, setIsAnime] = useState(funnelCharData.style === 'Anime');

    // Update isAnime when style changes
    useEffect(() => {
        setIsAnime(funnelCharData.style === 'Anime');
    }, [funnelCharData.style]);

    const clearOptions = useCallback(() => {
        setFunnelCharData(defaultData);
        localStorage.removeItem('funnelCharacterData');
        //eslint-disable-next-line
    }, []);

    const updateCharacterData = useCallback((newData) => {
        setFunnelCharData(prevData => {
            const updatedData = {
                ...prevData,
                ...newData
            };
            localStorage.setItem('funnelCharacterData', JSON.stringify(updatedData));
            return updatedData;
        });
    }, []);

    // console.log('funnel char data context', funnelCharData);

    return (
        <FunnelCharacterContext.Provider value={{
            funnelCharData,
            updateCharacterData,
            clearOptions,
            isAnime,
        }}>
            {children}
        </FunnelCharacterContext.Provider>
    );
};

export default FunnelCharacterProvider;