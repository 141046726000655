import React, {useCallback, useEffect, useRef, useState} from "react";
import "./AIOutputChat.css";
import "../../pages/ChatPage/ChatPage.css";
import {MessageButtonSelection} from "./MessageButtonSelection";
import {getUserAccessToken, getUserID, supabase, userIDExists} from "../../App";
import {ReactComponent as PlayButton} from "./ChatIcons/playicon.svg";
import {ReactComponent as PauseButton} from "./ChatIcons/pause.svg";
import {BeatLoader, BounceLoader} from 'react-spinners';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {ReactComponent as ThumbsUpIcon} from "../../icons/thumbs-up.svg";
import {ReactComponent as ThumbsDownIcon} from "../../icons/thumbs-down.svg";
import {ReactComponent as DiamondIcon} from "../../icons/diamond.svg";
import ReportDislikeButton from "./ReportDislikeButton";
// import {ChatFuncContext} from "../Context/ChatFunctionsContext - Deprecated";
import {useProfileData} from "../Context/ProfileDataContext";
import {useWavesurfer} from "@wavesurfer/react";
import {useChat} from "../Context/ChatContext";
import {useTimer} from "../Context/TimerContext";
import {useTranslation} from "react-i18next";
import {Checkbox} from "@mui/material";
import {useMediaGeneration} from "../../pages/ChatPage/ChatPageFunctions";
import UpgradeNotification from "../Notifications/Upsells/UpgradeNotification";
import {useChatSettings} from "../../pages/ChatPage/ChatSettingsContext";

//TODO: Ensure dynamic loading focusing on the correct type of the image expected.
const AIOutputChat = ({
                          text,
                          messageId,
                          regenMessage,
                          isLatest,
                          isFirst,
                          deleteMessage,
                          profileUrl,
                          senderUsername,
                          characterUsername,
                          optionalUsername,
                          optionalTimestamp,
                          optionalCharacterId,
                          isActuallyUser,
                          senderId,
                          continueMessage,
                          areButtonsVisible,
                          setAreButtonsVisible,
                          likeStatus,
                          currentCharacterType,
                          userMessage, totalMessages,
                          imageUrl,
                          audioUrl,
                          firstMessageAudio,

                      }) => {

    let selectableMessages = (totalMessages - 1) / 2;
    const {profileData} = useProfileData();
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    const {
        imageLoading,
        audioLoading,
        generateImage,
        generateAudio,
        subscriptionError,
        setSubscriptionError,
    } = useMediaGeneration();

    const [generatedImageUrl, setGeneratedImageUrl] = useState(null);
    const [generatedAudioUrl, setGeneratedAudioUrl] = useState(null)
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    const handleGenerateImage = async () => {
        try {
            const result = await generateImage(messageId);
            if (result.success) {
                setGeneratedImageUrl(result.imageUrl);
                setLikeStatus(0);
            } else {
                if (result.blurredImage) {
                    setGeneratedImageUrl(result.blurredImage);
                    setLikeStatus(3);
                } else {
                    console.error("Failed to generate image:", result.error);
                }
            }
        } catch (error) {
            console.error("Error generating image:", error);
        }
    };

    const handleGenerateAudio = async () => {
        try {
            const result = await generateAudio(messageId);
            if (result.success) {
                setGeneratedAudioUrl(result.audioUrl);
            } else {
                console.error("Failed to generate audio:", result.error);
            }
        } catch (error) {
            console.error("Error generating audio:", error);
        }
    };

    const currentImageUrl = generatedImageUrl || imageUrl;

    const currentAudioUrl = generatedAudioUrl || audioUrl;

    const hasImage = () => {
        return !isActuallyUser && currentImageUrl != null && currentImageUrl.length > 0;
    }

    const hasAudio = () => {
        return !isActuallyUser && currentAudioUrl != null && currentAudioUrl.length > 0;
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 680);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const shouldSetAudio = firstMessageAudio && isFirst;
        if (shouldSetAudio) {
            setGeneratedAudioUrl(firstMessageAudio);
        }
    }, [firstMessageAudio, isFirst]);

    const {
        selectedMessages,
        setSelectedMessages,
        isGalleryBannerVisible,
        selectMessageMode,
        selectAll,
        setSelectAll
    } = useChat();

    const loadingRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);

    const imageRequestKeys = [
        'sendMe',
        'canISee',
        'envisionMe',
        'showMe',
        'createImage',
        'drawThis',
        'makeAPicture',
        'visualize'
    ];

    const audioRequestKeys = [
        'voiceMessage',
        'wantToHearYou'
    ];

    useEffect(() => {
        if (messageId === undefined || messageId === null) {
            return;
        }

        if (userMessage !== undefined && userMessage !== null && userMessage.length > 0) {
            const lowercaseText = userMessage.toLowerCase();

            // Check for any image or audio request
            const isImageRequest = imageRequestKeys.some(key =>
                lowercaseText.includes(t(key))
            );

            const isAudioRequest = audioRequestKeys.some(key =>
                lowercaseText.includes(t(key))
            );

            // Handle explicit image/audio requests
            if (isImageRequest) {
                if (lowercaseText.includes("audio") || lowercaseText.includes("voice")) {
                    handleGenerateAudio().then(r => {
                    });
                } else {
                    handleGenerateImage().then(r => {
                    });
                }
            } else if (isAudioRequest) {
                handleGenerateAudio().then(r => {
                });
            }

            // Handle random generation for free tier users with no tokens
            if (Math.floor(Math.random() * 25) === 1 &&
                isLatest &&
                profileData.subscription_data.tier === 'FREE' &&
                profileData.tokens <= 0) {

                // Random chance of 1 in 2 for image vs audio
                if (Math.floor(Math.random() * 2) === 1) {
                    handleGenerateImage().then(r => {
                    });
                } else {
                    handleGenerateAudio().then(r => {
                    });
                }
            }
        }
        //eslint-disable-next-line
    }, [text, messageId]);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const [isContinuing, setIsContinuing] = useState(false);

    const handleContinueMessage = async () => {
        const canContinue = messageId !== null && messageId !== undefined && (await userIDExists());

        if (canContinue) {
            setIsContinuing(true);
            const identityName = window.location.pathname.substring(6);
            const userId = await getUserID();
            const {data: character} = await supabase
                .from('characters')
                .select('id')
                .eq('user_id', userId)
                .eq('identity_name', identityName)
                .single();

            await continueMessage(character.id, userMessage, text, messageId, identityName, userId)
            setIsContinuing(false);
        }
    }

    const WaveContainerRef = useRef(null);

    const {wavesurfer, isPlaying} = useWavesurfer({
        container: WaveContainerRef,
        height: 30, // Set the height to 40px
        waveColor: 'rgb(255,255,255)',
        progressColor: 'rgb(164,137,236)',
        url: currentAudioUrl,
        barGap: 2,
        barWidth: 4,
        barRadius: 2,
        normalize: true,
        fillParent: true,
        autoCenter: true,
    });

    const onPlayPause = useCallback(() => {
        wavesurfer && wavesurfer.playPause();
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer && currentAudioUrl) {
            wavesurfer.load(currentAudioUrl);
        }
    }, [wavesurfer, currentAudioUrl]);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!imageLoading) return;

        setProgress(0);
        const totalDuration = 25000;

        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }

                return prevProgress + 1;
            });
        }, totalDuration / 100);

        return () => clearInterval(interval);
    }, [imageLoading]);

    const regenerateMessage = async () => {
        if (optionalTimestamp !== undefined && optionalCharacterId !== undefined) {
            await regenMessage(optionalCharacterId);
        } else {
            await regenMessage("", profileUrl ?? "", true, messageId);
        }
    }

    const deleteMessageWrapper = async () => {
        if (optionalTimestamp !== undefined && optionalCharacterId !== undefined) {
            if (isLatest) {
                return await deleteMessage(optionalCharacterId);
            }
        } else {
            return await deleteMessage(messageId);
        }
    }

    const getStoredLanguage = () => {
        const identityName = window.location.pathname.substring(6);
        return localStorage.getItem(`language_${identityName}`);
    };

    const storedLanguage = getStoredLanguage();

    //TODO test new regex and move the const up to the top
    //literally i have no idea how to implement this shit as of now
    // const [tryFeaturePopupOpen, setIsTryFeaturePopupOpen] = useState(false);

    function formatMessage(message, characterUsername, username) {

        if (optionalCharacterId !== undefined && optionalCharacterId.length > 0) return message;

        // Trim the message and replace multiple consecutive newline characters with a single newline character
        message = message.trim().replace(/\n+/g, '\n');

        message = message.replace(/�/g, ' ');

        if (storedLanguage === 'ru') {
            message = message.replace(/\*\. "/g, '* "');
        }

        // const regex = /I cannot|I can't|I can not|I'm sorry/;
        //
        // if (regex.test(message)) {
        //     setIsTryFeaturePopupOpen(true)
        // }

        // Replace {{char}} and {{user}} placeholders
        message = message.replace(/{{char}}/gi, characterUsername)
            .replace(/{{user}}/g, username)
            // .replace(/user/g, username)
            .replace(/{char}/g, characterUsername)
            .replace(/{user}/g, username)
            .replace(/{Char}/g, characterUsername)
            .replace(/{User}/g, username);

        message = message.replace(/[{}]/g, '');

        message = message.replace(/\*\*([^*]+)\*\*\.(?!\s*\n)/g, '**$1**');

        message = message.replace(/["`”“]/g, '');

        message = message.replace(/(\s*)(\*\*?)([^*\n]+)\2/g, (match, space, stars, content) => {
            let formattedText = '\n\n<b><i>' + content.trim() + '</i></b>';

            if (storedLanguage !== 'ja' && storedLanguage !== 'zh' && storedLanguage !== 'ko' && storedLanguage !== 'ru') {
                if (!/[.!?]$/.test(content)) {
                    formattedText += '.';
                }
            }

            return formattedText + '\n\n';
        });

        message = message.replace(/(?<!\.)"/g, '."\n');

        message = message.replace(/(?<!\.)(?=\s*")/g, '.\n');

        message = message.replace(/\n{3,}/g, '\n\n');

        return message.trim();
    }

    const containerClass = !isMobile && isGalleryBannerVisible === true ? "bot-chat-container wide" : "bot-chat-container";

    const [isFullscreen, setIsFullscreen] = useState(false);

    const openImageInFullScreen = (imageElement) => {
        if ((likeStatus === 3 || profileData.subscription_data.tier === 'FREE') && profileData.tokens <= 0) {
            return;
        }
        if (likeStatus !== 3) { // Add this condition
            if (!document.fullscreenElement) {
                if (imageElement.requestFullscreen) {
                    imageElement.requestFullscreen().then(() => {
                        setIsFullscreen(true);
                    }).catch(err => {
                        console.error(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
                    });
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen().then(() => {
                        setIsFullscreen(false);
                    }).catch(err => {
                        console.error(`Error attempting to disable fullscreen mode: ${err.message} (${err.name})`);
                    });
                }
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullscreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
    }, []);

    const [reportFormMessage, setReportFormMessage] = useState('');

    const handleShortDescriptionChange = (e) => {
        setReportFormMessage(e.target.value);
    }

    const reportNegativeImageQuality = async (imageUrl) => {
        setLikeStatus(2);
        setReportModalOpen(false);

        const resp = await fetch('https://api.elyza.app/v1/report-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                imageUrl: imageUrl, isPositive: false, issueReport: reportFormMessage,
            })
        });

        if (resp.status === 200) {

        }
    }

    const reportPositiveImageQuality = async (imageUrl) => {

        setLikeStatus(1);

        const resp = await fetch('https://api.elyza.app/v1/report-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                imageUrl: imageUrl, isPositive: true, issueReport: '',
            })
        });

        if (resp.status === 200) {

        }
    }

    const [isReportModalOpen, setReportModalOpen] = useState(false);

    const openReportModal = () => {
        setReportModalOpen(true);
    };

    const [cacheLikeStatus, setLikeStatus] = useState(likeStatus);

    const handleLikeClick = (e) => {
        if (!hasImage() || cacheLikeStatus !== 0) {
            return;
        }

        // TODO: FORM WITH REPORT MESSAGE
        e.stopPropagation();

        setLikeStatus(1);

        reportPositiveImageQuality(imageUrl).then(() => {
        });
    };

    const handleDislikeClick = (e) => {
        if (!hasImage() || cacheLikeStatus !== 0) {
            return;
        }

        e.stopPropagation();

        openReportModal();
    };

    useEffect(() => {
        if (loading) {
            loadingRef.current?.scrollIntoView({behavior: "smooth"});
        }
    }, [loadingRef, loading]);

    const handleRemoveBlurClick = () => {
        setSubscriptionError(true); // Assuming this triggers your pop-up
    };

    const {timerExpired} = useTimer();

    const {
        isCharacterBackground,
        isAutoGenerateAudio
    } = useChatSettings();

    useEffect(() => {
        if (isAutoGenerateAudio && !isFirst && currentAudioUrl == null && isLatest) {
            handleGenerateAudio();
        }
        //eslint-disable-next-line
    }, [isAutoGenerateAudio, currentAudioUrl, isLatest]);

    useEffect(() => {
        if (!isFirst) {
            if (selectAll) {
                setSelectedMessages(prevMessages => {
                    if (!prevMessages.includes(messageId)) {
                        return [...prevMessages, messageId];
                    }
                    return prevMessages;
                });
            } else {
                setSelectedMessages(prevMessages => {
                    if (prevMessages.length === selectableMessages) {
                        return [];
                    }
                    return prevMessages;
                });
            }
        }
        //eslint-disable-next-line
    }, [selectAll, messageId, isFirst, selectableMessages]);

    const handleRadioChange = (messageId, event) => {
        const isChecked = event.target.checked;

        setSelectedMessages(prevSelected => {
            let newSelected;
            if (isChecked) {
                newSelected = [...prevSelected, messageId];

                if (newSelected.length === selectedMessages) {
                    setSelectAll(true);
                }
            } else {
                newSelected = prevSelected.filter(id => id !== messageId);

                setSelectAll(false);
            }
            return newSelected;
        });
    };

    return (
        <div className="chat-message-bot-h" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

            {/*<div className="universal-help-popup"></div>*/}

            <div className={containerClass}
                 style={{marginBottom: timerExpired !== false ? "20px" : '30px'}}
            >
                {selectMessageMode && (
                    <Checkbox
                        checked={isFirst || selectedMessages.includes(messageId)}
                        onChange={(event) => handleRadioChange(messageId, event)}
                        disabled={isFirst}
                        sx={{
                            color: '#5324d0',
                            width: '10px',
                            height: '10px',
                            '&.Mui-checked': {
                                color: '#6f46d1',
                                backgroundColor: '#fff'
                            },
                            '&.Mui-disabled': {
                                color: '#5324d0',
                            },
                            // Style for the first message (disabled and checked)
                            '&.Mui-disabled.Mui-checked': {
                                color: '#5324d0',
                                '& .MuiSvgIcon-root': { // This targets the icon inside the checkbox
                                    color: '#383838',

                                }
                            }
                        }
                        }
                    />
                )}

                <div className='ai-chat-message-main'
                     style={{backgroundColor: isCharacterBackground ? 'rgba(159,128,243,0.75)' : '#6f46d1'}}
                >

                    {text.length === 0 && (
                        <div
                            className="message-loading-spin"
                        >
                            <BeatLoader color="white"/>
                        </div>
                    )}

                    <div className="bot-text">
                        {formatMessage(text, characterUsername, senderUsername).split('\n').map((line, index, array) => {
                            const isLastLine = index === array.length - 1;
                            return (
                                <React.Fragment key={index}>
                                    <span dangerouslySetInnerHTML={{__html: line}}/>
                                    {!isLastLine && <br style={{lineHeight: '0.5', margin: '0.25em 0'}}/>}
                                </React.Fragment>
                            );
                        })}
                        {audioLoading ? (
                            <div className="message-loading-spin" style={{marginTop: '10px'}}>
                                <BeatLoader color="white"/>
                            </div>
                        ) : (
                            currentAudioUrl != null && currentAudioUrl.length > 0 && (
                                <div className='surfer-h-container'>
                                    <button onClick={onPlayPause} className='surfer-control-timer'>
                                        {isPlaying ? <PauseButton/> : <PlayButton/>}
                                    </button>
                                    <div ref={WaveContainerRef} className='central-surfer-component'>
                                        <div className='main-surfer-line'/>
                                    </div>
                                </div>
                            )
                        )}
                        {
                            (!loading && messageId !== undefined && !isActuallyUser && !isContinuing &&
                                (isMobile || isHovered || 'messageIndex' < 5)) && (
                                <div
                                    className={`selection-menu-general ${isMobile || isHovered || 'messageIndex' < 5 ? 'fadeIn' : ''}`}>
                                    <MessageButtonSelection
                                        className="selection-menu-general"
                                        deleteEnabled={isLatest && !isFirst}
                                        regenerateEnabled={isLatest && !isFirst}
                                        imageEnabled={!isFirst && !currentImageUrl} //TODO: might be fucked up
                                        audioEnabled={!isFirst && !currentAudioUrl} //TODO: might be fucked up
                                        continueEnabled={isLatest && !isFirst}
                                        messageId={messageId}
                                        generateImage={handleGenerateImage}
                                        loading={loading}
                                        setLoading={setLoading}
                                        hasImage={hasImage}
                                        regenerateMessage={regenerateMessage}
                                        isLatest={isLatest}
                                        deleteMessage={deleteMessageWrapper}
                                        handleContinueMessage={handleContinueMessage}
                                        handleGenerateAudio={handleGenerateAudio}
                                        hasAudio={hasAudio}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {subscriptionError && <UpgradeNotification isOpen={subscriptionError} onClose={() => {
                        setSubscriptionError(false);
                    }}/>}

                </div>

                {imageLoading && (currentImageUrl == null || currentImageUrl.length === 0) ? (
                    <div ref={loadingRef} className="loading-image-container">
                        <svg width="100" height="100" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none" stroke="#242424"/>
                            <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none" stroke="#916DE8"
                                    strokeDasharray={Math.PI * 2 * 45}
                                    strokeDashoffset={Math.PI * 2 * 45 * ((100 - progress) / 100)}
                                    style={{transition: 'stroke-dashoffset 0.3s linear'}}
                            />
                            <text x="50" y="55" textAnchor="middle" fill="#916DE8"
                                  className="loading-text">{`${progress}%`}</text>
                        </svg>

                        <div className="loading-image-text">
                            Please Wait!
                        </div>

                        <div className="loading-image-text-2">
                            Your image is being generated.
                        </div>


                    </div>) : null}

                {!isActuallyUser && currentImageUrl != null && currentImageUrl.length > 0 && (
                    <div
                        className="ai-chat-message-image"
                        style={{position: 'relative', pointerEvents: "visible"}}
                        onClick={(e) => {
                            if (e.target.classList.contains('chat-image') || e.target.classList.contains('ai-chat-message-image')) {
                                const imageElement = e.currentTarget.querySelector('.chat-image');
                                openImageInFullScreen(imageElement);
                            }
                        }}
                    >
                        {loading && (
                            <div className="loading-button">
                            </div>
                        )}


                        {!imageLoaded &&
                            <>
                                {profileData.subscription_data.tier !== 'FREE' ? (

                                    <div className="loading-image-container-3" onContextMenu={(e) => e.preventDefault()}
                                         style={{userSelect: 'none', WebkitUserSelect: 'none'}}>
                                        <div className='view-image-premium-button' style={{
                                            height: '120px',
                                            width: '40%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <BounceLoader/>
                                        </div>
                                    </div>

                                ) : (

                                    <div className="loading-image-container-2" onContextMenu={(e) => e.preventDefault()}
                                         style={{userSelect: 'none', WebkitUserSelect: 'none'}}>
                                        <img src={currentImageUrl} alt="" className="anime-image" draggable="false"
                                             onContextMenu={(e) => e.preventDefault()}/>
                                        <button
                                            className='view-image-premium-button'
                                            onClick={handleRemoveBlurClick}
                                        >
                                            <DiamondIcon className='view-image-diamond-icon'/>
                                            {t('RemoveBlur')}
                                        </button>
                                    </div>
                                )}


                            </>
                        }

                        <div className="image-container" style={{overflow: 'hidden', borderRadius: '0 14px 14px 14px'}}>

                            <img
                                className={`chat-image ${imageLoaded ? 'visible' : 'hidden'} ${isFullscreen ? 'fullscreen' : ''} ${cacheLikeStatus === 3 ? 'blur' : ''}`}
                                src={`https://elyza.app/cdn-cgi/image/format=avif,width=832,height=1216,compression=fast/${currentImageUrl}`}
                                alt=""
                                onLoad={handleImageLoaded}
                                onError={(e) => console.error('Image failed to load:', e)}
                            />

                            {cacheLikeStatus === 3 && (
                                <button
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        zIndex: 0,

                                    }}
                                    className='view-image-premium-button'
                                    onClick={handleRemoveBlurClick}
                                >
                                    <DiamondIcon className='view-image-diamond-icon'/>
                                    {t('RemoveBlur')}
                                </button>
                            )}
                        </div>

                        {imageLoaded && ((isMobile && imageLoaded) || (cacheLikeStatus !== 3)) && (


                            <div className="like-dislike-buttons"
                                 style={{position: 'absolute', top: '5px', left: '5px'}}>

                                <div className={`like-icon ${cacheLikeStatus === 1 ? 'icon-like-selected' : ''}`}
                                     onClick={handleLikeClick}>
                                    <ThumbsUpIcon/>
                                </div>

                                <div className={`dislike-icon ${cacheLikeStatus === 2 ? 'icon-like-selected' : ''}`}
                                     onClick={handleDislikeClick}>
                                    <ThumbsDownIcon/>
                                </div>

                            </div>
                        )}
                    </div>
                )}

            </div>

            <ReportDislikeButton
                isOpen={isReportModalOpen}
                onCancel={() => setReportModalOpen(false)}
                imageUrl={imageUrl}
                reportNegativeImage={reportNegativeImageQuality}
                shortDescription={reportFormMessage}
                setShortDescription={setReportFormMessage}
                handleShortDescriptionChange={handleShortDescriptionChange}
            />
        </div>
    );
};

export default AIOutputChat;
