import TransStep2 from "./Other/Trans/TransStep2";
import TransStep3 from "./Other/Trans/TransStep3";
import TransStep4 from "./Other/Trans/TransStep4";
import TransStep1 from "./Other/Trans/TransStep1";
import {useTranslation} from "react-i18next";
import TransStep5 from "./Other/Trans/TransStep5";
import TransStep0 from "./Other/Trans/TransStep0";

const createFunnelConfig = (t) => ({
    trannyGeneral: [
        {
            component: TransStep0,
        },
        {
            component: TransStep1,
        },
        {
            component: TransStep2,
        },
        {
            component: TransStep3,
        },
        {
            component: TransStep4,
        },
        {
            component: TransStep5,
        }
    ],
});

export const useStepsConfig = (version) => {
    const {t} = useTranslation();
    const config = createFunnelConfig(t);
    return config[version] || [];
};