import React from "react";
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import {useTranslation} from "react-i18next";

const ConfirmationModal = ({isOpen, onClose, onConfirm, isPublic}) => {
    const {t} = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(10px)',
            background: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999
        }}>
            <div className="modal confirmation-modal">
                <div className='modal-content'>
                    <div className="main-upper-text-row">
                        <span className="main-elyza-text-label">{isPublic ? t('RequestDeletion') : t('ConfirmDelete')}</span>
                        <div className="close-modal-icon" onClick={onClose}><CloseIcon/></div>
                    </div>
                    <div className="deletion-text">
                        <span className="main-elyza-text-sublabel" style={{whiteSpace: 'normal'}}>
                            {isPublic ? t('RequestDeletionSub') : t('ConfirmDeleteSub')}
                        </span>
                    </div>
                    <button className='signup-button' onClick={onConfirm}>
                        {isPublic ? t('RequestDeletion') : t('YesDelete')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;