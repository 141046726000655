import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";
import {ReactComponent as ImageIcon} from "./icons/ImageIcon.svg";
import {ReactComponent as MemoryIcon} from "./icons/MemoryIcon.svg";
import {ReactComponent as InfinityIcon} from "./icons/InfinityIcon.svg";
import UpsellElyzaDev from './images/Preposterous.png';
import './UpdatedUpsell.css';
import {getUserEmail, userIDExists} from "../../../App";
import UpsellRandomGirl1 from "./images/ExitIntentPhoto1.png";
import UpsellRandomGirl2 from "./images/ExitIntentPhoto2.png";
import {ReactComponent as ElyzaIcon} from "../../../components/RewardScreen/RewardIcons/elyzaCoin.svg";

const UpdatedMessageLimitUpsell = ({isOpen, onClose, onSwitchFree, model, modelSwitchExternalCache, setLoginError}) => {
    const {t} = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const images = [UpsellRandomGirl1, UpsellElyzaDev, UpsellRandomGirl2];

    useEffect(() => {
        let interval;
        let transitionTimeout;

        const handleVisibilityChange = () => {
            if (document.hidden) {
                clearInterval(interval);
                clearTimeout(transitionTimeout);
            } else {
                startInterval();
            }
        };

        const startInterval = () => {
            if (interval) clearInterval(interval);
            if (transitionTimeout) clearTimeout(transitionTimeout);

            interval = setInterval(() => {
                if (!document.hidden) {
                    setIsTransitioning(true);

                    // Use a separate state update to ensure proper ordering
                    transitionTimeout = setTimeout(() => {
                        setCurrentImageIndex(prev => (prev + 1) % images.length);
                        setIsTransitioning(false);
                    }, 500); // Increased from 300ms to 500ms for smoother transition
                }
            }, 8000);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        startInterval();

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(interval);
            clearTimeout(transitionTimeout);
        };
    }, [images.length]);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setLoginError(true); // Show login modal
        }

        return loggedIn;
    };

    const pricingPlans = {
        monthly: {
            price: "$14.99",
            originalPrice: "$24.99",
            discountLabel: "40% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Monthly&utm_source=cb-app-copy",
        }
    };

    const processQuickPayment = async () => {
        const loggedIn = await validateIsLoggedIn();

        if (loggedIn) {
            setTimeout(() => {
                setLoginError(false);
            }, 100);

            const userEmail = await getUserEmail();
            window.location.href = `${pricingPlans.monthly.redirect}&customer[email]=${encodeURIComponent(userEmail)}`;
        } else {
            setLoginError(true);
        }
    };


    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 680);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!isOpen || modelSwitchExternalCache === "Elyza Free") return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">

                <div className="updated-modal-content-notification">
                    <div className="updated-notification-left">
                        <div className="updated-notification-label"
                             style={{textAlign: "left", fontSize: '1em', whiteSpace: 'nowrap'}}>

                            <h2>{t('outOfMessages')}</h2>

                            {isMobile && (
                                <div className="modal-close-icon-notification" onClick={() => {
                                    onClose();
                                    onSwitchFree(true);
                                }}>
                                    <CloseIcon/>
                                </div>
                            )}
                        </div>

                        <div style={{
                            paddingLeft: window.innerWidth < 680 ? '20px' : '0',
                            paddingRight: window.innerWidth < 680 ? '20px' : '0'
                        }}
                             className="gap-between-notification">
                            <div className="sublabel-notification">
                                {t('neverRunOut')}<br/><br/>
                                {t('enjoyFeatures')}
                            </div>
                        </div>

                        <div className="features-grid"
                             style={{paddingTop: '20px', paddingBottom: '12px'}}
                        >
                            <div className="feature-item-notification">
                                <MemoryIcon/>
                                <span>{t('enhancedWord1')}<br/>{t('enhancedWord2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <InfinityIcon/>
                                <span>{t('unlimitedWord1')}<br/>{t('unlimitedWord2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ImageIcon/>
                                <span>{t('premiumWord1')}<br/>{t('premiumWord2')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ElyzaIcon/>
                                <span>{t('tokensWord1').replace('60', '125')}<br/>{t('tokensWord2')}</span></div>
                        </div>


                        <div className="notification-buttons-container">
                            <button
                                onClick={processQuickPayment}
                                className="notification-button-main-updated"
                                style={{marginTop: "15px"}}
                            >
                                {t('unlockPremium').replace('9.99', '14.99')}

                            </button>

                            <button
                                onClick={() => onSwitchFree(true)}
                                className="notification-button-secondary-updated"
                                style={{border: isMobile ? 'none' : 'none', height: isMobile ? 'unset' : 'unset'}}
                            >
                                {t('Continue With Free Messages')}
                            </button>
                        </div>

                    </div>

                    <div className="updated-notification-right">

                        <div
                            style={{zIndex: 9999}}
                            className="updated-modal-close-icon-notification" onClick={() => {
                            onClose();
                            onSwitchFree(true);
                        }}>
                            <CloseIcon/>
                        </div>
                        <div className="image-carousel-container-exit-intent">
                            <img
                                src={images[currentImageIndex]}
                                alt="Elyza Character"
                                className="carousel-image-exit-intent"
                                draggable="false"
                                style={{
                                    opacity: isTransitioning ? 0 : 1,
                                    transition: 'opacity 0.5s ease-in-out',
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>
        ),
        document.body
    );
};

export default UpdatedMessageLimitUpsell;