import React, {useEffect, useState} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../../FunnelContext";

import AnimeStyle from "./src/style/animeTrans.jpeg";
import RealStyle from "./src/style/realtrans.jpeg";
import {useLocation} from "react-router-dom";

const TransStep0 = () => {
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedStyle, setSelectedStyle] = useState(funnelCharData.style || 'Anime');
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/create-trans') {
            updateCharacterData({
                gender: 'Futa',
            });
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const styleOptions = [
        {
            name: 'Anime',
            image: AnimeStyle
        },
        {
            name: 'Realistic',
            image: RealStyle
        }
    ];

    const handleStyleSelect = (style) => {
        setSelectedStyle(style);
        updateCharacterData({
            style: style,
        });
    };

    return (
        <div>
            <div className="general-multiple-options-container"
            style={{marginTop: '0'}}
            >
                <div className="funnel-creation-label" style={{marginBottom: '0'}}>
                    {t('StyleTitle')}
                </div>
                <div className="top-row">
                    {styleOptions.map((style, index) => (
                        <div key={`${style.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedStyle === style.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleStyleSelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={style.name}
                                    className={`general-image-creator ${selectedStyle === style.name ? 'selected' : ''}`}
                                    style={{
                                        pointerEvents: 'none',
                                        objectPosition: 'center',
                                        aspectRatio: '1/1.5',
                                    }}
                                />
                                <span className='enforced-display-char-creator-small'>
                                    {t(style.name)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TransStep0;