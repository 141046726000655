import React, {useEffect, useState, useCallback} from "react";
import {ReactComponent as CrownIcon} from "../../../icons/crown.svg";
import {ReactComponent as BlackFridayIcon} from "../../../icons/blackFridaybanner.svg";
import {useTimer} from "../../Context/TimerContext";
import {useProfileData} from "../../Context/ProfileDataContext";
import {useTranslation} from "react-i18next";
import './TopTimer.css';
const HOUR_IN_MS = 60 * 60 * 1000; // 3,600,000
const DAY_IN_MS = 24 * HOUR_IN_MS;

//TODO: translate this shit ! ! !

const TIMER_MODES = {
    STANDARD: {
        duration: HOUR_IN_MS,
        baseClass: 'standard',
        message: 'FIRST_SUBSCRIPTION',
        discount: 'OFF',
        icons: [<CrownIcon key="crown" />]
    },
    HOLIDAY: {
        duration: DAY_IN_MS,
        baseClass: 'holiday',
        message: 'CYBER WEEK SALE',
        discount: '33% Off - 3 Months',
        icons: [<BlackFridayIcon key="blackfriday" />]
    }
};

const getTimerMode = (isHolidaySale) => isHolidaySale ? TIMER_MODES.HOLIDAY : TIMER_MODES.STANDARD;

const calculateTimeLeft = (targetTime) => {
    const now = Date.now();
    const distance = targetTime - now;

    if (distance < 0) {
        return {hours: 0, minutes: 0, seconds: 0, milliseconds: 0, expired: true};
    }

    // Calculate hours first (for 24-hour display)
    const totalHours = Math.floor(distance / HOUR_IN_MS);
    const remainingAfterHours = distance % HOUR_IN_MS;

    return {
        hours: totalHours,
        minutes: Math.floor(remainingAfterHours / (60 * 1000)),
        seconds: Math.floor((remainingAfterHours % (60 * 1000)) / 1000),
        milliseconds: Math.floor((remainingAfterHours % 1000) / 10),
        expired: false
    };
};

const CountdownTimer = ({onExpire, targetTime, mode}) => {

    const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(targetTime));
    const {t} = useTranslation();
    const showHours = mode.duration > HOUR_IN_MS;

    const baseClass = mode.baseClass;

    useEffect(() => {
        const updateTimer = () => {
            const newTimeLeft = calculateTimeLeft(targetTime);
            setTimeLeft(newTimeLeft);

            if (!newTimeLeft.expired) {
                requestAnimationFrame(updateTimer);
            } else {
                onExpire();
            }
        };

        const animationId = requestAnimationFrame(updateTimer);
        return () => cancelAnimationFrame(animationId);
    }, [targetTime, onExpire]);

    const renderTimeSegment = (value, label) => (
        <div className={`${baseClass}-segment`}>
            <span className={`${baseClass}-number`}>
                {String(value).padStart(2, '0')}
            </span>
            <small className={`${baseClass}-text`}>{t(label)}</small>
        </div>
    );

    return (
        <div className={`${baseClass}-timer`}>
            {showHours && (
                <>
                    {renderTimeSegment(timeLeft.hours, 'Hrs')}
                    <span className={`${baseClass}-segment-separator`}>:</span>
                </>
            )}
            {renderTimeSegment(timeLeft.minutes, 'Min')}
            <span className={`${baseClass}-segment-separator`}>:</span>
            {renderTimeSegment(timeLeft.seconds, 'Sec')}
            <span className={`${baseClass}-segment-separator`}>:</span>
            {renderTimeSegment(timeLeft.milliseconds, 'Ms')}
        </div>
    );
};

const TopTimerChatPage = ({onTimerActive}) => {
    const {profileData} = useProfileData();
    const {timerExpired, setTimerExpired} = useTimer();
    const [targetTime, setTargetTime] = useState(null);
    const [isHolidaySale, setIsHolidaySale] = useState(false);
    const {t} = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    const [renderTimer, setRenderTimer] = useState(true);

    useEffect (() => {

        if (isMobile === true && isHolidaySale === true) {
            setRenderTimer(false);
        } else {
            setRenderTimer(true);
        }

    }, [isMobile, isHolidaySale]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 680);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const checkHolidaySale = () => {
            const now = new Date();
            const blackFridayStart = new Date(now.getFullYear(), 11, 7);
            const blackFridayEnd = new Date(now.getFullYear(), 11, 8);
            setIsHolidaySale(now >= blackFridayStart && now <= blackFridayEnd);
        };
        checkHolidaySale();
        //eslint-disable-next-line
    }, []);

    const timerMode = getTimerMode(isHolidaySale);

    useEffect(() => {
        if (profileData.subscription_data.tier === "FREE") {
            // Check for existing timer
            const savedTargetTime = sessionStorage.getItem('countdownTargetTime');

            if (savedTargetTime) {
                // If timer exists and hasn't expired, use it
                const parsedTargetTime = parseInt(savedTargetTime);
                if (parsedTargetTime > Date.now()) {
                    setTargetTime(parsedTargetTime);
                    setTimerExpired(false);
                    return;
                }
            }

            // Only set new timer if there isn't a valid existing one
            const now = Date.now();
            const duration = timerMode.duration;
            const newTargetTime = now + duration;
            sessionStorage.setItem('countdownTargetTime', newTargetTime.toString());
            setTargetTime(newTargetTime);
            setTimerExpired(false);
        } else {
            setTimerExpired(true);
        }
    }, [profileData.subscription_data.tier, setTimerExpired, timerMode]);

    const handleExpire = useCallback(() => {
        sessionStorage.removeItem('countdownTargetTime');
        setTimerExpired(true);
    }, [setTimerExpired]);

    const handleClick = () => {

        if (timerMode.baseClass === "holiday" && window.location.pathname !== '/pricing-sale') {
            window.location.href = '/pricing-sale';
        }

        if (window.location.pathname !== '/pricing' && timerMode.baseClass === "standard") {
            window.location.href = '/pricing';
        }
    };

    if (timerExpired) return null;

    return (
        <div className={`${timerMode.baseClass}-timer-container`}
             style={{borderRadius: 0, height: '60px', width: '100vw'}}
             onClick={handleClick}>
            <div className={`${timerMode.baseClass}-timer-text`}>
                {timerMode.icons.map((icon) => icon)}
                {t(timerMode.message)}
            </div>
            <div className={`${timerMode.baseClass}-timer-padding`}>
                {t(timerMode.discount)}
                {/*{t('OFF')}*/}
            </div>
            {targetTime && renderTimer && (
                <div className={`${timerMode.baseClass}-timer-general`}
                style={{padding: '6px 12px'}}
                >
                    <CountdownTimer
                        onExpire={handleExpire}
                        targetTime={targetTime}
                        mode={timerMode}
                    />
                </div>
            )}
        </div>
    );
};

export default TopTimerChatPage;