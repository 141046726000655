import { useState, useRef, useEffect } from 'react';
import { ReactComponent as VolumeX } from "../../icons/volume-x.svg";
import { ReactComponent as Volume } from "../../icons/volume.svg";
import { ReactComponent as Play } from "../../icons/play.svg";
import { ReactComponent as Pause } from "../../icons/pause.svg";
import './CustomAudioPlayer.css';
const CustomAudioPlayer = ({ src, duration }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(1);
    const [showVolumeControl, setShowVolumeControl] = useState(false);
    const audioRef = useRef(null);
    const progressRef = useRef(null);
    const volumeControlRef = useRef(null);
    const volumeSliderRef = useRef(null);
    const isDraggingRef = useRef(false);

    const updateSliderBackground = (element, value, max) => {
        const progress = Math.min((value / max) * 100, 100);
        element.style.backgroundImage =
            `linear-gradient(to right, white ${progress}%, #4b4b4b ${progress}%)`;
    };

    const handleMouseDown = () => {
        isDraggingRef.current = true;
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
    };

    useEffect(() => {
        const audio = audioRef.current;
        let raf;

        const updateProgress = () => {
            if (!audio) return;

            setCurrentTime(audio.currentTime);
            if (progressRef.current) {
                updateSliderBackground(progressRef.current, audio.currentTime, duration);
            }

            if (isPlaying) {
                raf = requestAnimationFrame(updateProgress);
            }
        };

        if (isPlaying) {
            raf = requestAnimationFrame(updateProgress);
        }

        const handleClickOutside = (event) => {
            if (volumeControlRef.current && !volumeControlRef.current.contains(event.target)) {
                setShowVolumeControl(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            cancelAnimationFrame(raf);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPlaying, duration]);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        audioRef.current.muted = !isMuted;
        setIsMuted(!isMuted);

        if (volumeSliderRef.current) {
            const value = isMuted ? 0 : volume;
            updateSliderBackground(volumeSliderRef.current, value, 1);
        }
    };

    const handleTimeChange = (e) => {
        const time = parseFloat(e.target.value);
        audioRef.current.currentTime = time;
        setCurrentTime(time);
        updateSliderBackground(e.target, time, duration);
    };

    const handleVolumeChange = (e) => {
        const value = parseFloat(e.target.value);
        audioRef.current.volume = value;
        setVolume(value);
        setIsMuted(value === 0);
        updateSliderBackground(e.target, value, 1);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        if (progressRef.current) {
            updateSliderBackground(progressRef.current, currentTime, duration);
        }
        if (volumeSliderRef.current) {
            updateSliderBackground(volumeSliderRef.current, isMuted ? 0 : volume, 1);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <div className="custom-player">
            <audio
                ref={audioRef}
                src={src}
                onEnded={() => {
                    setIsPlaying(false);
                    setCurrentTime(duration);
                    if (progressRef.current) {
                        updateSliderBackground(progressRef.current, duration, duration);
                    }
                }}
            />

            <button
                onClick={togglePlay}
                className="player-button"
            >
                {isPlaying ? <Pause /> : <Play />}
            </button>

            <div className="time-controls">
                <span className="time-display">
                    {formatTime(currentTime)}
                </span>
                <input
                    ref={progressRef}
                    type="range"
                    min="0"
                    max={duration}
                    step="0.01"
                    value={currentTime}
                    onChange={handleTimeChange}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    className="progress-slider"
                />
                <span className="time-display">
                    {formatTime(duration)}
                </span>
            </div>

            <div className="volume-controls" ref={volumeControlRef}>
                <button
                    onClick={() => {
                        toggleMute();
                        setShowVolumeControl(!showVolumeControl);
                    }}
                    className="player-button"
                >
                    {isMuted ? <VolumeX /> : <Volume />}
                </button>
                <div className={`volume-popup ${showVolumeControl ? 'visible' : ''}`}>
                    <input
                        ref={volumeSliderRef}
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={handleVolumeChange}
                        className="progress-slider"
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomAudioPlayer;