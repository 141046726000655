import React, {useEffect, useState} from "react";
import {ReactComponent as DeleteIcon} from "../../icons/deleteIcon.svg";
import {ReactComponent as UploadIcon} from "../../icons/UploadIcon.svg"
import CustomAudioPlayer from "./CustomAudioPlayer";
import {useCharacterCreation} from "../../CharacterCreationContext";
import {useAdvancedCharacterCreation} from "../../../AdvancedCharacterCreation/AdvancedCharacterContext";

const FileUploadSection = ({currentVoiceOptionActive, setCurrentVoiceOptionActive}) => {
    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [audioDuration, setAudioDuration] = useState(0);
    const acceptedFileTypes = ['audio/wav', 'audio/mpeg', 'audio/mp3', 'audio/mp4', 'audio/x-m4a', 'audio/aac', 'audio/ogg'];
    const MAX_DURATION = 10; // Maximum duration in seconds
    const { updateCharacterData } = useCharacterCreation();
    const {updateAdvancedCharacterData} = useAdvancedCharacterCreation();
    const calculateAudioDuration = (file) => {
        return new Promise((resolve) => {
            const audio = new Audio();
            audio.src = URL.createObjectURL(file);

            audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                URL.revokeObjectURL(audio.src);
                resolve(duration);
            });
        });
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!acceptedFileTypes.includes(file.type)) {
                alert('Please upload a valid audio file (WAV, MP3, M4A, OGG, AAC)');
                return;
            }

            if (file.size > 10 * 1024 * 1024) {
                alert('File size should not exceed 10MB');
                return;
            }

            const duration = await calculateAudioDuration(file);

            if (duration > MAX_DURATION) {
                alert(`Audio file must not exceed ${MAX_DURATION} seconds. Current duration: ${Math.round(duration)} seconds`);
                return;
            }

            // Create URL for preview playback
            const audioUrl = URL.createObjectURL(file);
            setAudioDuration(duration);
            setCurrentVoiceOptionActive('upload');
            setUploadedAudio(audioUrl);

            // Convert file to base64
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Audio = reader.result.split(',')[1]; // Remove the data URL prefix

                if (window.location.href === 'https://elyza.app/advanced-creator') {
                    updateAdvancedCharacterData({ clonedVoiceURL: base64Audio });
                } else if (window.location.href === 'https://elyza.app/create-character') {
                    updateCharacterData({ clonedVoiceURL: base64Audio });
                }

            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        return () => {
            if (uploadedAudio) {
                URL.revokeObjectURL(uploadedAudio);
            }
        };
    }, [uploadedAudio]);

    if (currentVoiceOptionActive === 'library' || currentVoiceOptionActive === 'record') {
        return null;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <div style={{width: '100%'}}>
                <label
                    htmlFor="audio-upload"
                    className="audio-upload-label"
                >
                    <UploadIcon />
                    Upload Audio File
                </label>
                <input
                    id="audio-upload"
                    type="file"
                    accept="audio/*"
                    onChange={handleFileUpload}
                    style={{display: 'none'}}
                />
            </div>

            {uploadedAudio && (
                <div className="audio-preview-container"
                     style={{marginTop: '15px', marginBottom: '0'}}
                >
                    <CustomAudioPlayer
                        src={uploadedAudio}
                        duration={audioDuration}
                    />

                    <div
                        onClick={() => {
                            setUploadedAudio(null);
                            setCurrentVoiceOptionActive('');
                            setAudioDuration(0);
                        }}
                        className="delete-voice-clone-audio-button"
                    >
                        <DeleteIcon
                            style={{minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px'}}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileUploadSection;