import React, {useEffect, useRef, useState} from 'react';
import './ChatSettingDropdown.css';
import ReactDOM from 'react-dom';
import {ReactComponent as ResponseIcon} from './icons/responseIcon.svg';
import {ReactComponent as ShareIcon} from './icons/shareIcon.svg';
import {ReactComponent as CustomizeIcon} from './icons/customizeIcon.svg';
import {useTranslation} from "react-i18next";
import {useChatSettings} from "./ChatSettingsContext";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {FormControlLabel, Switch} from "@mui/material";
import {useChat} from "../../components/Context/ChatContext";
import {ReactComponent as LockIcon} from "../../icons/purpleDiamond.svg";
import {useNavigate} from "react-router-dom";

const ChatSettingDropdown = ({isOpen, onClose, setSubscriptionError}) => {
    const {profileData} = useProfileData();
    const menuRef = useRef(null);
    const [isAnimatingOut, setIsAnimatingOut] = useState(false);

    const {
        toggleResponseModal,
        toggleCustomizeModal,
        toggleShareModal,
        isCharacterBackground,
        toggleCharacterBackground,
        toggleMobileCustomization,
        isAutoGenerateAudio,
        toggleAutoGenerateAudio
    } = useChatSettings();

    const clickAutoGenerateAudio = () => {
        if (profileData.subscription_data.tier === "FREE") {
            setSubscriptionError(true);
        } else {
            toggleAutoGenerateAudio();
        }
    }

    const {t} = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        setIsAnimatingOut(true);
        setTimeout(() => {
            setIsAnimatingOut(false);
            onClose();
        }, 300); // This should match the CSS transition duration
    };

    const settingOptions = [
        {
            title: t('ResponseSettings'),
            icon: <ResponseIcon/>,
            action: () => {
                toggleResponseModal();
                handleClose();
            }
        },
        {
            title: t('shareThisChat'),
            icon: <ShareIcon/>,
            action: () => {
                toggleShareModal();
                handleClose();
            }
        },
        {
            title: t('CustomizeElyza'),
            icon: <CustomizeIcon/>,
            action: () => {
                if (window.innerWidth < 680) {
                    toggleMobileCustomization();
                } else {
                    toggleCustomizeModal();
                }
                handleClose();
            }
        },
        // {
        //     title: t('upgrade'),
        //     icon: <HiddenGemIcon/>,
        //     action: () => {
        //         handleClose();
        //         navigate('/pricing');
        //     },
        // },
    ];
    //
    // const [exampleState, setExampleState] = useState(false);
    // const handleSwitchClick = () => {
    //     setExampleState(!exampleState);
    // }
    const {
        isGalleryBannerVisible,
    } = useChat();


    if (!isOpen && !isAnimatingOut) return null;

    return ReactDOM.createPortal(
        (<div className={`modal-backdrop-chat-control ${isOpen && !isAnimatingOut ? 'fade-in' : 'fade-out'}`}>
                <div
                    className={`modal-content-profile-control-menu ${isOpen && !isAnimatingOut ? 'pop-in' : 'pop-out'}`}
                    ref={menuRef}

                    style={{marginRight: isGalleryBannerVisible ? '400px' : '15px'}}

                >

                    <div className="line-container">
                        <span className="text-line-container">{t('FEATURES')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="slider-label"
                         style={{
                             marginTop: '5px',
                             fontSize: profileData.subscription_data.tier === "FREE" ? '0.9em' : '1em',
                             color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"
                         }}
                    >
                        {/*{t('dynamicScenarioNoDot')}*/}

                        {t('THEME_OVERLAY')}
                        <div className='two-switches-menu'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isCharacterBackground}
                                        // disabled={isSubscriptionLocked}
                                        onChange={toggleCharacterBackground}
                                        name="switch1"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: 'white', // Thumb color when checked
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered
                                                },
                                            }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: '#9072D9', // Track color when checked
                                            }, '& .MuiSwitch-track': {
                                                backgroundColor: 'grey', // Track color when unchecked
                                            },
                                        }}
                                    />}
                                labelPlacement="start"/>
                        </div>
                    </div>

                    <div className="slider-label"
                         style={{
                             marginTop: '5px',
                             fontSize: profileData.subscription_data.tier === "FREE" ? '0.9em' : '1em',
                             color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"
                         }}
                    >

                        <span
                            style={{color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"}}>
                            {t('AUDIO_MESSAGE')}
                        </span>

                        {profileData.subscription_data.tier === "FREE" &&
                            <div className="menu-item-content-icon-premium-but-smaller"
                                 style={{fontSize: '0.85em'}}
                                 onClick={() => navigate('/pricing')}>
                                <LockIcon
                                    style={{minWidth: '20px', minHeight: '20px', maxHeight: '20px', maxWidth: '20px'}}
                                />
                                {t('premium').charAt(0).toUpperCase() + t('premium').slice(1)}
                            </div>
                        }

                        <div className='two-switches-menu'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isAutoGenerateAudio}
                                        // disabled={isSubscriptionLocked}
                                        onChange={clickAutoGenerateAudio}
                                        name="switch1"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: profileData.subscription_data.tier === "FREE" ? "#4c4c4c" : "#9072D9", // Thumb color when checked
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered
                                                },
                                            }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#6335D2", // Track color when checked
                                            }, '& .MuiSwitch-track': {
                                                backgroundColor: 'grey', // Track color when unchecked
                                            },
                                        }}
                                    />}
                                labelPlacement="start"/>
                        </div>
                    </div>
                    {/*<div className="slider-label"*/}
                    {/*     style={{*/}
                    {/*         marginTop: '5px',*/}
                    {/*         color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    /!*{t('dynamicScenarioNoDot')}*!/*/}
                    {/*    <div className='two-switches-menu'>*/}
                    {/*        <FormControlLabel*/}
                    {/*            control={*/}
                    {/*                <Switch*/}
                    {/*                    checked={exampleState}*/}
                    {/*                    // disabled={isSubscriptionLocked}*/}
                    {/*                    onChange={handleSwitchClick}*/}
                    {/*                    name="switch1"*/}
                    {/*                    sx={{*/}
                    {/*                        '& .MuiSwitch-switchBase.Mui-checked': {*/}
                    {/*                            color: 'white', // Thumb color when checked*/}
                    {/*                            '&:hover': {*/}
                    {/*                                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered*/}
                    {/*                            },*/}
                    {/*                        }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {*/}
                    {/*                            backgroundColor: '#9072D9', // Track color when checked*/}
                    {/*                        }, '& .MuiSwitch-track': {*/}
                    {/*                            backgroundColor: 'grey', // Track color when unchecked*/}
                    {/*                        },*/}
                    {/*                    }}*/}
                    {/*                />}*/}
                    {/*            labelPlacement="start"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="balance-counter-main">*/}
                    {/*    <div className="profile-count-counter">*/}
                    {/*        {userTokens}*/}
                    {/*        <ElyzaCoin/>*/}
                    {/*    </div>*/}
                    {/*    <Link*/}
                    {/*        to="/addons"*/}
                    {/*        style={{textDecoration: 'none'}}*/}
                    {/*        className="get-more-tokens-sign"*/}
                    {/*        onClick={handleTokensClick}*/}
                    {/*    >*/}
                    {/*        {t('GetMoreTokens')}*/}
                    {/*    </Link>*/}
                    {/*</div>*/}

                    <div className="line-container">
                        <span className="text-line-container">{t('Options')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="profile-control-options">
                        {settingOptions.map((option, index) => (
                            <div
                                className="profile-control-option"
                                key={index}
                                onClick={option.action}
                            >
                                {/*{option.icon}*/}
                                {/*<span className="profile-control-option-title">{option.title}</span>*/}

                                <div className="menu-icon-desktop">
                                    {option.icon}
                                </div>
                                <span
                                    className="menu-label-desktop">
                                    {option.title}
                                </span>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        document.getElementById('modal-root')
    );
};

export default ChatSettingDropdown;