import React, {useEffect, useState} from "react";
import "./NewCharacterContainer.css";
import 'react-tooltip/dist/react-tooltip.css';
import {ReactComponent as AltCard} from "./icons/altcard.svg";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Tooltip from '@mui/material/Tooltip';
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export const CharacterContainer = ({character, handleCharacterClick, username, scrollPosition, isBlurred, setIsBlurred, ageVerificationModalOpen, setAgeVerificationModalOpen, unblurAllImages}) => {
    const {t} = useTranslation();

    const NSFW_VERIFICATION_COOKIE = 'nsfwAgeVerified';

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        return savedLanguage || i18n.language;
    });

    useEffect(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        const i18nLang = i18n.language;

        if (savedLanguage && savedLanguage !== currentLanguage) {
            setCurrentLanguage(savedLanguage);
        } else if (!savedLanguage && i18nLang !== currentLanguage) {
            setCurrentLanguage(i18nLang);
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    // Check if user is already age-verified on component mount
    useEffect(() => {
        if (character.nsfw_picture) {
            const isAgeVerified = Cookies.get(NSFW_VERIFICATION_COOKIE) === 'true';
            setIsBlurred(!isAgeVerified);
        }
        //eslint-disable-next-line
    }, [character.nsfw_picture]);


    const handleUnblurImage = () => {
        const isAgeVerified = Cookies.get(NSFW_VERIFICATION_COOKIE) === 'true';
        if (isAgeVerified) {
            unblurAllImages();
        } else {
            setAgeVerificationModalOpen(true);
        }
    };

    useEffect(() => {
        const isAgeVerified = Cookies.get(NSFW_VERIFICATION_COOKIE) === 'true';
        if (!isAgeVerified) {
            setAgeVerificationModalOpen(true);
        }
        //eslint-disable-next-line
    }, []);

    // Rest of your existing functions (getTranslatedContent, formatDescription, etc.)
    const getTranslatedContent = () => {
        if (character.preferred_language === 'EN+' && character.translations) {
            const userLang = currentLanguage.toUpperCase();
            if (character.translations[userLang]) {
                return {
                    display_name: character.translations[userLang].display_name || character.display_name,
                    description: character.translations[userLang].description || character.description
                };
            }
        }
        return {
            display_name: character.display_name,
            description: character.description
        };
    };

    const [translatedContent, setTranslatedContent] = useState({
        display_name: character.display_name,
        description: character.description
    });

    useEffect(() => {
        const newTranslations = getTranslatedContent();
        const hasContentChanged =
            newTranslations.display_name !== translatedContent.display_name ||
            newTranslations.description !== translatedContent.description;

        if (hasContentChanged) {
            setTranslatedContent(newTranslations);
        }
        // eslint-disable-next-line
    }, [currentLanguage, character.translations, character.preferred_language, character.display_name, character.description]);

    function formatDescription(character, username) {
        let description = translatedContent.description
            .replace(/{{char}}/gi, translatedContent.display_name)
            .replace(/{{user}}/gi, username);
        return description.length > 50 ? `${description.substring(0, 50)}...` : description;
    }

    const selectImageQuality = () => {
        const width = window.innerWidth;
        if (width <= 768) return "width=416,height=416";
        if (width <= 1024) return "width=512,height=512";
        if (width <= 1536) return "width=512,height=512";
        return "width=512,height=512";
    };

    const imageUrl = character.profile ? `https://elyza.app/cdn-cgi/image/format=avif,${selectImageQuality()},compression=fast/${character.profile}` : "";

    function formatFullDescription(character, username) {
        return translatedContent.description
            .replace(/{{char}}/g, translatedContent.display_name)
            .replace(/{{user}}/g, username);
    }

    const characterClickFunction = () => {
        if (character.nsfw_picture && isBlurred) {
            handleUnblurImage();
        } else {
            handleCharacterClick(character.identity_name);
        }
    };

    return (
        <>
            <div className="character-container-new" onClick={characterClickFunction}>
                <div className="character-image-overlay-container-new">
                    {imageUrl ? (
                        <div className={`image-container ${character.nsfw_picture && isBlurred ? 'blurred' : ''}`}>
                            <LazyLoadImage
                                effect="blur"
                                src={imageUrl}
                                alt={translatedContent.display_name}
                                className="character-image-main-new"
                                placeholderSrc={AltCard}
                                scrollPosition={scrollPosition}
                            />
                            {character.nsfw_picture && isBlurred && (
                                <div className="blur-unblur-general">
                                    <div className="nsfw-blur-text">{t('NSFWChar')}</div>
                                    <button
                                        className="unblur-button-character"
                                        onClick={handleUnblurImage}
                                    >
                                        {t('Unblur')}
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="character-svg-altcard"></div>
                    )}
                    <div className="character-gradient-overlay-new"></div>
                    <div className="character-text-content-new">
                        <div className="character-name-new">
                            {translatedContent.display_name}
                        </div>
                        <div className="character-description-new">
                            <Tooltip title={formatFullDescription(character, username)} placement="top" arrow>
                                <span>{formatDescription(character, username)}</span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};