import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";
import {AuthModal} from "../Forms/AuthModal";

const CreateCharPleaseLogin = ({isOpen, onClose, characterName}) => {

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const handleSignUp = () => {
        setIsAuthModalOpen(true);
    }

    const {t} = useTranslation();

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <>
                <div className="modal-backdrop"
                     style={{alignItems: 'center'}}
                >
                    <div className="modal-content-notification"
                         style={{
                             borderRadius: '16px'
                         }}
                    >
                        <div className='notification-label'>
                            ✋ {t('WaitASecond')}
                            <div className="modal-close-icon-notification" onClick={onClose}>
                                <CloseIcon/>
                            </div>
                        </div>

                        <div className="gap-between-notification">
                            <div className='sublabel-notification'>
                                {t('BeforeYouCreate')}
                                <br></br>
                                <br></br>
                                {t('DontWorry')}
                                {characterName}
                                {t('WillBeWaiting')}
                            </div>
                        </div>

                        <button
                            className="notification-button-main"
                            onClick={handleSignUp}
                        >
                            {t('SignUp')}
                        </button>
                    </div>
                </div>

                {isAuthModalOpen &&
                    <AuthModal
                        isOpen={isAuthModalOpen}
                        onClose={() => setIsAuthModalOpen(false)}
                    />
                }

            </>
        ),
        document.body
    );
};

export default CreateCharPleaseLogin;