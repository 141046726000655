import React, {useEffect, useRef, useState} from 'react';
import '../CharacterCard/CharacterCard.css';
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import './UserPage.css';
import {fetchUserData} from "./UserPageFunctions";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {getUserAccessToken} from "../../App";
import {useNavigate} from "react-router-dom";
import {ReactComponent as ReportIcon} from "./icons/reportIcon.svg";
import {ReactComponent as ThreeDotsIcon} from "./icons/threeDots.svg"
import PleaseUpdateBio from "../../components/Notifications/UpdateBioModal";
import UserScreenLoader from "../../components/LoadingEvents/UserLoader/UserLoader";
import {useTranslation} from "react-i18next";
import BioChangePopup from "../../components/Modals/BioChangePopup";
import TopTimer from "../../components/Notifications/TopBanners/TopTimer";
import {useTimer} from "../../components/Context/TimerContext";
import UniversalFilterModal from "../../components/Modals/UniversalFilterModal";
import {LazyLoadImage} from "react-lazy-load-image-component";
import SignUpToUseGeneral from "../../components/Notifications/SignUpToUseGeneral";
import PublicMemoriesUserMobile from "./Components/PublicMemoriesUserMobile";
import PublicMemoriesUserDesktop from "./Components/PublicMemoriesUserDesktop";
import {FollowButton} from "./Components/FollowButton";
import ScrollToTop from "../CharacterCard/Components/ScrollToTop";
import {usePageTracking} from "../../components/Analytics/UserAnalyticsContext";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";

const UserPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const [isUser, setIsUser] = useState(false);
    const tagName = window.location.pathname.substring(6);
    const {profileData} = useProfileData();
    const [isFollowing, setIsFollowing] = useState(false);
    const [isEditingBio, setIsEditingBio] = useState(false);
    const [editedBio, setEditedBio] = useState('');
    const [pleaseUpdateBioModalOpen, setIsPleaseUpdateBioModalOpen] = useState(false)
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {timerExpired} = useTimer();
    const {t} = useTranslation();
    const [publicMemories, setPublicMemories] = useState([]);
    usePageTracking('user_page');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 720);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const replacePlaceholders = (text, characterName = '') => {
        if (!text) return '';

        const username = profileData?.username || '';
        const charName = characterName || '';

        return text
            // Replace user placeholders
            .replace(/\{\{user\}\}/gi, username)
            .replace(/\{user\}/gi, username)
            .replace(/\{\{User\}\}/g, username.charAt(0).toUpperCase() + username.slice(1))
            .replace(/\{User\}/g, username.charAt(0).toUpperCase() + username.slice(1))
            // Replace character placeholders
            .replace(/\{\{char\}\}/gi, charName)
            .replace(/\{char\}/gi, charName)
            .replace(/\{\{Char\}\}/g, charName.charAt(0).toUpperCase() + charName.slice(1))
            .replace(/\{Char\}/g, charName.charAt(0).toUpperCase() + charName.slice(1));
    };

    useEffect(() => {
        if (userData.bio) {
            setEditedBio(userData.bio);
        }

        if (profileData.bio === '' || profileData.bio === null) {
            setIsPleaseUpdateBioModalOpen(true)
        }

    }, [userData.bio, profileData.bio]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const data = await fetchUserData(tagName);
                setUserData(data);

                setIsUser(data.tagname === profileData.tagname);

                const followers = data.followers?.map(follower => follower.tagname) || [];
                setIsFollowing(followers.includes(profileData.tagname));
            } catch (err) {
                if (err.message === "User not found") {
                    setError("User not found");
                } else {
                    setError("An error occurred while fetching user data");
                }
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [tagName, profileData.tagname]);

    const truncateDescription = (message, limit = 85, displayName) => {
        if (!message) return '';
        const replacedMessage = replacePlaceholders(message, displayName);
        if (replacedMessage.length <= limit) return replacedMessage;
        return replacedMessage.slice(0, limit) + '...';
    };

    const handleFollowUser = async () => {
        try {
            const response = await fetch('https://api.elyza.app/v1/follow-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({tagname: tagName}),
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }

            const data = await response.json();
            return data.bio;
        } catch (error) {
            throw error;
        }
    }

    const handleSaveBio = async () => {
        try {
            const newBio = await sendEditedBio(editedBio);
            setUserData(prevData => ({...prevData, bio: newBio}));
            setIsEditingBio(false);
        } catch (error) {
            console.error('Error updating bio:', error);
        }
    }

    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const sendEditedBio = async (newBio) => {
        try {
            const response = await fetch('https://api.elyza.app/v1/edit-bio', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({bio: newBio}),
            });

            if (response.status === 403) {
                setErrorMessage('Content violates our policies');
                setIsWarningModalOpen(true);
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }

            const data = await response.json();
            return data.bio;
        } catch (error) {
            throw error;
        }
    };

    const handleEditBio = () => {
        setIsEditingBio(!isEditingBio);
    }

    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);
    const threeDotsRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target) &&
                threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const togglePopup = () => {
        setShowPopup(prevState => !prevState);
    };

    const reportIdentity = async () => {
        await fetch('https://api.elyza.app/v1/report-identity', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                identityName: tagName, message: 'N/A',
            })
        });
    }

    const Popup = () => (
        <div ref={popupRef} className="username-popup">
            <button className="report-button-insider"
                    onClick={() => {
                        reportIdentity().then(() => {
                        });
                        togglePopup();
                    }}>
                {t('Report')}
                <ReportIcon/>
            </button>
        </div>
    );

    const handleNavigateToMain = () => {
        navigate('/');
    };

    if (error) {
        return (
            <div className="nothing-found">
                <h2 className="nothing-found-message">{error}</h2>
                <button className="save-settings-button"
                        style={{width: '50%'}}
                        onClick={handleNavigateToMain}
                >
                    {t('BackToMainPage')}

                </button>
            </div>
        );
    }

    const handleCharacterClick = (identityName) => {
        navigate(`/c/${identityName}`);
    };

    return (
        <>

            {isUserLoggedIn && (
                //TODO: REWORK SIGNUP MODAL AND ADD ANIME TITTIES AND MAKE MORE UNIVERSAL
                <SignUpToUseGeneral
                    isOpen={isUserLoggedIn}
                    onClose={() => setIsUserLoggedIn(false)}
                />
            )}

            {!timerExpired ? (
                <div className="top-timer-container-main-pricing"
                     style={{display: timerExpired !== false ? "none" : 'block'}}
                >
                    <TopTimer/>
                </div>
            ) : null}

            {isLoading && (
                <UserScreenLoader/>
            )}

            <div className="character-card-page">

                <HeaderMainMenu
                    setSearchText={() => {
                    }}
                    searchText={''}
                />
                <div className="character-card-background-user"></div>

                <div className="character-card-column-unify"
                     style={{marginTop: timerExpired !== false ? '115px' : '185px'}}
                >

                    <div className="character-card-left-column">

                        {userData.profile_url ? (
                            <div className="character-page-image-container">
                                <LazyLoadImage
                                    loading="lazy"
                                    alt={''}
                                    src={`https://elyza.app/cdn-cgi/image/format=avif,width=420,height=420,compression=fast/${userData.profile_url}`}
                                    className="user-page-image"
                                />
                            </div>
                        ) : (
                            <div className="user-profile-placeholder"></div>
                        )}
                    </div>

                    <div className="character-card-right-column">
                        <div className="object-centralizer-component">
                            <div className="character-card-right-column-button-header">
                                <h2 className="character-page-name"
                                    style={{marginTop: '25px'}}
                                >

                                    <span className="character-name-centralizer">
                                        {userData.username}
                                        <div className="three-dots-container">
                                    <ThreeDotsIcon
                                        ref={threeDotsRef}
                                        onClick={togglePopup}
                                        style={{cursor: 'pointer'}}
                                    />
                                            {showPopup && <Popup/>}
                                    </div>
                                </span>
                                </h2>
                                <div
                                    className="user-stats-text">{tagName} • {userData.follower_count} {t('followers')}</div>
                                <h4 className="character-page-bio">
                                    <span>{editedBio}</span>
                                </h4>
                            </div>
                            <FollowButton
                                isUser={isUser}
                                handleEditBio={handleEditBio}
                                handleFollowUser={handleFollowUser}
                                isFollowing={isFollowing}
                                isUserLoggedIn={isUserLoggedIn}
                            />
                        </div>
                    </div>
                </div>

                <div className="lower-half-character-page"
                     style={{
                         flexDirection: isMobile ? 'column-reverse' : 'row',
                         marginTop: isMobile ? '0px ' : '60px',
                         gap: isMobile ? '20px ' : '40px'
                     }}
                >
                    <div className="similar-characters-main"
                         style={{marginTop: isMobile ? '0' : '0'}}
                    >

                        <h3>{isUser ? t('YourCharacters') : t('PublicCharacters')}</h3>

                        <div className="similar-characters-list">
                            {userData.public_identities && userData.public_identities.length > 0 ? (
                                userData.public_identities.map((character, index) => (
                                    <div
                                        onClick={() => handleCharacterClick(character.identity_name)}
                                        style={{cursor: 'pointer'}}
                                        key={character.identity_name}
                                        className='similar-characters-unify'
                                    >
                                        {character.profile ? (
                                            <div className="similar-characters-avatar">
                                                <LazyLoadImage
                                                    loading="lazy"
                                                    src={`https://elyza.app/cdn-cgi/image/format=avif,width=128,height=128,compression=fast/${character.profile}`}
                                                    alt={''}
                                                    className='similar-characters-avatar-image'
                                                />
                                            </div>
                                        ) : (
                                            <div className="similar-chars-placeholder"></div>
                                        )}

                                        <div className="similar-characters-text-container">
                                            <div className="similar-characters-name">
                                                {character.display_name}
                                            </div>
                                            <div
                                                className="similar-characters-description">{truncateDescription(character.description, 60, character.display_name)}</div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="no-public-characters">
                                    <p>{t('NoPublicCharacters')}</p>
                                    {isUser && (
                                        <p>{t('CreateFirstPublicCharacter')}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="character-page-public-chats-container"
                         style={{marginTop: publicMemories.length > 0 && isMobile ? '40px' : '0' }}
                    >

                        {publicMemories.length > 0 &&
                            <h3>{isUser ? t('YourMemories') : t('PublicMemories')}</h3>
                        }

                        {!isMobile ? (
                            <>
                                <PublicMemoriesUserDesktop
                                    userData={userData}
                                    setIsUserLoggedIn={setIsUserLoggedIn}
                                    tagName={tagName}
                                    publicMemories={publicMemories}
                                    setPublicMemories={setPublicMemories}
                                />
                            </>
                        ) : (
                            <PublicMemoriesUserMobile
                                userData={userData}
                                setIsUserLoggedIn={setIsUserLoggedIn}
                                tagName={tagName}
                                publicMemories={publicMemories}
                                setPublicMemories={setPublicMemories}
                            />
                        )}


                    </div>
                </div>
            </div>

            <BioChangePopup
                isOpen={isEditingBio}
                onClose={() => setIsEditingBio(false)}
                newBio={editedBio}
                setNewBio={setEditedBio}
                onSave={handleSaveBio}
            />

            <PleaseUpdateBio
                isOpen={pleaseUpdateBioModalOpen}
                onClose={() => setIsPleaseUpdateBioModalOpen(false)}
                onStartBioEdit={() => setIsEditingBio(true)}
            />

            <UniversalFilterModal
                isOpen={isWarningModalOpen}
                onClose={() => setIsWarningModalOpen(false)}
                message={errorMessage}
            />
            <ScrollToTop />

            <MobileNavigation/>

        </>
    );
};

export default UserPage;