import React, {useEffect, useState} from "react";
import {NewPricingContainer} from "../components/MainMenuPage/PricingPage/NewPricingContainer";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {ReactComponent as CreditCardIcon} from "../icons/credit-card.svg";
import {ReactComponent as CryptoIcon} from "../icons/cryptoIcon.svg";
import {ReactComponent as ElyzaCoinIcon} from "../icons/elyzaCoinIcon.svg";
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {getUserAccessToken, getUserEmail, userIDExists} from "../App";
import AnimeGirl from "../components/Banners/BannerIcons/anime1.png";
import GirlReal from "../components/Banners/BannerIcons/real1.png";
import TopTimer from "../components/Notifications/TopBanners/TopTimer";
import {useTimer} from "../components/Context/TimerContext";
import {AuthModal} from "../components/Forms/AuthModal";
import {useProfileData} from "../components/Context/ProfileDataContext";
import "./MicroTransactions.css"
import {A11y, Navigation} from "swiper/modules";
import {useTranslation} from "react-i18next";
import {usePageTracking} from "../components/Analytics/UserAnalyticsContext";
import {MobileNavigation} from "../components/MainMenuPage/MobileNavigation";
import AutomaticPurchaseModal from "../components/Notifications/AutomaticPurchaseModal";
import EpicLoadingScreen from "../components/LoadingEvents/EpicLoadingScreen";
import MicroSuccess from "../components/Notifications/MicroTransCongrats";
import FailToCharge from "../components/Notifications/FailToCharge";

const MicroTransactionPage = () => {
    usePageTracking('micro_transactions');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isWide, setIsWide] = useState(window.innerWidth > 680);

    const [selectedTop, setSelectedTop] = useState(1);
    const {timerExpired} = useTimer();
    const {profileData} = useProfileData();

    const [paymentMethodExists, setPaymentMethodExists] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [isAutomaticPurchaseOpen, setIsAutomaticPurchaseOpen] = useState(false);

    useEffect(() => {
        const checkPaymentMethod = async () => {
            try {

                const response = await fetch('https://api.elyza.app/v1/instant-transaction', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({
                        stage: 1
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to check payment method');
                }

                const data = await response.json();
                setPaymentMethodExists(data.exists);
                setPaymentMethod(data.cardDetails);

                // Optional: Handle case where no payment method exists
                if (!data.exists) {
                    // Handle no payment method case
                    // console.log('No payment method found');
                }

            } catch (err) {
                console.error('Error checking payment method:', err);
            }
        };

        checkPaymentMethod();
    }, []); // Empty dependency array for first render only


    // TODO: If not null, keep checking for like 45s to see if it goes through
    const [invoiceId, setInvoiceId] = useState(null);
    const [invoiceConfirmationLoading, setInvoiceConfirmationLoading] = useState(false);
    const [isTransactionFailed, setIsTransactionFailed] = useState(false);

    const confirmTransaction = async (selectedItem) => {
        try {
            setInvoiceConfirmationLoading(true);
            setIsAutomaticPurchaseOpen(false);
            const response = await fetch('https://api.elyza.app/v1/instant-transaction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    stage: 2,
                    item: selectedItem,
                    itemCount: 1
                })
            });

            if (!response.ok) {
                throw new Error('Failed to confirm transaction');
            }

            const data = await response.json();

            if (data.status === true) {
                setInvoiceId(data.invoiceId);
                setInvoiceStatus(false);
                setInvoiceConfirmationLoading(true);
                // console.log('Transaction successful');
            } else {
                // Transaction failed
                console.log('Transaction failed');
            }
        } catch (err) {
            console.error('Error confirming transaction:', err);
        }
    }

    const [invoiceStatus, setInvoiceStatus] = useState(null);

    const checkInvoiceStatus = async () => {
        // TODO: Have an in-built 45s limit here
        if (invoiceStatus === false && invoiceConfirmationLoading === true) {
            try {
                const response = await fetch('https://api.elyza.app/v1/instant-transaction', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({
                        stage: 3,
                        invoiceId: invoiceId
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to check invoice status');
                }

                const data = await response.json();

                if (data.status === true) {
                    setInvoiceStatus(true);
                    setInvoiceConfirmationLoading(false);
                    // console.log('Invoice paid successfully');
                    return true;
                } else {
                    console.log('Invoice not paid');
                    return false;
                }
            } catch (err) {
                console.error('Error checking invoice status:', err);
            }
        }
    }

    const [successInstantTransaction, setSuccessInstantTransaction] = useState(false);

    useEffect(() => {
        let timeoutId;
        let attempts = 0;
        const maxAttempts = 45;

        const checkStatus = async () => {
            if (invoiceConfirmationLoading && !invoiceStatus && attempts < maxAttempts) {
                const success = await checkInvoiceStatus();

                if (success) {
                    // Payment successful
                    setInvoiceConfirmationLoading(false);
                    setSuccessInstantTransaction(true);
                } else {
                    attempts++;
                    if (attempts >= maxAttempts) {
                        // Max attempts reached, show failure
                        setInvoiceConfirmationLoading(false);
                        setIsTransactionFailed(true);
                        // console.log('Payment verification timeout');
                    } else {
                        // Continue checking
                        timeoutId = setTimeout(checkStatus, 1000);
                    }
                }
            }
        };

        if (invoiceConfirmationLoading) {
            checkStatus();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
        //eslint-disable-next-line
    }, [invoiceConfirmationLoading, invoiceStatus]);

    const {t} = useTranslation();

    const toggleTopSelection = (index) => {
        setSelectedTop(index); // Select new option
    };

    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleViewMorePlans = () => {
        window.location.href = '/pricing';
    };

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    const placeholderPlans = [
        {
            heading: `${t('premiumPlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('maximumMemory')}`,
                `${t('hundredAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('earlyAccessNewFeatures')}`,
                `${t('dynamicScenario')}`,

            ]
        },
        {
            heading: `${t('ultimatePlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('ultimateMemory')}`,
                `${t('fiveHundredAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('earlyAccessNewFeatures')}`,
                `${t('dynamicScenario')}`,

            ]
        },
        {
            heading: `${t('basicPlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('betterMemory')}`,
                `${t('fiftyAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,

            ]
        },
        {
            heading: `${t('freePlanHeading')}`,
            features: [
                `${t('unlimitedFreeModelAccess')}`,
                `${t('basicChatModel')}`,
                `${t('createYourOwnCharacters')}`,
                `${t('basicMemory')}`,
                `${t('nsfwContent')}`,
                `${t('premiumFeatures')}`,
                `${t('premiumRewards')}`,
            ]
        }
    ];

    const MicroTransOptions = [
        {
            heading: '50',
            price: '$9.99',
            numericPrice: 9.99,
            itemId: '50_tokens-USD',
            // itemId: 'test_charge-USD',
            cryptoItemId: '9e07f839-ab40-453a-b8c6-ba90bc35a3bf',
        },
        {
            heading: '135',
            bonusPercentage: '+10%',
            price: '$24.99',
            numericPrice: 24.99,
            itemId: '135_tokens-USD',
            cryptoItemId: '072667cd-cafa-4b49-a2bd-26efb474a8b0',
        },
        {
            heading: '300',
            bonusPercentage: '+20%',
            price: '$49.99',
            numericPrice: 49.99,
            itemId: '300_tokens-USD',
            cryptoItemId: '40bf319c-818e-426e-b374-8181fbe0bf7c',
        },
        {
            heading: '700',
            bonusPercentage: '+25%',
            price: '$99.99',
            numericPrice: 99.99,
            itemId: '700_tokens-USD',
            cryptoItemId: '4d4abcc6-3090-474e-826f-4fb4b3df2c51',
        },
        {
            heading: '1200',
            bonusPercentage: '+40%',
            price: '$149.99',
            numericPrice: 149.99,
            itemId: '1200_tokens-USD',
            cryptoItemId: '58efbe61-8d1a-4d87-8643-5fc98fd4f789'
        },
        {
            heading: '2200',
            bonusPercentage: '+50%',
            price: '$249.99',
            numericPrice: 249.99,
            itemId: '2200_tokens-USD',
            cryptoItemId: '47a4dbc6-2545-44be-a571-0cc4a5f177af'
        },
    ];

    function generateCheckoutURL(firstItemPriceId = '', secondItemPriceId = '') {
        const baseURL = 'https://elyza.chargebee.com/hosted_pages/checkout';
        const layout = 'full_page';
        let url = `${baseURL}?subscription_items[item_price_id][0]=elyza_addon-USD-Monthly&subscription_items[quantity][0]=1`;

        if (firstItemPriceId.length > 0) {
            url += `&subscription_items[item_price_id][1]=${encodeURIComponent(firstItemPriceId)}&subscription_items[quantity][1]=1`;
        }

        if (secondItemPriceId.length > 0) {
            url += `&subscription_items[item_price_id][2]=${encodeURIComponent(secondItemPriceId)}&subscription_items[quantity][2]=1`;
        }

        url += `&layout=${layout}`;
        return url;
    }

    function generateCryptoCheckoutURL(itemId) {
        const baseURL = 'https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad';
        return `${baseURL}/${itemId}`;
    }

    async function handlePayment(isCrypto) {
        let firstItem = '';
        const loggedIn = await validateIsLoggedIn();


        if (!isCrypto) {
            if (selectedTop !== null) {
                firstItem = MicroTransOptions[selectedTop].itemId;
            }

            // else if (isBonusOfferSelected && bonusOffer !== null) {
            //     firstItem = bonusOffer.itemId;
            // }

            const url = generateCheckoutURL(firstItem);

            if (loggedIn) {
                setIsAuthModalOpen(false);
                if (paymentMethodExists) {
                    if (paymentMethodExists && paymentMethod !== null) {
                        setIsAutomaticPurchaseOpen(true);
                    }
                } else {
                    window.location.href = url + `&customer[email]=${encodeURIComponent(await getUserEmail())}`;
                }
            } else {
                setIsAuthModalOpen(true);
            }
        } else {
            if (selectedTop !== null) {
                firstItem = MicroTransOptions[selectedTop].cryptoItemId;
            }
            // else if (isBonusOfferSelectedx && bonusOffer !== null) {
            //     firstItem = bonusOffer.cryptoItemId;
            // }

            const url = generateCryptoCheckoutURL(firstItem);

            if (loggedIn) {
                setIsAuthModalOpen(false);
                window.location.href = url + `?email=${encodeURIComponent(await getUserEmail())}`;
            } else {
                setIsAuthModalOpen(true);
            }
        }
    }

    const handleRegularTransaction = async () => {
        const baseURL = 'https://elyza.chargebee.com/hosted_pages/checkout';
        const layout = 'full_page';

        try {
            const firstItem = MicroTransOptions[selectedTop]?.itemId || '';
            const url = `${baseURL}?subscription_items[item_price_id][0]=elyza_addon-USD-Monthly&subscription_items[quantity][0]=1${
                firstItem ? `&subscription_items[item_price_id][1]=${encodeURIComponent(firstItem)}&subscription_items[quantity][1]=1` : ''
            }&layout=${layout}`;

            const userEmail = await getUserEmail();
            setIsAutomaticPurchaseOpen(false);

            // Navigate to Chargebee checkout with user's email
            window.location.href = url + `&customer[email]=${encodeURIComponent(userEmail)}`;
        } catch (error) {
            console.error('Error redirecting to regular checkout:', error);
        }
    };

    let paymentMethodBrand = paymentMethod?.brand ?? 'mastercard';

    return (
        <>
            <EpicLoadingScreen
                isLoading={invoiceConfirmationLoading}
                isAddonModal={true}
            />

            <FailToCharge
                isOpen={isTransactionFailed}
                onClose={() => setIsTransactionFailed(false)}
            />

            <MicroSuccess
                isOpen={successInstantTransaction}
                onClose={() => setSuccessInstantTransaction(false)}
                tokens={MicroTransOptions[selectedTop].heading}
            />

            <HeaderMainMenu setSearchText={() => {
            }} searchText={''}/>

            <AutomaticPurchaseModal
                isOpen={isAutomaticPurchaseOpen}
                onClose={() => setIsAutomaticPurchaseOpen(false)}
                handleInstantTransaction={() => confirmTransaction(MicroTransOptions[selectedTop].itemId)}
                tokens={MicroTransOptions[selectedTop].heading}
                cost={MicroTransOptions[selectedTop].numericPrice}
                handleRegularTransaction={handleRegularTransaction}
                brand={paymentMethodBrand}
            />

            {
                isAuthModalOpen && (
                    <AuthModal
                        isOpen={isAuthModalOpen}
                        onClose={() => setIsAuthModalOpen(false)}
                    />
                )
            }

            {!timerExpired ? (
                <div className="top-timer-container-main-pricing"
                     style={{display: timerExpired !== false ? "none" : 'block'}}
                >
                    <TopTimer/>
                </div>
            ) : null}

            <div className="new-pricing-page-container">
                {isWide && (
                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "40px" : '80px'}}
                    >
                        <div className="new-toggle-pricing">
                            <div className="new-pricing-subtitle-microtrans">
                                <h2>{t('WantMoreGetMore')}</h2>
                                <span>{t('GetMoreTokensWith')}</span>
                                <span className="highlight-text-price">{t('UpTo40Bonus')}</span>
                                <br></br>
                                <span>{t('MoreYouBuyBiggerSavings')}</span>
                            </div>
                            <div className='select-container-microtrans'>
                                {MicroTransOptions.slice(0, 6).map((option, index) => (
                                    <div className={`microtrans-option ${selectedTop === index ? 'selected' : ''}`}
                                         key={index}
                                         onClick={() => {
                                             // setIsBonusOfferSelected(false);
                                             toggleTopSelection(index);
                                         }}>
                                        {option.bonusPercentage && (
                                            <div className='microtrans-option-bonus'>
                                                <div className='bonus-oval'>
                                                    <h4>{option.bonusPercentage} bonus</h4>
                                                </div>
                                            </div>
                                        )}
                                        <div className='microtrans-option-header'>
                                            <span className='plan-heading-microtrans'>{option.heading}
                                                <ElyzaCoinIcon/>
                                            </span>
                                            <span className='plan-subheading-microtrans'>{option.price}</span>
                                        </div>
                                        <div className='microtrans-option-features'>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="button-buy-addon-container">
                                <div
                                    className={`buy-right-now-button-microtrans`}
                                    onClick={() => handlePayment(false)}>
                                    <CreditCardIcon/>
                                    {t('PayWithCard')}
                                </div>
                                <div
                                    className={`buy-right-now-button-microtrans-crypto`}
                                    onClick={() => handlePayment(true)}>
                                    <CryptoIcon/>
                                    {t('PayWithCrypto')}
                                </div>
                            </div>

                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                {!isWide && (

                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "40px" : '100px', gap: '20px'}}
                    >
                        <div className="new-toggle-pricing">
                            <div className="left-padding-text-container">
                                <div className="new-pricing-subtitle-microtrans">
                                    <h2>{t('WantMoreGetMore')}</h2>
                                    <span>{t('GetMoreTokensWith')}</span>
                                    <span className="highlight-text-price">{t('UpTo40Bonus')}</span>
                                    <br></br>
                                    <span>{t('MoreYouBuyBiggerSavings')}</span>
                                </div>
                            </div>
                            <div className="card-container-main-premium">
                                <div className="pricing-swiper-component-microtrans">
                                    <>
                                        <Swiper modules={[Navigation, A11y]}
                                                slidesPerView={3}
                                                centeredSlides={true}
                                                loop={false}
                                                initialSlide={1}
                                        >
                                            {MicroTransOptions.slice(0, 3).map((option, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className='slide-option-mobile-micro'>

                                                        <div
                                                            className={`microtrans-option ${selectedTop === index ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                // setIsBonusOfferSelected(false);
                                                                toggleTopSelection(index);
                                                            }}>
                                                            {option.bonusPercentage && (
                                                                <div className='microtrans-option-bonus'>
                                                                    <div className='bonus-oval'>
                                                                        <h4>{option.bonusPercentage} bonus</h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <span
                                                                className='plan-heading-microtrans'>{option.heading}
                                                                <ElyzaCoinIcon/>
                                                            </span>
                                                            <span
                                                                className='plan-subheading-microtrans'>{option.price}</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                </div>
                                <div className="pricing-swiper-component-microtrans">
                                    <>
                                        <Swiper modules={[Navigation, A11y]}
                                                slidesPerView={3}
                                                centeredSlides={true}
                                                loop={false}
                                                initialSlide={1}
                                        >
                                            {MicroTransOptions.slice(3, 6).map((option, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className='slide-option-mobile-micro'>

                                                        <div
                                                            className={`microtrans-option ${selectedTop === (index + 3) ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                toggleTopSelection(index + 3);
                                                            }}>
                                                            {option.bonusPercentage && (
                                                                <div className='microtrans-option-bonus'>
                                                                    <div className='bonus-oval'>
                                                                        <h4>{option.bonusPercentage} bonus</h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <span
                                                                className='plan-heading-microtrans'>{option.heading}
                                                                <ElyzaCoinIcon/>
                                                            </span>
                                                            <span
                                                                className='plan-subheading-microtrans'>{option.price}</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="button-buy-addon-container">
                            <div
                                className={`buy-right-now-button-microtrans`}
                                onClick={() => handlePayment(false)}>
                                <CreditCardIcon/>
                                {t('PayWithCard')}
                            </div>
                            <div
                                className={`buy-right-now-button-microtrans-crypto`}
                                onClick={() => handlePayment(true)}>
                                <CryptoIcon/>
                                {t('PayWithCrypto')}
                            </div>
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}
                <div className="bottom-of-the-page-container"
                     style={{paddingBottom: isWide ? "20px" : "100px"}}
                >
                    <div className="left-padding-text-container">
                        <h2>{t('CurrentPlan')}</h2>
                        {profileData.subscription_data.tier !== 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                        <span>{t('PrettyNeat')}<br/>{t('YoureMissingOut')} <span
                                            className="highlight-text-price">{t('BunchOfStuff')}</span></span>

                                    <div className="buy-right-now-button-credit" onClick={handleViewMorePlans}>
                                        {t('ViewOtherPlans')}
                                    </div>


                                </div>
                            </>
                        )}
                        {profileData.subscription_data.tier === 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>Thank you for using <span
                                    className="highlight-text-price">Elyza.</span></span>
                                </div>
                            </>
                        )}
                    </div>

                    {profileData.subscription_data.tier === 'GOLD' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === t('ultimatePlanHeading'))
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'PREMIUM' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === t('premiumPlanHeading'))
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'BASIC' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === t('basicPlanHeading'))
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'FREE' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === t('freePlanHeading'))
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    <img src={AnimeGirl} alt="Anime Girl" className="anime-girl"
                         draggable="false"/>

                </div>
            </div>

            <MobileNavigation/>


            {/*{!isRegularButtonVisible &&*/}
            {/*<div className={'buy-right-now-button-mobile'}*/}
            {/*     onClick={() => handlePayment(false)}>*/}
            {/*    <CreditCardIcon/>*/}
            {/*    {t('PayWithCard')}*/}
            {/*</div>*/}
            {/*}*/}
        </>
    );
};

export default MicroTransactionPage;


