import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as DiscordIcon} from '../../../icons/discord.svg';
import {ReactComponent as TwitterIcon} from '../../../icons/twitter.svg';
import {ReactComponent as GoogleIcon} from '../../../icons/google.svg';
import {ReactComponent as CloseModalIcon} from '../../../icons/cross.svg';
import {Turnstile} from '@marsidev/react-turnstile'
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {getUserAccessToken, supabase} from "../../../App";
import '../../../components/Forms/AuthModal.css'
import {useFunnelCharacterContext} from "../SalesCharCreator/FunnelContext";

export const SimplifiedSignUp = ({isOpen, onClose, initialUsername = 'Guest', onComplete}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [captchaToken, setCaptchaToken] = useState()
    const captchaRef = useRef();
    const [name, setName] = useState(initialUsername || 'Guest');
    const { funnelCharData } = useFunnelCharacterContext();

    const generateUsernameFromEmail = (email) => {
        const username = email.split('@')[0];
        // Clean up the username and ensure uniqueness
        return username
                .replace(/[^a-zA-Z0-9]/g, '') // Remove special characters
                .replace(/\s+/g, '_') // Replace spaces with underscore
                .slice(0, 15) // Limit length
            + Math.floor(Math.random() * 1000); // Add random number for uniqueness
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (!name || name === initialUsername) {
            setName(generateUsernameFromEmail(newEmail));
        }
    };

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    }

    const isValidEmail = (email) => {
        return /^[^\s@+]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPassword = (password) => {
        const minLength = 8;

        return password.length >= minLength;
    };

    const handleSignUp = async (event) => {
        event.preventDefault();

        if (!email || !isValidEmail(email)) {
            setEmailError(true);
            setError("Please enter a valid email address.");
            setCaptchaToken(null);
            return;
        } else {
            setEmailError(false);
        }

        if (!password || !isValidPassword(password)) {
            setPasswordError(true);

            setError("Password must be at least 8 characters long!");
            setCaptchaToken(null);
            return;
        } else {
            setPasswordError(false);
        }

        window.rdt('track', 'SignUp');

        setError("");
        setIsLoading(true);
        try {

            const response = await supabase.auth.signUp({
                email, password, options: {captchaToken}
            });
//TODO - Add user gender preference into storage
            if (response.error) {
                setError(response.error.message);
                setCaptchaToken(null);
                return;
            } else {
                if (response.data.user) {
                    const id = response.data.user.id;
                    await fetch('https://api.elyza.app/v1/update-profile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': await getUserAccessToken()
                        },
                        body: JSON.stringify({
                            userId: id,
                            imageBase64: "",
                            newUsername: name.length > 0 ? name : "Guest",
                            // newBio: "",
                            genderPreference: localStorage.getItem('genderPreference') ?? "Female",
                            justRegistered: true,
                            affCode: localStorage.getItem('affCode') ?? "",
                            referralCode: localStorage.getItem('referralCode') ?? "",
                            campaignCode: localStorage.getItem('campaignCode') ?? "",
                            targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                            language: languageCodeToEnglishName[i18n.language] ?? "English",
                        })
                    });
                    onClose();
                    // onComplete()
                    // window.location.reload();
                }
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
            onComplete(true)
        }
    }

    const handleSocialSignIn = async (provider) => {
        try {
            // Persist funnel data before social sign-in
            localStorage.setItem('funnelCharacterData', JSON.stringify(funnelCharData));

            const { data, error } = await supabase.auth.signInWithOAuth({
                provider,
                options: {
                    redirectTo: `${window.location}`,
                    data: {
                        funnelData: funnelCharData  // Store funnel data in auth metadata
                    }
                }
            });

            if (error) throw error;

            if (data) {
                localStorage.setItem('isLoggedIn', 'true');
                if (typeof onComplete === 'function') {
                    onComplete(true);
                }
            }
        } catch (error) {
            console.error(`${provider} sign-in error:`, error);
            if (typeof onComplete === 'function') {
                onComplete(false);
            }
        }
    };

    const handleGoogleSignIn = () => handleSocialSignIn('google');
    const handleDiscordSignIn = () => handleSocialSignIn('discord');

    const handleTwitterSignIn = () => handleSocialSignIn('twitter');


    const AuthRef = useRef(null);

    useEffect(() => {
        // Function to call onClose if the click is outside the modal
        const handleClickOutside = (event) => {
            if (AuthRef.current && !AuthRef.current.contains(event.target)) {
                onClose(); // Call your onClose function if the click is outside
            }
        };

        // Attach the event listener to the document
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener from the document when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const { t } = useTranslation();

    const capitalizeFirstLetters = (str) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    if (!isOpen) return null;

    return (
        <div className="profile-edit-overlay">
            <div className="auth-modal" ref={AuthRef}>
                <div className="auth-container">
                        <>
                            <div className="signup-form">
                                <div className="main-upper-text-row">
                                    <div className="main-elyza-text-label"
                                         style={{textTransform: "capitalize"}}
                                    >
                                        {capitalizeFirstLetters(t("signUpToSeeMore"))}
                                    </div>
                                    <div className="close-modal-icon" onClick={onClose}><CloseModalIcon/></div>

                                </div>
                                <div className="main-elyza-text-sublabel">Don't worry! Your progress will be saved!</div>

                                <div className="a-straight-line"
                                     style={{backgroundColor: "#c4c4c4"}}
                                ></div>


                                <div className="social-signup-container">

                                    <div className="double-social-signup-button">
                                        <button className="social-signup-button twitter" onClick={handleTwitterSignIn}>
                                            <TwitterIcon className="social-signup-button-icon-discord"/>
                                            <span className="social-signup-button-text"></span>
                                        </button>
                                        <button className="social-signup-button discord" onClick={handleDiscordSignIn}>
                                            <DiscordIcon className="social-signup-button-icon-discord"/>
                                            <span className="social-signup-button-text">{t('LoginWithDiscord')}</span>
                                        </button>
                                    </div>

                                    <button className="social-signup-button google" onClick={handleGoogleSignIn}>
                                        <GoogleIcon className="social-signup-button-icon-google"/>
                                        <span className="social-signup-button-text">{t('SignUpWithGoogle')}</span>
                                    </button>

                                </div>
                                <form onSubmit={handleSignUp}>
                                    <div className="or-container">
                                        <div className="line"></div>
                                        <span className="or-label">{t('OR')}</span>
                                        <div className="line"></div>
                                    </div>

                                    {/*<div className={`textbox-name`}>*/}
                                    {/*    <label className="label-sign-up-h">{t('Username')}</label>*/}

                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        value={name}*/}
                                    {/*        onChange={(e) => setName(e.target.value)}*/}
                                    {/*        placeholder="Username"*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <div className={`textbox-email ${emailError ? 'shake-animation' : ''}`}>
                                        <label className="label-sign-up-h">{t('Email')}</label>

                                        <input
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="example@gmail.com"
                                        />
                                    </div>

                                    <div className={`textbox-password ${passwordError ? 'shake-animation' : ''}`}>
                                        <label className="label-sign-up-h">{t('Password')}</label>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="●●●●●●●●●"  // Added placeholder attribute
                                        />
                                    </div>

                                    <button className='signup-button' onClick={handleSignUp}
                                            disabled={!name.length || !email.length || !password.length || isLoading || !captchaToken}>
                                        {t('SignUp')}
                                    </button>

                                    {!captchaToken ?
                                        <Turnstile
                                            ref={captchaRef}
                                            style={{
                                                display: "none",
                                            }}
                                            siteKey="0x4AAAAAAAWnS3GcUrv9riNn"
                                            onSuccess={(token) => {
                                                setCaptchaToken(token);
                                            }}
                                        /> : null
                                    }

                                    {error && <div className="error-message">{error}</div>}
                                    <div className="tos">{t('TOSMessage')} <Link to="/legal/terms-of-use"
                                                                                 className="underline">{t('TermsOfUse')}</Link>
                                    </div>

                                    {/*{isLoading && <div>Loading...</div>}*/}
                                </form>
                            </div>
                        </>
                </div>
            </div>
        </div>
    );
};