import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../../FunnelContext";

// Anime Images
import AnimeFCurly from "./src/hairStyles/universal/anime/AnimeFCurly.jfif";
import AnimeFShort from "./src/hairStyles/universal/anime/AnimeFShort.jfif";
import AnimeFStraight from "./src/hairStyles/universal/anime/AnimeFStraight.jfif";

// Real Images
import RealFCurly from "./src/hairStyles/universal/real/RealFCurly.jfif";
import RealFShort from "./src/hairStyles/universal/real/RealFShort.jfif";
import RealFStraight from "./src/hairStyles/universal/real/RealFStraight.jfif";

const TransStep2 = () => {
    const {isAnime, funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedHairStyle, setSelectedHairStyle] = useState(funnelCharData.hairStyle || 'Blonde');
    const [selectedHairColor, setSelectedHairColor] = useState(funnelCharData.hairColor || 'Curly');
    const { t } = useTranslation();

    const hairDetail = {
        hairStyle: [
            { name: 'Curly', image: isAnime ? AnimeFCurly : RealFCurly },
            { name: 'Short', image: isAnime ? AnimeFShort : RealFShort },
            { name: 'Long', image: isAnime ? AnimeFStraight : RealFStraight },
        ],
        hairColor: [
            { name: 'Blonde', color: '#d9b380' },  // Pale golden blonde
            { name: 'Black', color: '#2C2C2C' },   // Jet black with slight natural highlights
            { name: 'Brown', color: '#6d4730' },   // Medium brown
            { name: 'Pink', color: '#e27589' },
        ],
    };

    const handleColorSelect = (color) => {
        setSelectedHairColor(color);
        updateCharacterData({ hairColor: color });
    };

    const handleStyleSelect = (style) => {
        setSelectedHairStyle(style);
        updateCharacterData({ hairStyle: style });
    };

    return (
        <div className="general-multiple-options-container" style={{marginTop: '0'}}>
            <span className="funnel-creation-label">{t('tSelectHairStyle')}</span>
            <div className="top-row">
                {hairDetail.hairStyle.map((part, index) => (
                    <div key={`style-${part.name}-${index}`} className="general-option-wrapper">
                        <div
                            className={`general-option-creator-multiple-items ${selectedHairStyle === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleStyleSelect(part.name)}
                        >
                            <img
                                src={part.image}
                                alt={part.name}
                                className={`general-image-creator ${selectedHairStyle === part.name ? 'selected' : ''}`}
                                style={{pointerEvents: 'none'}}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <span className="funnel-creation-label">{t('tSelectHairColor')}</span>
            <div className="bottom-row-color">
                {hairDetail.hairColor.map((part, index) => (
                    <div key={`color-${part.name}-${index}`} className="general-option-wrapper">
                        <div
                            className={`general-option-creator-multiple-items ${selectedHairColor === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleColorSelect(part.name)}
                        >
                            <div
                                className="color-box"
                                style={{
                                    backgroundColor: part.color,
                                }}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransStep2;