import React, {useEffect, useRef, useState} from 'react';
import './CharacterCard.css';
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import WaveSurferCharSample from "./IconsSub/WaveSurferCharSample";
import {fetchCharacterCardDetails} from "./CharacterCardFunctions";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {GirlfriendButton} from "./GirlfriendButton";
import PublicChats from "./Components/PublicChatMemoriesDesktop";
import PublicChatsMobile from "./Components/PublicChatMemoriesMobile";
import SimilarCharacters from "./Components/SimilarCharacters";
import ExpandableDescriptionDesktop from "./Components/ExpandableDescriptionDesktop";
import {ReactComponent as ReportIcon} from "../UserPage/icons/reportIcon.svg";
import {ReactComponent as ThreeDotsIcon} from "./IconsSub/threeDots.svg"
import TagSystemCharacterCard from "./IconsSub/TagSystemCharacterCard";
import Tooltip from "@mui/material/Tooltip";
import {getUserAccessToken} from "../../App";
import CharacterScreenLoader from "../../components/LoadingEvents/CharacterLoader/CharacterLoader";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TopTimer from "../../components/Notifications/TopBanners/TopTimer";
import {useTimer} from "../../components/Context/TimerContext";
import SignUpToUseGeneral from "../../components/Notifications/SignUpToUseGeneral";
import ExpandableDescriptionMobile from "./Components/ExpandableDescriptionMobile";
import i18n from "i18next";
import ScrollToTop from "./Components/ScrollToTop";
import {usePageTracking} from "../../components/Analytics/UserAnalyticsContext";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";

const CharacterPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {profileData} = useProfileData();
    const [characterName, setCharacterName] = useState('');
    const [characterDescription, setCharacterDescription] = useState('');
    const [characterVoiceSample, setCharacterVoiceSample] = useState(null);
    const [characterScenario, setCharacterScenario] = useState('');
    const [tag, setTag] = useState([]);
    const [identityData, setIdentityData] = useState(null);
    const [translatedContent, setTranslatedContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const identityName = window.location.pathname.substring(3);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [profileImage, setIsProfileImage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {timerExpired} = useTimer();
    const [publicChatPublicMessages, setPublicChatPublicMessages] = useState([]);
    const {t} = useTranslation();
    const currentLanguage = i18n.language;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 720);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to get translated content based on user's language
    const getTranslatedContent = (data) => {
        if (data?.preferred_language === 'EN+' && data?.translations) {
            const userLang = currentLanguage.toUpperCase();
            if (data.translations[userLang]) {
                return {
                    displayName: data.translations[userLang].display_name || data.displayName,
                    description: data.translations[userLang].description || data.description,
                    scenario: data.translations[userLang].scenario || data.scenario
                };
            }
        }
        return {
            displayName: data?.displayName,
            description: data?.description,
            scenario: data?.scenario
        };
    };

    const replacePlaceholders = (text) => {
        if (!text) return '';

        const username = profileData?.username || '';
        const charName = translatedContent?.displayName || identityData?.displayName || '';

        return text
            // Replace user placeholders
            .replace(/\{\{user\}\}/gi, username)
            .replace(/\{user\}/gi, username)
            .replace(/\{\{User\}\}/g, username.charAt(0).toUpperCase() + username.slice(1))
            .replace(/\{User\}/g, username.charAt(0).toUpperCase() + username.slice(1))
            // Replace character placeholders
            .replace(/\{\{char\}\}/gi, charName)
            .replace(/\{char\}/gi, charName)
            .replace(/\{\{Char\}\}/g, charName.charAt(0).toUpperCase() + charName.slice(1))
            .replace(/\{Char\}/g, charName.charAt(0).toUpperCase() + charName.slice(1));
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const data = await fetchCharacterCardDetails(identityName);
                setIdentityData(data);
                const translated = getTranslatedContent(data);
                setTranslatedContent(translated);
            } catch (err) {
                if (err.message === "Failed to fetch identity data") {
                    setError("This Character Does Not Exist");
                } else {
                    setError("An error occurred while fetching user data");
                    console.log(err);
                }
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [identityName, currentLanguage]);

    const allowedTags = ['Anime', 'Real', 'Furry', 'Gay', 'Lesbian', 'Bisexual', 'Femboy', 'Futanari', 'Tomboy', 'Goth', 'Family', 'YouTuber', 'VTuber', 'NonEnlish', 'Manga', 'Videogames', 'Cartoon', 'Teenager', 'School', 'Girlfriend', 'Wholesome', 'Romantic', 'Cute', 'Drama', 'Childhood Friend', 'Wife', 'BDSM', 'Femdom', 'NonConsensual', 'MILF', 'Chubby', 'Original', 'MindControl', 'NonHuman', 'SizeDifference', 'Historical', 'Kink'];
    const filterTags = (tags) => tags.filter(tag => allowedTags.includes(tag));

    useEffect(() => {
        if (identityData && translatedContent) {
            setIsProfileImage(identityData.profile || '');
            setCharacterName(translatedContent.displayName || '');
            setTag(filterTags(identityData.tags || []));
            setCharacterDescription(translatedContent.description || '');
            setCharacterScenario(translatedContent.scenario || '');
            setCharacterVoiceSample(identityData.audio || '');
        }

        if (profileData && profileData.favorites) {
            setIsFavorite(profileData.favorites.includes(identityName) || '');
        }
        // eslint-disable-next-line
    }, [identityData, profileData, identityName, translatedContent]);

    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);
    const threeDotsRef = useRef(null);

    usePageTracking('character_card');

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target) &&
                threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const togglePopup = () => {
        setShowPopup(prevState => !prevState);
    };

    const Popup = () => (
        <div ref={popupRef} className="username-popup">
            <button className="report-button-insider"
                    onClick={() => {
                        reportIdentity().then(() => {
                        });
                        togglePopup();
                    }}>
                {t('Report')}
                <ReportIcon/>
            </button>
            {identityData.creator !== null && (
                <button className="created-by-button"
                        onClick={() => navigate(`/user/${identityData.creator}`)}
                >
                    {t('madeBy')}
                    <br></br> @{identityData.creator}</button>
            )}
        </div>
    );

    const truncateDescription = (description, maxLength = 150) => {
        const processedDescription = replacePlaceholders(description);
        if (processedDescription.length <= maxLength) return processedDescription;
        return processedDescription.slice(0, maxLength - 3) + '...';
    };

    const reportIdentity = async () => {
        await fetch('https://api.elyza.app/v1/report-identity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({
                identityName: identityName,
                message: 'N/A',
            })
        });
    }

    const handleNavigateToMain = () => {
        navigate('/');
    };

    if (error) {
        return (
            <div className="nothing-found">
                <h2 className="nothing-found-message">{error}</h2>
                <button className="save-settings-button"
                        style={{width: '50%'}}
                        onClick={handleNavigateToMain}
                >
                    {t('BackToMainPage')}
                </button>
            </div>
        );
    }

    return (
        <>
            <ScrollToTop />
            <MobileNavigation/>

            {isUserLoggedIn && (
                <SignUpToUseGeneral
                    isOpen={isUserLoggedIn}
                    onClose={() => setIsUserLoggedIn(false)}
                />
            )}

            {!timerExpired ? (
                <div className="top-timer-container-main-pricing"
                     style={{display: timerExpired !== false ? "none" : 'block'}}
                >
                    <TopTimer/>
                </div>
            ) : null}

            {isLoading && (
                <CharacterScreenLoader/>
            )}

            <div className="character-card-page">
                <HeaderMainMenu
                    setSearchText={() => {
                    }} searchText={''}/>

                <div className="character-card-background"></div>

                <div className="character-card-column-unify"
                     style={{marginTop: timerExpired !== false ? '115px' : '185px'}}
                >
                    <div className="character-card-left-column">
                        <div className="character-page-image-container">
                            <img
                                loading="lazy"
                                src={`https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${profileImage}`}
                                alt={''}
                                className="character-page-image"
                                draggable="false"
                            />

                            {!isMobile && !isLoading && (
                                <div className="character-page-main-image-tags">
                                    <TagSystemCharacterCard
                                        tagList={tag}
                                        setTag={setTag}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="character-card-right-column">
                        <div className="object-centralizer-component">
                            <div className="character-card-right-column-button-header">
                                <h2 className="character-page-name">
                                    <span className="character-name-centralizer">
                                        {characterName}
                                        <div className="three-dots-container">
                                            <ThreeDotsIcon
                                                ref={threeDotsRef}
                                                onClick={togglePopup}
                                                style={{cursor: 'pointer'}}
                                            />
                                            {showPopup && <Popup/>}
                                        </div>
                                    </span>
                                </h2>
                                <Tooltip title={replacePlaceholders(characterDescription)} placement="bottom-start"
                                         arrow>
                                    <h4 className="character-page-bio">
                                        {truncateDescription(characterDescription)}
                                    </h4>
                                </Tooltip>
                            </div>
                            {isMobile && !isLoading && (
                                <div className="character-page-main-image-tags">
                                    <TagSystemCharacterCard
                                        tagList={tag}
                                        setTag={setTag}
                                    />
                                </div>
                            )}
                            <GirlfriendButton
                                setIsUserLoggedIn={setIsUserLoggedIn}
                                identityName={identityName}
                                initialIsFavorite={isFavorite}
                            />
                        </div>

                        <div className="audio-sample-character-card">
                            <WaveSurferCharSample audioURL={characterVoiceSample}/>
                        </div>


                        {!isMobile && (
                            <>
                                <h3>{t('aboutMe')}</h3>
                                <h4>
                                    <ExpandableDescriptionDesktop
                                        description={characterScenario}
                                        maxLength={360}
                                        charName={characterName}
                                    />
                                </h4>
                            </>
                        )}

                        {isMobile && (
                            <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
                                <h3>{t('aboutMe')}</h3>
                                <h4>
                                    <ExpandableDescriptionMobile
                                        description={characterScenario}
                                        maxLength={360}
                                        charName={characterName}
                                    />
                                </h4>
                            </div>
                        )}

                    </div>
                </div>

                <div className="lower-half-character-page">
                    {!isMobile && (
                        <div className="similar-characters-main">
                            <h3>{t('similarCharacters')}</h3>
                            <SimilarCharacters
                                identityName={identityName}
                            />
                        </div>
                    )}

                    <div className="character-page-public-chats-container"
                         style={{marginTop: publicChatPublicMessages.length > 0 && isMobile ? '40px' : '0' }}
                    >

                        {publicChatPublicMessages.length > 0 &&
                            <h3>{t('publicChats')}</h3>
                        }

                        {!isMobile ? (
                            <>
                                <PublicChats
                                identityName={identityName}
                                setIsUserLoggedIn={setIsUserLoggedIn}
                                publicChatPublicMessages={publicChatPublicMessages}
                                setPublicChatPublicMessages={setPublicChatPublicMessages}
                            />
                            </>
                        ) : (
                            <PublicChatsMobile
                                identityName={identityName}
                                setIsUserLoggedIn={setIsUserLoggedIn}
                                publicChatPublicMessages={publicChatPublicMessages}
                                setPublicChatPublicMessages={setPublicChatPublicMessages}
                            />
                        )}
                    </div>
                </div>
                {isMobile && (
                    <div className="similar-characters-main">
                        <h3>{t('similarCharacters')}</h3>
                        <SimilarCharacters
                            identityName={identityName}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CharacterPage;