import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import SimpleStepZero from "./Steps/SimpleStepZero";
import SimpleStepTwo from './Steps/SimpleStepTwo';
import SimpleStepThree from "./Steps/SimpleStepThree";
import SimpleStepFour from "./Steps/SimpleStepFour";
import SimpleStepSix from "./Steps/SimpleStepFive";
import SimpleStepFive from "./Steps/SimpleStepSix";
import SimpleStepSeven from "./Steps/SimpleStepSeven";
import SimpleStepEight from "./Steps/SimpleStepEight";
import SimpleStepNine from "./Steps/SimpleStepNine";
import EmailVerification from "../../components/Forms/EmailVerification";
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {getUserAccessToken, getUserID, userIDExists} from "../../App";
import './RealNewCharacterPage.css';
import {ReactComponent as GoForwardIcon} from "./icons/forwardIcon.svg";
import {ReactComponent as GoBackIcon} from "./icons/backIcon.svg";

// import {ReactComponent as ElyzaCoin} from '../../components/RewardScreen/RewardIcons/elyzaCoin.svg';

import SimpleStepOne from "./Steps/SimpleStepOne";
import {useCharacterCreation} from "./CharacterCreationContext";
import {useNavigate} from "react-router-dom";
import LoadingScreen from "../../components/LoadingEvents/EpicLoadingScreen";
import SuccessModal from "./screens/SuccessModal";
import SimpleSelectVoice from "./Steps/SimpleSelectVoice";
import {useTranslation} from "react-i18next";
import SimpleReviewScreen from "../../components/Modals/FinalReviewScreens/SimpleReviewScreen";
import {useTimer} from "../../components/Context/TimerContext";
import TopTimer from "../../components/Notifications/TopBanners/TopTimer";
import CharWarningModalCentralized from "../../components/Modals/CharWarningModalCentralized";
import CreateCharPleaseLogin from "../../components/Modals/CreateCharPleaseLogin";
import {usePageTracking} from "../../components/Analytics/UserAnalyticsContext";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";

const ProgressBar = ({currentStep, totalSteps}) => {
    return (
        <div className="progress-bar-container-char">
            <div className="progress-bar-background"></div>
            <div className="progress-bar-line" style={{width: `${(currentStep) / (totalSteps - 1) * 100}%`}}></div>
            {Array.from({length: totalSteps}).map((_, index) => (
                <div key={index} className={`circle-progress-bar ${index <= currentStep ? 'active' : ''}`}></div>
            ))}
            <div className="progress-user-icon" style={{left: `${(currentStep) / (totalSteps - 1) * 100}%`}}></div>
        </div>
    );
};

const Step = ({
                  component: StepComponent,
                  title,
                  subtitle,
                  index,
                  currentStep,
                  setCurrentStep,
                  scrollToStep,
                  totalSteps,
                  steps, returnToStep,
              }) => {

    //TODO: breaks on char creator

    // const location = useLocation();
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location.pathname]);

    const {language} = useProfileData();

    const mapLanguageCodeToEnglishName = (code) => {
        return languageCodeToEnglishName[code] || code;
    };

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    let correctLanguage = mapLanguageCodeToEnglishName(language);

    // console.log(correctLanguage)

    const {characterData, transformDataForBackend, clearOptions} = useCharacterCreation();

    const [ref, inView] = useInView({
        threshold: 0.1,
        rootMargin: '50px',
        triggerOnce: false
    });

    useEffect(() => {
        if (inView) {
            setCurrentStep(index);
        }
    }, [inView, index, setCurrentStep]);

    useEffect(() => {
        if (inView) {
            requestAnimationFrame(() => {
                setCurrentStep(index);
            });
        }
        //eslint-disable-next-line
    }, [inView]);

    const handleNext = () => {
        if (index < totalSteps - 1) {
            scrollToStep(index + 1);
        }
    };
    const handlePrevious = () => {
        if (index > 0) {
            scrollToStep(index - 1);
        }
    };

    const [showReviewModal, setShowReviewModal] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [identityName, setIdentityName] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [createCharLoginModal, setCreateCharLoginModal] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    const handleCreateCharacter = async () => {

        if (!isLoggedIn) {
            setCreateCharLoginModal(true);
            return;
        }

        try {
            setIsLoading(true);
            const transformedData = transformDataForBackend(characterData);
            const response = await fetch('https://api.elyza.app/v1/simple-character-creator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken(),
                },
                body: JSON.stringify({
                    userSelection: transformedData,
                    charName: characterData.charName,
                    scenario: characterData.scenario,
                    specialSetting: characterData.specialCase,
                    languageSelection: characterData.languageSelection,
                    voiceSelection: characterData.voiceSelection,
                    voiceName: characterData.clonedVoiceName,
                    voiceSample: characterData.clonedVoiceURL
                }),
            });

            const data = await response.json();

            if (response.status === 403) {
                setErrorMessage('Content violates our policies');
                setIsLoading(false);
                setIsWarningModalOpen(true);
                return;
            }

            if (!response.ok) {
                setErrorMessage('General Error - Failed To Create');
                setIsLoading(false);
                setIsWarningModalOpen(true);
                return;
            }

            if (data.identityName) {
                setSuccessModalOpen(true);
                setIdentityName(data.identityName);
            } else {
                setErrorMessage('General Error - No ID returned');
                setIsLoading(false);
                setIsWarningModalOpen(true);
            }
        } catch (error) {
            setErrorMessage('General Error');
            setIsLoading(false);
            setIsWarningModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const navigate = useNavigate();
    const [internalLimit, setInternalLimit] = useState(false);

    const handleStartChatting = async () => {
        if (internalLimit) return;
        setInternalLimit(true);

        try {
            const id = await getUserID();
            if (!identityName) {
                console.error('No identity name available');
                // Handle this case (e.g., show an error message to the user)
                return;
            }

            await fetch('https://api.elyza.app/v1/create-character', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: await getUserAccessToken(),
                },
                body: JSON.stringify({
                    userId: id,
                    identityName: identityName,
                    creatorId: "",
                    language: correctLanguage
                }),
            });

            navigate(`/chat/${identityName}`, {state: {autoSelect: true}});
        } catch (error) {
            console.error('Error starting chat:', error);
        } finally {
            setInternalLimit(false);
            clearOptions();
        }
    };

    const isLastStep = index === totalSteps - 1;
    const {t} = useTranslation();

    const handleSimpleFinalize = () => {
        if (!isLoggedIn) {
            setCreateCharLoginModal(true);
        } else {
            setShowReviewModal(true);
        }
    }

    return (
        <>
            <motion.div
                ref={ref}
                initial={{opacity: 0, y: 0}}
                animate={inView ? {opacity: 1, y: 0} : {opacity: 0, y: -55}}
                transition={{duration: 0.5}}
                className="step-container"
            >
                <div className="character-creation-header">
                    <h1 className="main-title">{t('CharacterCreatorTitle')}</h1>
                    <p className="main-description">{t('CharacterCreatorDescription')}</p>
                </div>

                <ProgressBar currentStep={currentStep} totalSteps={steps.length}/>

                <div className="step-content">
                    <h2 className="step-title">{title}</h2>
                    <h3 className="step-subtitle">{subtitle}</h3>

                    <StepComponent/>

                    <motion.div
                        className="navigation-buttons"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.5}}
                    >
                        <button
                            onClick={handlePrevious}
                            disabled={index === 0}
                            className="nav-button prev-button"
                        >
                            <GoBackIcon/>
                        </button>
                        {isLastStep ? (
                            <button
                                onClick={handleSimpleFinalize}
                                className="nav-button next-button"
                            >
                                <span>{t('Finish')}</span>
                                <GoForwardIcon/>
                            </button>
                        ) : (
                            <button
                                onClick={handleNext}
                                className="nav-button next-button"

                            >
                                {t('Next')}
                                <GoForwardIcon/>
                            </button>
                        )}
                    </motion.div>
                </div>
            </motion.div>

            <SimpleReviewScreen
                isOpen={showReviewModal}
                onClose={() => setShowReviewModal(false)}
                onConfirm={handleCreateCharacter}
                onReturnToStep={returnToStep}
            />

            <LoadingScreen
                isLoading={isLoading}
            />

            <SuccessModal
                isOpen={successModalOpen}
                onClose={() => setSuccessModalOpen(false)}
                characterName={characterData.charName}
                startChatting={handleStartChatting}
                clearSimpleData={clearOptions}
            />

            <CharWarningModalCentralized
                isOpen={isWarningModalOpen}
                onClose={() => setIsWarningModalOpen(false)}
                message={errorMessage}
            />

            <CreateCharPleaseLogin
                isOpen={createCharLoginModal}
                onClose={() => setCreateCharLoginModal(false)}
                characterName={characterData.charName}
            />

        </>
    );
};

const NewCharacterCreation = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const {profileData} = useProfileData();
    const containerRef = useRef(null);
    usePageTracking('simple_creation');
    const {isFemale} = useCharacterCreation();
    const {t} = useTranslation();

    const steps = [
        {
            component: SimpleStepZero,
            title: t('StyleTitle'),
            subtitle: t('StyleSubtitle')
        },
        {
            component: SimpleStepOne,
            title: t('GenderTitle'),
            subtitle: t('GenderSubtitle')
        },
        {
            component: SimpleStepTwo,
            title: t('EthnicityTitle'),
            subtitle: t('EthnicitySubtitle')
        },
        {
            component: SimpleStepThree,
            title: t('PersonalityTitle'),
            subtitle: t('PersonalitySubtitle')
        },
        {
            component: SimpleSelectVoice,
            title: t('VoiceTitle'),
            subtitle: t('VoiceSubtitle')
        },
        {
            component: SimpleStepFour,
            title: t('EyeColorTitle'),
            subtitle: t('EyeColorSubtitle')
        },
        {
            component: SimpleStepSix,
            title: t('HairStyleTitle'),
            subtitle: t('HairStyleSubtitle')
        },
        {
            component: SimpleStepFive,
            title: t('HairColorTitle'),
            subtitle: t('HairColorSubtitle')
        },
        {
            component: SimpleStepSeven,
            title: t('BodyTypeTitle'),
            subtitle: t('BodyTypeSubtitle')
        },
        ...(isFemale ? [{
            component: SimpleStepEight,
            title: t('PrivatePartsTitle'),
            subtitle: t('PrivatePartsSubtitle')
        }] : []),
        {
            component: SimpleStepNine,
            title: t('FinalizeTitle'),
            subtitle: t('FinalizeSubtitle')
        }
    ];

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        if (isLoggedIn && !profileData.is_verified) {
            setIsEmailVerificationModalOpen(true);
        }
    }, [isLoggedIn, profileData.is_verified]);

    const scrollToStep = (index) => {
        if (containerRef.current && containerRef.current.children[index]) {
            const isMobile = window.innerWidth <= 680;
            const element = containerRef.current.children[index];
            const elementRect = element.getBoundingClientRect();
            const offset = isMobile ? window.innerHeight * 0.10 : 55; // 15% of viewport height on mobile, 55px on desktop

            const scrollPosition = window.pageYOffset + elementRect.top - offset;

            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const returnToStep = (stepName) => {

        if (stepName === t('Scenario') || stepName === t('Age') || stepName === t('CharacterName')) {
            stepName = t('LastDetails');
        } else if (stepName === t('ButtSize') || stepName === t('BreastSize')) {
            stepName = t('PrivateBodyParts');
        } else if (stepName === t('LanguageSelection')) {
            stepName = t('VoiceSelection');
        }


        const stepIndex = steps.findIndex(step =>
            step.title.toLowerCase().includes(stepName.toLowerCase())
        );
        if (stepIndex !== -1) {
            scrollToStep(stepIndex);
            setCurrentStep(stepIndex);
        }
    };

    const {timerExpired} = useTimer();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setWindowWidth(window.innerWidth);
            }, 150); // Debounce time
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, []);

    const getPaddingTop = () => {
        const isMobile = windowWidth <= 680;

        if (timerExpired) {
            return isMobile ? "105px" : "55px";  // timerExpiredMobile : timerExpiredDesktop
        } else {
            return isMobile ? "165px" : "105px";  // timerActiveMobile : timerActiveDesktop
        }
    };

    return (
        <>

            {!timerExpired ? (
                <div className="top-timer-container-main-pricing"
                     style={{display: timerExpired !== false ? "none" : 'block', zIndex: '1003'}}
                >
                    <TopTimer/>
                </div>
            ) : null}

            <HeaderMainMenu/>

            <div className="container-char-creator">
                <AnimatePresence>
                    {isEmailVerificationModalOpen && (
                        <EmailVerification
                            isOpen={isEmailVerificationModalOpen}
                            onClose={() => setIsEmailVerificationModalOpen(false)}
                        />
                    )}
                </AnimatePresence>

                {/*//TODO, this might need more work based on the height of the screen. LOOK INTO THIS*/}
                <div ref={containerRef} className="steps-container"
                     style={{paddingTop: getPaddingTop()}}
                >
                    {steps.map((step, index) => (
                        <Step
                            key={index}
                            {...step}
                            index={index}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            scrollToStep={scrollToStep}
                            totalSteps={steps.length}
                            steps={steps}
                            returnToStep={returnToStep}
                        />
                    ))}
                </div>

            </div>
            <MobileNavigation/>

        </>
    );
};

export default NewCharacterCreation;