import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../../FunnelContext";

const scenarioOptions = [
    {
        name: "tBestFriends",
        emoji: "🤝",
        description: "tBestFriendsDesc"
    },
    {
        name: "tLovers",
        emoji: "❤️",
        description: "tLoversDesc"
    },
    {
        name: "tStepSiblings",
        emoji: "👥",
        description: "tStepSiblingsDesc"
    },
    {
        name: "tClassmates",
        emoji: "📝",
        description: "tClassmatesDesc"
    },
    {
        name: "tRoommates",
        emoji: "🏠",
        description: "tRoommatesDesc"
    },
    {
        name: "tNeighbors",
        emoji: "👋",
        description: "tNeighborsDesc"
    },
];

const TransStep5 = () => {
    const { t } = useTranslation();
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedScenario, setSelectedScenario] = useState(funnelCharData.scenario || 'tBestFriends');

    const handleScenarioSelect = (scenario) => {
        setSelectedScenario(scenario);
        updateCharacterData({ scenario: scenario });
    };

    return (
        <div className="personality-selection-container" style={{
            marginTop: '0',
            width: '100%',
        }}>
            <div className="funnel-creation-label" style={{
                marginBottom: '20px',
                fontSize: '1.25rem',
                color: 'white',
                fontWeight: '700',
                textAlign: 'left',
                width: '100%'
            }}>
                {t('tSelectScenario')}
            </div>

            <div className="personality-grid" style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '16px',
                width: '100%',
            }}>
                {scenarioOptions.map((scenario) => (
                    <div
                        key={scenario.name}
                        className={`personality-box ${selectedScenario === scenario.name ? 'selected' : ''}`}
                        onClick={() => handleScenarioSelect(scenario.name)}
                        style={{
                            // backgroundColor: 'transparent',
                            // padding: '16px',
                            // cursor: 'pointer',
                            // transition: 'all 0.2s ease',
                            // height: '100%',
                        }}
                    >
                        <h3 className="personality-name-creator" style={{
                            // color: 'white',
                            // fontSize: '1.125rem',
                            // fontWeight: '600',
                            // marginBottom: '8px',
                            // display: 'flex',
                            // alignItems: 'center',
                            // gap: '8px'
                        }}>
                            {t(scenario.name)} {scenario.emoji}
                        </h3>

                        <p className="personality-description-creator">
                            {t(scenario.description)}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransStep5;