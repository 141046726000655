import React, {useState} from 'react';
import './dev.css';
// import {AuthModal} from "../Forms/AuthModal";
// import CustomizationLimitUpsell from "./Upsells/CustomizationLimitUpsell";
// import MessageLimitUpsell from "./Upsells/MessageLimitUpsell";
// import RanOutOfTokens from "./Upsells/RanOutOfTokensModal";
// import UpgradeModal from "./Upsells/UpgradeNotification";
// import UpgradeSaleModal from "./Upsells/UpgradeSaleNotification";
// import AgeVerificationModal from "../Modals/AgeVerificationModal";
// import CreateCharPleaseLogin from "../Modals/CreateCharPleaseLogin";
// import SignUpToUseGeneral from "./SignUpToUseGeneral";
// import UpdatedMessageLimitUpsell from "./UpdatedUpsells/UpdatedMessageLimit";
// import MicroSuccess from "./MicroTransCongrats";
// import PlanCongrats from "./PlanCongrats";
// import MicroTransactions from "../../pages/MicroTransactions";
// import ReportDislikeButton from "../ChatComponents/ReportDislikeButton";
// import MaintenanceNotification from "./MaintenanceNotification";
// import ReferralSuccessNotification from "./ReferralSuccessNotification";
// import UpgradeNotification from "./Upsells/UpgradeNotification";
// import UpgradeSaleNotification from "./Upsells/UpgradeSaleNotification";
// import MessageLimitUpsell from "./Upsells/MessageLimitUpsell";
// import SwitchImageModelUniversal from "../Modals/SwitchImageModelUniversal";
// import PublishDenied from "../Modals/PublicCharModals/PublishDenied";
// import PublishSuccess from "../Modals/PublicCharModals/PublishSuccess";

const TestingMenu = () => {
    const [modalToShow, setModalToShow] = useState('');
    //eslint-disable-next-line
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    //     setModalToShow('');
    // };

    const handleClick = (modalName) => {
        setModalToShow(modalName);
        // setIsModalOpen(true);
    };

    const modals = {
            // AuthModal: <AuthModal
            //     isOpen={isModalOpen && modalToShow === 'AuthModal'}
            //     onClose={handleCloseModal}
            // />,


        // UpdatedMessageLimitUpsell: <UpdatedMessageLimitUpsell
        //         isOpen={isModalOpen && modalToShow === 'UpdatedMessageLimitUpsell'}
        //         onClose={handleCloseModal}
        //     />,
        }
    ;

    return (<div className='dev-dev-dev'>
        {Object.keys(modals).map((modalName) => (
            <button className="color-text-test" key={modalName} onClick={() => handleClick(modalName)}>
                Show {modalName} Modal
            </button>))}

        <iframe title="test"
            src="https://api.elyza.app/v1/fetch-banner?aid=AFF_TEST&size=300x250" width="300" height="250" frameBorder="0"></iframe>

        {modalToShow && modals[modalToShow]}
    </div>);
};

export default TestingMenu;
