import React, {useEffect, useMemo, useRef, useState} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as VoiceIcon} from "../../SimpleCharacterCreation/icons/voiceIcon.svg";

import Dropdown from "react-dropdown";
import {useAdvancedCharacterCreation} from "../AdvancedCharacterContext";
import {
    voicesFemaleChinese,
    voicesFemaleEnglish,
    voicesFemaleFrench,
    voicesFemaleGerman,
    voicesFemaleItalian,
    voicesFemaleJapanese,
    voicesFemaleKorean,
    voicesFemalePolish,
    voicesFemalePortuguese,
    voicesFemaleRussian,
    voicesFemaleSpanish,
    voicesMaleChinese,
    voicesMaleEnglish,
    voicesMaleFrench,
    voicesMaleGerman,
    voicesMaleItalian,
    voicesMaleJapanese,
    voicesMaleKorean,
    voicesMalePolish,
    voicesMalePortuguese,
    voicesMaleRussian,
    voicesMaleSpanish
} from "../../SimpleCharacterCreation/Steps/VoiceCloningHelpers/voices";

import {ReactComponent as PlusCircle} from "../../SimpleCharacterCreation/icons/plusIcon.svg";
import {ReactComponent as GoBackIcon} from "../../SimpleCharacterCreation/icons/goBackIcon.svg";

import CustomVoiceHeader from "../../SimpleCharacterCreation/Steps/VoiceCloningHelpers/CustomVoiceHeader";
import VoiceRecordingSection from "../../SimpleCharacterCreation/Steps/VoiceCloningHelpers/VoiceRecordingSection";
import FileUploadSection from "../../SimpleCharacterCreation/Steps/VoiceCloningHelpers/FileUploadSection";
import {getUserAccessToken, userIDExists} from "../../../App";
import VoiceCloningLibrary from "../../SimpleCharacterCreation/Steps/VoiceCloningHelpers/VoiceCloningLibrary";
// import VoiceCloningLibrary from "../../SimpleCharacterCreation/Steps/VoiceCloningHelpers/VoiceCloningLibrary";

const AdvancedSelectVoice = () => {
    const {
        advancedCharData,
        updateAdvancedCharacterData,
        selectedVoice,
        setVoiceSelection
    } = useAdvancedCharacterCreation();
    const [selectedLanguage, setSelectedLanguage] = useState(advancedCharData.languageSelection);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        if (advancedCharData.languageSelection === '') {
            setSelectedLanguage('EN');
            updateAdvancedCharacterData({languageSelection: 'EN'});
        }
        //eslint-disable-next-line
    }, [advancedCharData.languageSelection]);

    const audioRef = useRef(new Audio());

    const {t} = useTranslation();

    const languageOptions = [
        {value: 'EN', label: '🇺🇸 English', nativeName: 'English'},
        {value: 'ES', label: '🇪🇸 Español', nativeName: 'Español'},
        {value: 'RU', label: '🇷🇺 Русский', nativeName: 'Русский'},
        {value: 'DE', label: '🇩🇪 Deutsch', nativeName: 'Deutsch'},
        {value: 'PL', label: '🇵🇱 Polski', nativeName: 'Polski'},
        {value: 'JA', label: '🇯🇵 日本語', nativeName: '日本語'},
        {value: 'KO', label: '🇰🇷 한국어', nativeName: '한국어'},
        {value: 'ZH', label: '🇨🇳 中文', nativeName: '中文'},
        {value: 'FR', label: '🇫🇷 Français', nativeName: 'Français'},
        {value: 'IT', label: '🇮🇹 Italiano', nativeName: 'Italiano'},
        {value: 'PT', label: '🇵🇹 Português', nativeName: 'Português'}
    ];

    const voiceMap = useMemo(() => ({
        EN: {Male: voicesMaleEnglish, Female: voicesFemaleEnglish},
        ES: {Male: voicesMaleSpanish, Female: voicesFemaleSpanish},
        RU: {Male: voicesMaleRussian, Female: voicesFemaleRussian},
        DE: {Male: voicesMaleGerman, Female: voicesFemaleGerman},
        PL: {Male: voicesMalePolish, Female: voicesFemalePolish},
        JA: {Male: voicesMaleJapanese, Female: voicesFemaleJapanese},
        KO: {Male: voicesMaleKorean, Female: voicesFemaleKorean},
        ZH: {Male: voicesMaleChinese, Female: voicesFemaleChinese},
        FR: {Male: voicesMaleFrench, Female: voicesFemaleFrench},
        IT: {Male: voicesMaleItalian, Female: voicesFemaleItalian},
        PT: {Male: voicesMalePortuguese, Female: voicesFemalePortuguese}
        // eslint-disable-next-line
    }), []);


    const currentVoices = useMemo(() => {
        const gender = advancedCharData.gender === 'Female' ? 'Female' : 'Male';
        return voiceMap[selectedLanguage][gender] || [];
    }, [selectedLanguage, advancedCharData.gender, voiceMap]);


    useEffect(() => {
        if (advancedCharData.languageSelection) {
            setSelectedLanguage(advancedCharData.languageSelection);
        }
        if (advancedCharData.voiceSelection) {
            setVoiceSelection(advancedCharData.voiceSelection);
        }
        //eslint-disable-next-line
    }, [advancedCharData.languageSelection, advancedCharData.voiceSelection]);


    const handleLanguageSelect = (option) => {
        setSelectedLanguage(option.value);
        updateAdvancedCharacterData({languageSelection: option.value, voiceSelection: ''});
        setVoiceSelection('');
    };

    const handleVoiceSelection = (voice) => {
        setVoiceSelection(voice.id);
        updateAdvancedCharacterData({voiceSelection: voice.id});
        playAudio(voice.url);
    };

    const playAudio = (url) => {
        audioRef.current.pause();
        audioRef.current.src = url;
        audioRef.current.play().catch(error => console.error("Audio playback failed:", error));
    };

    const [isCustomMode, setIsCustomMode] = useState(false);

    const [currentVoiceOptionActive, setCurrentVoiceOptionActive] = useState('');

    const [voices, setVoices] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchVoices = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch('https://api.elyza.app/v1/list-voice-clones', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    language: selectedLanguage
                })
            });
            if (!response.ok) throw new Error('Failed to fetch voices');
            const data = await response.json();
            setVoices(data.voice_clones);
        } catch (err) {
            setError('Failed to load voices. Please try again later.');
            console.error('Error fetching voices:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchVoices();
        }
        //eslint-disable-next-line
    }, [isLoggedIn]);

    return (
        <div>
            <div className="general-multiple-options-container">
                <div className="dropdown-container-bottom" style={{width: '100%'}}>
                    <div className="custom-dropdown">
                        <Dropdown
                            options={languageOptions}
                            value={selectedLanguage}
                            onChange={handleLanguageSelect}
                            placeholder={t('SelectLanguage')}
                            className="model-dropdown-creator"
                            controlClassName="model-dropdown-control-creator"
                            menuClassName="model-dropdown-menu-creator-voice"
                            arrowClassName="model-dropdown-arrow-creator"
                        />
                    </div>
                </div>

                <div className="voice-grid" style={{display: isCustomMode ? 'flex' : 'grid', width: '100%'}}>
                    {!isCustomMode && (
                        <>
                            {currentVoices.map((voice, index) => (
                                <div key={`${voice.id}-${index}`} className="general-option-wrapper">
                                    <div
                                        className={`general-option-voice-items ${selectedVoice === voice.id ? 'general-option-creator-selected' : ''}`}
                                        onClick={() => handleVoiceSelection(voice)}
                                    >
                                        <div className="voice-example-container">
                                            {t(`${voice.label}`)}
                                            <VoiceIcon/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {isLoggedIn && (
                                <div className="general-option-wrapper">
                                    <div
                                        className="general-option-voice-items"
                                        onClick={() => setIsCustomMode(true)}
                                    >
                                        <div className="voice-example-container">
                                            Custom
                                            <PlusCircle/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {isCustomMode && (
                        <>
                            <div className="custom-voice-container" style={{width: '100%'}}>
                                <div className="custom-voice-header">
                                    <CustomVoiceHeader/>
                                </div>
                                {/*<MicIcon />*/}
                                <span style={{
                                    color: '#c4c4c4',
                                    fontSize: '0.85em',
                                    fontWeight: '600',
                                }}
                                >
                                    {t('voice_clone_requirements')}
                            </span>

                                <div className="voice-cloning-container"
                                     style={{marginTop: '20px'}}
                                >

                                    <VoiceRecordingSection
                                        currentVoiceOptionActive={currentVoiceOptionActive}
                                        setCurrentVoiceOptionActive={setCurrentVoiceOptionActive}
                                    />

                                    <FileUploadSection
                                        currentVoiceOptionActive={currentVoiceOptionActive}
                                        setCurrentVoiceOptionActive={setCurrentVoiceOptionActive}
                                    />

                                    <VoiceCloningLibrary
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        voices={voices}
                                        isLoading={isLoading}
                                        error={error}
                                        selectedLanguage={selectedLanguage}
                                        currentVoiceOptionActive={currentVoiceOptionActive}
                                        setCurrentVoiceOptionActive={setCurrentVoiceOptionActive}
                                        updateCharacterData={updateAdvancedCharacterData}
                                    />

                                    <div style={{width: '100%'}}>
                                        <button
                                            className="audio-upload-label"
                                            style={{marginTop: '15px'}}
                                            onClick={() => setIsCustomMode(false)}
                                        >
                                            <GoBackIcon/>
                                            Go Back
                                        </button>
                                    </div>
                                    {/*<div className="audio-container-cloning">*/}

                                    {/*        <div className="textbox-name-general-short">*/}
                                    {/*            <label className="label-sign-up-h">{t('Transcript')}</label>*/}
                                    {/*            <textarea*/}
                                    {/*                id="personalityDescription"*/}
                                    {/*                value={transcript}*/}
                                    {/*                onChange={handleTranscriptChange}*/}
                                    {/*                rows="2"*/}
                                    {/*                maxLength="500"*/}
                                    {/*                cols="50"*/}
                                    {/*                style={{maxHeight: '100px', minHeight: '60px'}}*/}
                                    {/*                placeholder={t('transcriptPlaceholder')}*/}
                                    {/*            />*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}

                                    <div className="checkmark-container-cloning"></div>

                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};


export default AdvancedSelectVoice;