import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import i18n from "i18next";

const CharacterCreationContext = createContext();

export const useCharacterCreation = () => useContext(CharacterCreationContext);

export const CharacterCreationProvider = ({ children }) => {

    const [currentLanguage, setCurrentLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        return savedLanguage || i18n.language;
    });

    let upperCaseLanguage = currentLanguage.toUpperCase();

    const [characterData, setCharacterData] = useState(() => {
        const savedData = localStorage.getItem('characterCreationData');

        return savedData ? JSON.parse(savedData) : {
            style: 'Anime',
            gender: 'Female',
            ethnicity: '',
            personality: '',
            eyeColor: '',
            hairStyle: '',
            hairColor: '',
            bodyType: '',
            breastSize: '',
            buttSize: '',
            charName: '',
            scenario: '',
            age: '',
            specialCase: '',
            languageSelection: upperCaseLanguage ?? 'EN',
            voiceSelection: '',

            clonedVoiceName: 'Custom Voice',
            clonedVoiceURL: ''

        };
    });

    const [isAnime, setIsAnime] = useState(() => characterData.style === 'Anime');
    const [isFemale, setIsFemale] = useState(() => characterData.gender === 'Female');

    const [selectedVoice, setVoiceSelection] = useState(characterData.voiceSelection)

    useEffect(() => {
        const handleLanguageChange = () => {
            const newLanguage = i18n.language;
            setCurrentLanguage(newLanguage);
            localStorage.setItem('userLanguage', newLanguage);

            // Update character data with new language
            setCharacterData(prevData => ({
                ...prevData,
                languageSelection: newLanguage.toUpperCase()
            }));
        };

        // Listen for language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Initial language setup
        handleLanguageChange();

        // Cleanup listener
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const updateCharacterData = useCallback((newData) => {
        setCharacterData(prevData => {
            const updatedData = { ...prevData, ...newData };

            // Only update isAnime and isFemale if style or gender has changed
            if ('style' in newData) {
                setIsAnime(updatedData.style === 'Anime');
            }
            if ('gender' in newData) {
                setIsFemale(updatedData.gender === 'Female');
            }

            return updatedData;
        });
    }, []);


    useEffect(() => {
        if (characterData.clonedVoiceURL && characterData.clonedVoiceName) {
            updateCharacterData({voiceSelection: 'custom'});
        }
        //eslint-disable-next-line
    }, [characterData.clonedVoiceURL, characterData.clonedVoiceName]);

    const clearOptions = useCallback(() => {
        const clearedData = {
            style: 'Anime',
            gender: 'Female',
            ethnicity: '',
            personality: '',
            eyeColor: '',
            hairStyle: '',
            hairColor: '',
            bodyType: '',
            breastSize: '',
            buttSize: '',
            charName: '',
            scenario: '',
            age: '',
            specialCase: '',
            languageSelection: upperCaseLanguage ?? 'EN',
            voiceSelection: '',
            clonedVoiceName: 'Custom Voice',
            clonedVoiceURL: ''
        };
        setCharacterData(clearedData);
        setIsAnime(true);
        setIsFemale(true);

        localStorage.removeItem('characterCreationData');
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        localStorage.setItem('characterCreationData', JSON.stringify(characterData));
    }, [characterData]);

    const traitOptions = {
        style: ['Anime', 'Real', 'Furry'],
        gender: ['Male', 'Female', 'Other'],
        ethnicity: ['White', 'Asian', 'Black', 'Latina', 'Arabic'],
        personality: ['Sweet', 'Mean', 'Innocent', 'Submissive', 'Dominant', 'Shy'],
        eyeColor: ['Brown', 'Hazel', 'Gray', 'Green', 'Blue'],
        hairStyle: ['Bangs', 'Curly', 'Bun', 'Ponytail', 'Short', 'Straight', 'Buzz', 'Messy', 'Undercut'],
        hairColor: ['Blonde', 'Black', 'Brown', 'Gray', 'Red', 'Purple', 'Blue', 'Pink'],
        bodyType: ['Slim', 'Small', 'Average', 'Curvy', 'Large', 'Bulky'],
        breastSize: ['Small', 'Medium', 'Big', 'Large'],
        buttSize: ['Small', 'Medium', 'Big', 'Large'],
    };


    const transformDataForBackend = useCallback(() => {
        const transformedData = {};

        for (const [trait, value] of Object.entries(characterData)) {
            if (traitOptions[trait]) {
                const index = traitOptions[trait].indexOf(value);
                if (index !== -1) {
                    transformedData[trait] = index + 1;
                }
            }
        }

        return transformedData;
        //eslint-disable-next-line
    }, [characterData]);

    return (
        <CharacterCreationContext.Provider value={{
            isAnime,
            isFemale,
            characterData,
            updateCharacterData,
            transformDataForBackend,
            clearOptions,
            selectedVoice, setVoiceSelection
        }}>
            {children}
        </CharacterCreationContext.Provider>
    );
};