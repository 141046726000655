import React from 'react';
import '../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const AgeVerificationModal = ({isOpen, onClose, onConfirmAge}) => {
    const navigate = useNavigate();

    const {t} = useTranslation();

    const handleConfirmAge = (e) => {
        onConfirmAge(e);
        onClose();
    }

    const handleLeave = (e) => {
        navigate(-1);
        window.close();
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center', backdropFilter: 'blur(10px)'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    {t('ConfirmYourAge')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('ConfirmAgeDesc0')}
                    <br></br>
                    <br></br>
                    {t('ConfirmAgeDesc1')}
                    <br></br>
                    <br></br>
                    {t('ConfirmAgeDesc2')}
                </div>
                <button onClick={handleConfirmAge}
                        className="notification-button-main"
                >
                    {t('ConfirmAge')}
                </button>
                <button
                    style={{
                        marginTop: '10px',
                        width: '100%',
                        color: '#c4c4c4',
                        fontWeight: 'bold',
                        fontSize: '1em',
                        cursor: 'pointer'
                    }}
                    onClick={handleLeave}>
                    I am not 18
                </button>
            </div>
        </div>
    );
};

export default AgeVerificationModal;